import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Directive({
  selector: '[elemDetect]'
})
export class ElementDetectDirective {
  @Output() elemDetectChange = new EventEmitter();
  @Input() elemDetect;

  constructor(private eleRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickout(e) {
    if (!this.eleRef.nativeElement.contains(e.target)) {
      this.elemDetectChange.emit(false);
    }
  }
}
