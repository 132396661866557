<footer
  class="footer"
  [ngClass]="{
    expandFooter: footerExpand
  }"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <p>copyright &copy; Builder.ai {{ currentYear }}</p>
      </div>
      <div class="col-md-8">
        <ul class="footer-links">
          <!--Opens mattermost chat-->
          <!-- <li
            class="trackerList dropup"
            *ngIf="openLeftPanel() && mattermost_url"
          >
            <div class="trackerhasList" (click)="goToMattermost()">
              <img
                src="assets/images/mattermost_logo_horizontal.svg"
                alt="mattermost"
                width="91px"
                height="14px"
              />
            </div>
          </li> -->

          <li
            class="trackerList dropup"
            *ngIf="
              dataService.partnerData?.code === 'e-ai' ||
              dataService.partnerData?.code === 'liquidstudio'
            "
          >
            <ng-container
              *ngIf="
                openLeftPanel() && builderPlanners.length > 0;
                else oldView
              "
            >
              <div
                id="tracker_toggle"
                class="trackerhasList"
                (click)="openTrackerLink(builderPlanners[0].url)"
              >
                <img
                  src="assets/images/revamp-buildertracker-logo.svg"
                  alt="tracker"
                  width="91"
                  height="14"
                />
              </div>
            </ng-container>
            <ng-template #oldView>
              <a
                (click)="naviagteToTracker()"
                rel="noopener noreferrer"
                *ngIf="!openLeftPanel()"
                ><img
                  src="assets/images/revamp-buildertracker-logo.svg"
                  alt="tracker"
                  width="91"
                  height="14"
              /></a>
            </ng-template>
          </li>
          <li
            class="trackerList dropup"
            *ngIf="openLeftPanel() && builderPlanners.length > 0"
          >
            <div
              class="trackerhasList"
              (click)="openTrackerLink(builderPlanners[0].url, true)"
            >
              <img
                src="assets/images/builder-uiw.svg"
                alt="builder"
                width="91"
                height="14"
              />
            </div>
          </li>

          <li class="trackerList dropup" *ngIf="openLeftPanel() && uniCode">
            <div class="trackerhasList" (click)="openTrackerLink()">
              <img
                src="assets/images/Builder_home.svg"
                alt="home"
                width="91"
                height="14"
              />
            </div>
          </li>

          <li class="trackerList dropup" *ngIf="openLeftPanel()">
            <div class="trackerhasList" (click)="openBNowLink()">
              <img
                src="assets/images/b-now-logo.svg"
                alt="b-now"
                width="91"
                height="14"
              />
            </div>
          </li>
        </ul>
      </div>

      <div class="footerExpand d-md-none" (click)="mobileFooter()">
        <em class="zmdi zmdi-chevron-up" *ngIf="footerExpand"></em>
        <em class="zmdi zmdi-chevron-down" *ngIf="!footerExpand"></em>
      </div>
    </div>
  </div>
</footer>
