import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ToasterModel } from '../../../../core/manager-dashboard/store/toaster/toaster.model';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnChanges {
  getToastMessage$: Observable<ToasterModel>;

  @Input() showToasterMsg = false;
  constructor(private store: Store) {
    this.getToastMessage$ = this.store.select((state) => state.toasterstate);
  }

  ngOnChanges() {
    if (this.showToasterMsg) {
      this.getToastMessage$.subscribe((data) => {
        if (data) {
          data.time = data.time ? data.time : 2000;
          this.showToasterMsg = true;
          setTimeout(() => {
            this.showToasterMsg = false;
          }, data.time);
        }
      });
    }
  }

  closeSaveTost() {
    this.showToasterMsg = false;
  }
}
