import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { MyMeetingsSidebarComponent } from '../../../core/my-meeting-module/my-meeting-sidebar/my-meeting-sidebar.component';
const routesList = [
  {
    name: 'MyMeetingsSidebarComponent',
    path: '../../../core/my-meeting-module/my-meeting-module',
    component: MyMeetingsSidebarComponent
  }
];
@Injectable({
  providedIn: 'root'
})
export class DynamicLoaderService {
  constructor(private injector: Injector) {}
  async loadLazyComponent(viewContainerRef: ViewContainerRef, componentName) {
    const getComponent = this.findComponent(componentName);
    // Load the module dynamically
    // eslint-disable-next-line
    const lazyModule = await import(
      '../../../core/my-meeting-module/my-meeting-module'
    ).then((m) => m.MymeetingModule);

    viewContainerRef.clear();
    // Use the environment injector to create the component
    // eslint-disable-next-line
    const componentRef = viewContainerRef.createComponent(
      getComponent.component,
      {
        injector: this.injector
      }
    );
    // Optionally, you can perform additional actions with the component reference
    // componentRef.instance.someInput = someValue;
  }

  private findComponent(name) {
    return routesList.filter((ele) => ele.name === name)[0];
  }
}
