import { Injectable } from '@angular/core';
import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { UrlParameterModel } from '../models/url-parameters-model/url-parameter.model';
/**
 * Created by nitin on 16/12/17.
 */
@Injectable()
export class ModelMapperService {
  public jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
    this.jsonConvert.operationMode = OperationMode.ENABLE; // print some debug data
    this.jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // allow null
  }

  public mapToUrlParameterModel(
    mappedObj: UrlParameterModel,
    jsonObject
  ): UrlParameterModel {
    try {
      if (
        jsonObject['code'] &&
        jsonObject['code'].indexOf('#/my-account') > -1
      ) {
        console.log('settn auth code');
        localStorage.setItem('zoomAuthCode', jsonObject['code'].split('#')[0]);
      } else {
        const deserializeMapObject = this.jsonConvert.deserializeObject(
          jsonObject,
          UrlParameterModel
        );
        return deserializeMapObject;
      }
      return mappedObj;
    } catch (e) {
      console.log(<Error>e);
    }
  }

  public getMappedArrayModel<T>(modelToBeMapped: T[], jsonObject?): T[] {
    if (Array.isArray(jsonObject) && jsonObject.length > 0) {
      from(jsonObject)
        .pipe(pluck('attributes'))
        .subscribe((item: T) => {
          modelToBeMapped.push(item);
        });
    }
    return modelToBeMapped;
  }
}
