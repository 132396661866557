import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { catchError, map } from 'rxjs/operators';
import { ToastMessageService } from '../../../core/services/toast.message.service';
import { Constants } from '../utility/constants';
import { CommonService } from './common.service';
import { DataService } from './data.service';

@Injectable()
export class HttpRequests {
  miniStudioByPassUrl = [];

  constructor(
    public commonService: CommonService,
    public toastMessageService: ToastMessageService,
    public http: HttpClient,
    private router: Router,
    private dataService: DataService,
    private toastr: ToastrService
  ) {
    this.miniStudioByPassUrl = [
      'pm_dashboard/build_cards',
      'pm_dashboard/build_card/pm_dashboard_invite',
      'build_cards/',
      'pm_dashboard/build_card/',
      'b360_customisation_budget/graphql'
    ];
  }

  public get(url: string, Headers?, requestOptions?): Observable<any> {
    // eslint-disable-line
    if (requestOptions) {
      return this.http.get(url, requestOptions).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    } else if (Headers) {
      return this.http.get(url, Headers).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    } else {
      return this.http.get(url).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    }
  }

  public getWithoutCheckErrorStatus(
    url: string,
    requestOptions?
  ): Observable<any> {
    // eslint-disable-line
    return this.http.get(url, requestOptions).pipe(
      map((res) => this.parseResponse(res)),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error);
      })
    );
  }

  public post(url: string, data: any, Headers): Observable<any> {
    // eslint-disable-line
    if (Headers) {
      return this.http.post(url, data, Headers).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    } else {
      return this.http.post(url, data).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    }
  }

  public put(url: string, data: any, Header): Observable<any> {
    // eslint-disable-line
    if (Header) {
      return this.http.put(url, data, Header).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    } else {
      return this.http.put(url, data).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    }
  }

  public delete(url: string, Header): Observable<any> {
    // eslint-disable-line
    if (Header) {
      return this.http.delete(url, Header).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    } else {
      return this.http.delete(url).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    }
  }

  public patch(url: string, data: any, Header): Observable<any> {
    // eslint-disable-line
    if (Header) {
      return this.http.patch(url, data, Header).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    } else {
      return this.http.patch(url, data).pipe(
        map((res) => this.parseResponse(res)),
        catchError((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return throwError(() => error.error);
        })
      );
    }
  }

  public parseResponse(res) {
    return res;
  }

  public handleError(error) {
    this.checkErrorStatus(error);
    return throwError(() => error.error);
  }

  public checkErrorStatus(error) {
    let byPass = false;
    let url = '';
    for (let i = 0; i < this.miniStudioByPassUrl.length; i++) {
      if (error.url.includes(this.miniStudioByPassUrl[i])) {
        byPass = true;
        url = this.miniStudioByPassUrl[i];
        break;
      }
    }
    if (!byPass) {
      this.showToastErrorBasedOnCondition(error);
    }
    if (byPass && (error.status === 404 || error.status === 401)) {
      this.byPass401Error(error, url);
    }
    return throwError(() => error.error);
  }

  showToastErrorBasedOnCondition(error) {
    if (error.status === 401) {
      this.dataService.logout();
      this.router.navigate(['unauthorized-access']);
    } else if (error.status >= 500 && error.status < 600) {
      this.status500To600Toast();
    } else if (error.status === 404) {
      this.byPassToastError(error);
    }
  }

  byPass401Error(error, url) {
    if (error.status != 401) {
      this.toastMessageService.showToastMessage(
        error?.error?.message
          ? error.error.message
          : Constants.toastMessages.error,
        3000,
        true
      );
    } else if (error.status == 401 && url.includes('pm_dashboard/build_card')) {
      this.toastMessageService.showToastMessage(
        error.error.message,
        2000,
        true
      );
      return;
    }
  }

  byPassToastError(error) {
    if (this.dataService.getAuthToken()) {
      const projectId =
        error.url.includes('/pm/projects/') &&
        error.url.split('/').indexOf('projects') < error.url.split('/')?.length
          ? error.url.split('/')[error.url.split('/').indexOf('projects') + 1]
          : null;
      if (projectId && error.url.endsWith(projectId)) {
        if (sessionStorage.getItem('force_redirection')) {
          this.router.navigate([
            'projects/' + projectId + '/basic-information/project-detail'
          ]);
        } else {
          this.router.navigate(['projects']);
        }
      } else {
        this.showToastForError();
      }
    } else {
      this.router.navigate(['page-not-found']);
    }
  }
  private showToastForError() {
    this.toastr.success(
      'Oops Something went wrong. Please try again!',
      'Error',
      {
        disableTimeOut: false,
        tapToDismiss: false,
        positionClass: 'toast-top-right',
        toastClass: 'toast-icon custom-toast-error',
        timeOut: 2000
      }
    );
  }

  status500To600Toast() {
    this.toastr.success(
      'Oops Something went wrong. Please try again!',
      'Error',
      {
        disableTimeOut: false,
        tapToDismiss: false,
        positionClass: 'toast-top-right',
        toastClass: 'toast-icon custom-toast-error',
        timeOut: 2000
      }
    );
  }
}
