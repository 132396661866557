import { Pipe, PipeTransform } from '@angular/core';
import { getNameAndInitial } from '../utility/utility.functions';

@Pipe({
  name: 'nameInitial'
})
export class NameInitialPipe implements PipeTransform {
  transform(value: unknown): string {
    const initials = getNameAndInitial(value);
    return initials && initials['initials'] ? initials['initials'] : '';
  }
}
