import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/core/app-module/app.module';
import { environment } from './environments/environment';
import 'hammerjs';
if (environment.production) {
  enableProdMode();
  window.console.log = function () {}; // eslint-disable-line
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {}) // eslint-disable-line
  .catch((err) => console.log(err));
