import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Constants } from '../shared/constants';
import { CommonService } from '../../../shared/shared-module/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class TribeService {
  isMemberTabClicked;
  API_BASE_URL = environment.API_BASE_URL;
  managerTabStatus;
  managerID: number;
  tribeCoverageData = {};
  clickEventData = '';
  clickSquadEdit = '';
  clickMemberEdit = '';
  regionWithCountry = [];
  constructor(
    public http: HttpClient,
    private dataService: DataService,
    private commonService: CommonService
  ) {}

  getAllTribe(
    page?,
    perPage?,
    searchText?,
    status?,
    // coverage?,
    leader?,
    service_region?
  ) {
    let API = `${this.API_BASE_URL}${Constants.apiEndPoints.CRUDTribe}`;

    if (page) {
      API = `${API}?page=${page}`; //&per_page=${perPage}
    }
    if (searchText) {
      API = `${API}&search=${searchText}`;
    }
    if (status?.length > 0) {
      status.forEach((ele) => {
        API = `${API}&status[]=${ele}`;
      });
    }

    // if (coverage?.length > 0) {
    //   coverage.forEach((ele) => {
    //     API = `${API}&tribe_coverage[]=${ele}`;
    //   });
    // }
    if (leader?.length > 0) {
      leader.forEach((ele) => {
        API = `${API}&tribe_leaders[]=${ele}`;
      });
    }

    // if (head?.length > 0) {
    //   head.forEach((ele) => {
    //     API = `${API}&tribe_heads[]=${ele}`;
    //   });
    // }

    if (service_region?.length > 0) {
      service_region.forEach((ele) => {
        API = `${API}&service_region[]=${ele}`;
      });
    }
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => res),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  addTribes(addTribe) {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.CRUDTribe}`;
    const tribe = { tribe: addTribe };
    return this.http
      .post(
        API,
        tribe,
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => res),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public updateTribe(updateTribe, tribeId) {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.CRUDTribe}/${tribeId}`;
    const tribe = { tribe: updateTribe };
    return this.http
      .patch(
        API,
        tribe,
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getTribeById(id) {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.CRUDTribe}/${id}`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getTribeCoverage() {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.TribeCoverage}`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          this.tribeCoverageData = res['data'];
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getAllManager(page?, perPage?, query?, value?, filterStr?, min?) {
    let API = `${this.API_BASE_URL}${Constants.apiEndPoints.getAllManager}?page=${page}&per_page=${perPage}`;

    if (query) {
      API = `${API}&search=${query}`;
    }
    if (value) {
      API = `${API}&filter=status&value=${value}`;
    }

    if (filterStr) {
      API = `${API}&${filterStr}`;
    }

    if (min) {
      API = `${API}&min=${min}`;
    }
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => res),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  public getTribeSquad() {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.getPods}?without_tribes=true`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  public getTribeTimeline(tribeId?) {
    const API = `${
      this.API_BASE_URL
    }${Constants.apiEndPoints.tribeTimeline.replace('[tribe_id]', tribeId)}`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getServiceRegions() {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.getAllManager +
      '/service_regions' +
      this.commonService.forceRedirect();
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          this.tribeCoverageData = res['data'];
          if (res['data']) {
            return res;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
}
