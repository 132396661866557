import { Injectable } from '@angular/core';
import * as localforage from 'localforage';

@Injectable({
  providedIn: 'root'
})
export class LocalForageService {
  private videoStore: LocalForage;

  constructor() {
    this.videoStore = localforage.createInstance({
      name: 'video-cache',
      storeName: 'videos',
      driver: [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE
      ]
    });
    // Start the periodic check for old videos
    this.removeOldVideosPeriodically();
  }

  async setItem(key: string, value: Blob): Promise<void> {
    try {
      // Check if the new video is larger than the total available space
      const totalSpace = await navigator.storage.estimate();
      if (value.size > totalSpace.quota) {
        throw new Error('The new video is too large to fit in the storage');
      }

      // Try to add the new video
      await this.videoStore.setItem(key, { value, timestamp: Date.now() });
    } catch (error) {
      console.error('Error setting item in localForage:', error);
      if (
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      ) {
        // Storage is full, remove the oldest videos until there is enough space
        const keys = await this.videoStore.keys();
        const items = await Promise.all(
          keys.map((key) => this.videoStore.getItem(key))
        );
        // Sort the items by timestamp
        items.sort((a, b) => a['timestamp'] - b['timestamp']);
        for (const item of items) {
          // Remove the oldest video
          await this.videoStore.removeItem(item['key']);
          try {
            // Try to add the new video again
            await this.videoStore.setItem(key, {
              value,
              timestamp: Date.now()
            });
            // If the video was added successfully, break the loop
            break;
          } catch (error) {
            // If the storage is still full, continue with the next oldest video
            if (
              error.name !== 'QuotaExceededError' &&
              error.name !== 'NS_ERROR_DOM_QUOTA_REACHED'
            ) {
              throw error;
            }
          }
        }
      } else {
        throw error;
      }
    }
  }

  async getItem(key: string): Promise<Blob | null> {
    try {
      return await this.videoStore.getItem(key);
    } catch (error) {
      console.error('Error getting item from localForage:', error);
      return null;
    }
  }

  async removeItem(key: string): Promise<void> {
    try {
      await this.videoStore.removeItem(key);
    } catch (error) {
      console.error('Error removing item from localForage:', error);
    }
  }

  async clearStore(): Promise<void> {
    try {
      await this.videoStore.clear();
      await localforage.clear();
    } catch (error) {
      console.error('Error clearing localForage store:', error);
    }
  }

  // Estimate the used space in MB
  private async getUsedSpace(): Promise<number> {
    let totalSize = 0;
    try {
      await this.videoStore.iterate((value: Blob) => {
        totalSize += value.size;
      });
    } catch (error) {
      console.error('Error calculating used space in localForage:', error);
    }
    return totalSize / (1024 * 1024); // Convert to MB
  }

  private async removeOldVideosPeriodically(): Promise<void> {
    // Run this method every hour
    setInterval(
      async () => {
        try {
          // Get all keys
          const keys = await this.videoStore.keys();
          const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000;

          // Check each video
          for (const key of keys) {
            const item = await this.videoStore.getItem(key);
            if (item && item['timestamp'] < oneDayAgo) {
              // If the video is older than 24 hours, remove it
              await this.videoStore.removeItem(key);
            }
          }
        } catch (error) {
          console.error('Error removing old videos:', error);
        }
      },
      60 * 60 * 1000
    ); // 60 minutes * 60 seconds * 1000 milliseconds
  }
}
