//Here we define four actions for CRUD operations respectively
export enum TribeActionTypes {
  ADD_ONE = '[Tribes] Add One',
  UPDATE_ONE = '[Tribes] Update One',
  DELETE_ONE = '[Tribes] Delete One',
  GET_ALL = '[Tribes] Get All',
  GET_ONE = '[Tribes] Get One',
  GET_TRIBE_COVERAGE = '[Tribes] Get All Coverage',
  GET_ALL_MANAGER = '[Tribes] Get All Manager',
  GET_ALL_SQUAD = '[Tribes] Get All Squad',
  SET_VIEW_TYPE = '[Tribe] Set View Type',
  SET_FILTER_QUERY = '[Tribe] Set Filter Query',
  SET_GROUP = '[Tribe] Set Group',
  SET_SEARCH = '[Tribe] Set Search',
  GET_SERVICE_REGION = '[Pods] Get Service Regions'
}

//Read All
export class GetAllTribe {
  static readonly type = TribeActionTypes.GET_ALL;
  constructor(
    public page?: number,
    public perPage?: number,
    public searchText?: string,
    public status?,
    public leader?,
    public service_region?
  ) {}
}

//Create
export class AddTribe {
  static readonly type = TribeActionTypes.ADD_ONE;
  constructor(public payload) {}
}

//Update
export class UpdateTribe {
  static readonly type = TribeActionTypes.UPDATE_ONE;
  constructor(
    public payload,
    public id: number
  ) {}
}

//Read
export class GetTribe {
  static readonly type = TribeActionTypes.GET_ONE;
  constructor(public id: number) {}
}

//Delete
export class DeleteTribe {
  static readonly type = TribeActionTypes.DELETE_ONE;
  constructor(public id: number) {}
}

export class GetTribeCoverage {
  static readonly type = TribeActionTypes.GET_TRIBE_COVERAGE;
}

export class GetTribeManager {
  static readonly type = TribeActionTypes.GET_ALL_MANAGER;
  constructor(
    public type: 'leader' | 'head',
    public page: number,
    public perPage: number,
    public searchText: string,
    public value: string,
    public filterStr: string,
    public min: boolean
  ) {}
}

export class GetTribeSquad {
  static readonly type = TribeActionTypes.GET_ALL_SQUAD;
}

export class SetViewType {
  static readonly type = TribeActionTypes.SET_VIEW_TYPE;
  constructor(public payload: 'grid' | 'list') {}
}

export class SetFilterQuery {
  static readonly type = TribeActionTypes.SET_FILTER_QUERY;
  constructor(public payload) {}
}

export class SetGroup {
  static readonly type = TribeActionTypes.SET_GROUP;
  constructor(public payload) {}
}

export class SetSearch {
  static readonly type = TribeActionTypes.SET_SEARCH;
  constructor(public payload) {}
}

export class GetTribeServiceRegions {
  static readonly type = TribeActionTypes.GET_SERVICE_REGION;
}

export type TribeActions =
  | AddTribe
  | UpdateTribe
  | DeleteTribe
  | GetAllTribe
  | GetTribe
  | GetTribeManager
  | GetTribeCoverage
  | GetTribeSquad
  | SetViewType;
