import { Routes } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { CodeRepoComponent } from './core/app-module/code-repo/code-repo.component';
import { PageNotFoundComponent } from './core/app-module/page-not-found/page-not-found.component';
import { UnauthorizedAccessComponent } from './core/app-module/unauthorized-access/unauthorized-access.component';
import { UnderMaintainanceComponent } from './core/app-module/under-maintainance/under-maintainance.component';
import { ForgotPasswordComponent } from './core/login-module/forgot-password/forgot-password.component';
import { LoginComponent } from './core/login-module/login/login.component';
import { ResetPasswordComponent } from './core/login-module/reset-password/reset-password.component';
import { SignupComponent } from './core/login-module/signup/signup.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./core/dashboard-module/dashboard.module').then(
        (m) => m.DashboardModule
      )
  },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/project-module/project.module').then(
        (m) => m.ProjectModule
      )
  },
  {
    path: 'spec-calls',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/spec-calls/spec-calls.module').then(
        (m) => m.SpecCallsModule
      )
  },

  {
    path: 'demo-calls',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/spec-calls/spec-calls.module').then(
        (m) => m.SpecCallsModule
      )
  },

  {
    path: 'userAvailability',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/spec-calls/spec-calls.module').then(
        (m) => m.SpecCallsModule
      )
  },

  {
    path: 'my-account',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/my-account-module/my-account.module').then(
        (m) => m.MyaccountModule
      )
  },
  {
    path: 'managers',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/manager-dashboard/teams-module/teams.module').then(
        (m) => m.TeamsModule
      )
  },

  {
    path: 'partners',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/partner-module/partner.module').then(
        (m) => m.PartnerModule
      )
  },
  {
    path: 'accounts',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/accounts-module/accounts.module').then(
        (m) => m.AccountsModule
      )
  },

  {
    path: 'repository',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/repository-module/repository-module.module').then(
        (m) => m.RepositoryModuleModule
      )
  },
  {
    path: 'catalogue',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/catalogue-module/catalogue.module').then(
        (m) => m.CatalogueModule
      )
  },
  {
    path: 'configuration',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/configuration-module/configuration.module').then(
        (m) => m.ConfigurationModule
      )
  },
  {
    path: 'studio',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/studio/studio.module').then((m) => m.StudioModule)
  },
  {
    path: 'bchat',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/bchat-module/bchat-module').then((m) => m.BChatModule)
  },
  {
    path: 'cockpit',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/cockpit-natasha/cockpit-natasha.module').then(
        (m) => m.CockpitNatashaModule
      )
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'code-repo',
    component: CodeRepoComponent
  },
  {
    path: 'under-maintainance',
    component: UnderMaintainanceComponent
  },
  {
    path: 'unauthorized-access',
    component: UnauthorizedAccessComponent
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];
