import { animate, style, transition, trigger } from '@angular/animations';

export class CustomAnimations {
  static flyInOutAnimation = {
    slideInLeft: trigger('slideInLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate(
          '200ms ease-in',
          style({ transform: 'translateX(0%)', opacity: 1 })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate(
          '200ms ease-in',
          style({ transform: 'translateX(-100%)', opacity: 0 })
        )
      ])
    ]),
    slideInRight: trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(200%)', opacity: 0 }),
        animate(
          '200ms ease-in',
          style({ transform: 'translateX(0%)', opacity: 1 })
        )
      ]),
      transition(':leave', [
        animate(
          '200ms ease-in',
          style({ transform: 'translateX(200%)', opacity: 1 })
        )
      ])
    ]),
    slideInBottom: trigger('slideInBottom', [
      transition(':enter', [
        style({ transform: 'translateY(0)', opacity: 0 }),
        animate(
          '200ms ease-in',
          style({ transform: 'translateY(-200)', opacity: 1 })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate(
          '200ms ease-in',
          style({ transform: 'translateY(-200)', opacity: 0 })
        )
      ])
    ]),
    slideInTop: trigger('slideInTop', [
      transition(':enter', [
        style({ transform: 'translateY(-100px)', opacity: 0 }),
        animate(
          '150ms ease-in',
          style({ transform: 'translateY(0px)', opacity: 1 })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0px)', opacity: 1 }),
        animate(
          '150ms ease-in',
          style({ transform: 'translateY(-100px)', opacity: 0 })
        )
      ])
    ]),
    opacity: trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ])
  };
}
