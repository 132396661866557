<div class="notification-wrapper" #notificationWrapper>
  <div class="notification-title-wrap">
    <div class="noti-title">Notifications</div>
    <em class="noti-close" (click)="closeSidebar()"
      ><img src="../../../../assets/images/close-black.svg" alt=""
    /></em>
  </div>

  <div class="notification-subheader">
    <div class="noti-action-btns">
      <div
        role="button"
        [class.active]="!isActiveUnread"
        (click)="onChangeUnreadAllTab(false)"
        class="noti-btn"
      >
        All
      </div>
      <div
        role="button"
        [class.active]="isActiveUnread"
        (click)="onChangeUnreadAllTab(true)"
        class="noti-btn"
      >
        Unread ({{ unreadCount$ | async }})
      </div>
    </div>
    <div (click)="markAllAsRead()" class="read-all">Mark all as read</div>
  </div>

  <div
    #scrollContainer
    infiniteScroll
    [infiniteScrollDistance]="1.5"
    [infiniteScrollThrottle]="30"
    [scrollWindow]="false"
    (scrolled)="onScroll()"
    class="notification-body"
  >
    <div
      class="noti-day-grouping"
      *ngFor="let data of notificationByTab$ | async"
    >
      <div class="noti-date">
        <span>{{
          checkToday(data.key) ? 'TODAY' : (data.key * 1000 | date: 'MMM d, y')
        }}</span>
      </div>
      <div
        (click)="openNotification(noti.redirection_url, noti.id, noti.is_read)"
        *ngFor="let noti of data.value"
        visiblityObserver
        (visibilityChange)="onVisibilityChange($event, noti)"
      >
        <div
          class="noti-outer"
          *ngIf="
            !isActiveUnread || (isActiveUnread && !checkIsReadByID(noti.id))
          "
        >
          <div class="noti-top">
            <div class="title-content">
              <em class="icon">
                <img
                  *ngIf="noti.subtype_icon"
                  src="{{ noti.subtype_icon }}"
                  alt=""
                />
                <img
                  *ngIf="!noti.subtype_icon"
                  src="assets/images/fallback_Icon_buildcard.svg"
                  alt=""
                />
              </em>
              <span class="title">{{ noti.entity_name || 'N/A' }}</span>
            </div>
            <div
              class="noti-time"
              [ngClass]="{
                'is-unread': !noti.is_read && !checkIsReadByID(noti.id)
              }"
            >
              {{ giveDuration(noti.created_at) }}
            </div>
          </div>
          <div class="noti-content">{{ noti.message || 'N/A' }}</div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isLoading">
      <div
        class="noti-day-grouping"
        *ngFor="
          let _ of (notificationByTab$ | async).length > perPage
            ? [1]
            : [1, 2, 3, 4]
        "
      >
        <div class="noti-date skeleton-loader"></div>
        <div class="noti-outer skeleton-loader"></div>
        <div class="noti-outer skeleton-loader"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="(notificationByTab$ | async).length === 0">
      <div class="no-notification-container">
        <img src="assets/images/no-notification-bell.svg" alt="" class="mb-5" />
        <h3>No notifications</h3>
        <p class="no-notification-message">
          Currently, you don’t have any notifications. When you receive a
          notification, it will appear here.
        </p>
      </div>
    </ng-container>
  </div>
</div>
