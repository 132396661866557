export enum NotificationCenterRequestActionType {
  Subscribe = 'subscribe',
  Get_All_Notifications = 'getAllNotifications',
  Get_Unread_Notifications = 'getUnreadNotifications',
  Mark_As_Seen = 'markAsSeen',
  Mark_As_Read = 'markAsRead',
  Mark_All_As_Read = 'markAllAsRead',
  Update_Dnd = 'UpdateDnd',
  GetLatestBroadcastNotification = 'getlatestbroadcastnotification'
}
