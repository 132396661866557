import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Angulartics2Segment } from 'angulartics2';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  @ViewChild('email') public email;
  @ViewChild('password') public password;
  @ViewChild('firstName') public firstName;
  @ViewChild('lastName') public lastName;
  @ViewChild('countryCode') public countryCode;
  @ViewChild('phone') public phone;
  public pass = { show: false };
  public showVerifyPage = false;
  public data = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    country_code: '',
    phone: ''
  };
  public CommonErrorMsg = '';
  public isCrossInvite = false;
  public signUpLoading = false;

  constructor(
    public loaderService: LoaderService,
    public commonService: CommonService,
    public dataService: DataService,
    public router: Router,
    public apiRequest: ApiRequests,
    public angulartics2Segment: Angulartics2Segment,
    public activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.commonService.newHeadertrue = true;
    if (this.dataService.getUserData()) {
      this.commonService.newHeadertrue = false;
      this.router.navigate(['/projects']);
      return;
    }
    if (window.location.href.indexOf('email') > -1) {
      this.isCrossInvite = true;
      const queryParams = this.activatedRoute.snapshot.queryParamMap;

      this.data.email = queryParams.get('email');
      this.data.first_name = queryParams.get('first_name');
      this.data.last_name = queryParams.get('last_name');
      this.data.phone = queryParams.get('phone');
      this.data.country_code = queryParams.get('country_code')
        ? '+' + queryParams.get('country_code').trim()
        : '';
      this.data['invited'] = queryParams.get('invited');
    }
  }

  public checkIfEmailValid(): boolean {
    return (
      this.email &&
      this.email.errors &&
      (this.isCrossInvite ||
        (!this.isCrossInvite && (this.email.dirty || this.email.touched)))
    );
  }

  public checkIfMobileValid(): boolean {
    return (
      this.phone &&
      this.phone.invalid &&
      (this.phone.dirty || this.phone.touched)
    );
  }

  public checkLastNameValid(): boolean {
    return (
      this.lastName &&
      this.lastName.errors &&
      (this.isCrossInvite ||
        (!this.isCrossInvite && (this.lastName.dirty || this.lastName.touched)))
    );
  }

  public checkFirstNameValid(): boolean {
    return (
      this.firstName &&
      this.firstName.errors &&
      (this.isCrossInvite ||
        (!this.isCrossInvite &&
          (this.firstName.dirty || this.firstName.touched)))
    );
  }

  public ispasswordError(): boolean {
    return (
      this.password &&
      (this.password.touched || this.password.dirty) &&
      (this.password.errors || this.checkSpacePassword())
    );
  }

  public passwordLengthCheck(): boolean {
    return (
      this.password &&
      this.password.errors &&
      this.password.errors.minlength &&
      !this.ispasswordError()
    );
  }

  public passwordRequiredCheck(): boolean {
    return this.password && this.password.errors.required;
  }

  public checkSignupValidity(): boolean {
    return (
      !this.data.email ||
      !this.data.first_name ||
      !this.data.password ||
      !this.data.last_name ||
      !this.data.phone ||
      this.checkIfEmailValid() ||
      this.checkIfMobileValid() ||
      this.checkLastNameValid() ||
      this.checkFirstNameValid() ||
      this.ispasswordError()
    );
  }

  public checkSpacePassword() {
    if (
      this.password &&
      this.password.touched &&
      this.password.value.indexOf(' ') >= 0
    ) {
      return true;
    }
    return false;
  }

  public singupClick() {
    this.signUpLoading = true;
    this.apiRequest.login_signup(this.data, 'signup').subscribe(
      (data) => {
        this.resetForm();
        this.commonService.leftPanelOpen = false;
        if (this.isCrossInvite) {
          this.showVerifyPage = false;
          this.dataService.user = data.data.user;
          this.dataService.authToken = data.data['auth-token'];
          localStorage.setItem('user', JSON.stringify(this.dataService.user));
          localStorage.setItem('authToken', this.dataService.authToken);
          this.commonService.notifyMyAccountDetail(data);
          this.loaderService.show();
          this.router.navigate(['projects']);
        } else {
          this.showVerifyPage = true;
        }
        this.signUpLoading = false;
      },
      (error) => {
        this.signUpLoading = false;
        if (error?.error) {
          this.CommonErrorMsg = error.error;
        }
      }
    );
  }

  public showPassword() {
    this.pass.show = !this.pass.show;
  }

  public resetForm() {
    this.data = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      country_code: '',
      phone: ''
    };
  }

  public onlyNumber(evt) {
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }
}
