<div class="my-meetings-sidebar">
  <!-- skeleton starts -->
  <ng-container *ngIf="skeletonLoader">
    <div class="meetings-title-wrap">
      <div class="meetings-title skeleton-loader"></div>
    </div>
    <div class="meetings-subheader">
      <div class="meetings-action-btns">
        <div class="meeting-btn skeleton-loader"></div>
      </div>
      <div class="add-meeting-btn-skeleton skeleton-loader"></div>
    </div>

    <div class="meetings-body">
      <div class="meetings-day-grouping">
        <div class="meeting-date skeleton-loader"></div>
        <div class="meeting-outer skeleton-loader"></div>
        <div class="meeting-outer skeleton-loader"></div>
      </div>

      <div class="meetings-day-grouping">
        <div class="meeting-date skeleton-loader"></div>
        <div class="meeting-outer skeleton-loader"></div>
        <div class="meeting-outer skeleton-loader"></div>
        <div class="meeting-outer skeleton-loader"></div>
      </div>

      <div class="meetings-day-grouping">
        <div class="meeting-date skeleton-loader"></div>
        <div class="meeting-outer skeleton-loader"></div>
        <div class="meeting-outer skeleton-loader"></div>
      </div>
    </div>
  </ng-container>
  <!-- skeleton ends -->

  <ng-container *ngIf="!skeletonLoader">
    <div class="meetings-title-wrap">
      <div class="meetings-title">My meetings</div>
      <em class="close-meetings" (click)="closeSidebar()"
        ><img src="../../../../../assets/images/close-black.svg" alt=""
      /></em>
    </div>
    <div class="meetings-subheader">
      <div class="meetings-action-btns">
        <div
          class="meeting-btn"
          [ngClass]="{ active: selectedTab === 'upcoming' }"
          (click)="toggleSelectedTab('upcoming')"
        >
          Upcoming
        </div>
        <div
          class="meeting-btn"
          [ngClass]="{ active: selectedTab === 'past' }"
          (click)="toggleSelectedTab('past')"
        >
          Past
        </div>
      </div>
      <div (click)="addEditMeetingModal = true" class="add-meeting-btn">
        <img src="../../../../../assets/images/add-meeting.svg" alt="" />
        <div class="tooltip-text">Schedule meeting</div>
      </div>
    </div>

    <ng-container *ngIf="meetingsData.length === 0">
      <div class="no-meeting-found" *ngIf="!isMeetingRecieved">
        <div class="no-data-content">
          <em class="icon"
            ><img src="../../../../../assets/images/no-data-thumb.svg" alt=""
          /></em>
          <div class="no-data-title">No meetings found</div>
          <div class="no-data-desc">
            No upcoming client meetings. You can schedule a meeting using the
            button below.
          </div>
          <button
            class="green-btn is-lightpurple"
            (click)="addEditMeetingModal = true"
          >
            Schedule a meeting
          </button>
        </div>
      </div>
    </ng-container>
    <div
      *ngIf="meetingsData.length > 0"
      infiniteScroll
      [infiniteScrollDistance]="0.1"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      (scrolled)="handleScrollEvent()"
      class="meetings-body"
    >
      <div class="meetings-day-grouping" *ngFor="let data of meetingsData">
        <div
          class="meeting-outer"
          *ngFor="let meeting of data.meeting; let first = first"
        >
          <div *ngIf="first" class="meeting-date">
            <span *ngIf="today === data?.date">Today</span>
            <span *ngIf="today !== data?.date">{{
              data?.date | date: 'MMM d, YYYY'
            }}</span>
          </div>
          <div class="meeting-top">
            <div class="title-content" title="{{ meeting?.name | titlecase }}">
              {{ meeting?.name | titlecase }}
            </div>
            <div class="btns-wrap">
              <div class="title-btn">
                <a
                  rel="noopener noreferrer"
                  href="javascript:void(0)"
                  (click)="redirect(meeting, 'meeting')"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.6255 4.74902V1.37402H9.25049"
                      class="stroke"
                      stroke-width="0.9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.6255 1.37402L5.12549 8.87402"
                      class="stroke"
                      stroke-width="0.9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.62549 3.62402H2.12549C1.71127 3.62402 1.37549 3.95981 1.37549 4.37402V11.874C1.37549 12.2882 1.71127 12.624 2.12549 12.624H9.62549C10.0397 12.624 10.3755 12.2882 10.3755 11.874V7.37402"
                      class="stroke"
                      stroke-width="0.9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <div class="btn-tooltip">Go to buildcard meetings</div>
              </div>
              <!-- <div class="title-btn">
                <img
                  src="../../../../../assets/images/dot-icon-black.svg"
                  alt=""
                />
                <div class="context-menu">
                  <div class="menu-btn">
                    Edit
                    <em class="icon"
                      ><img
                        src="../../../../../assets/images/edit-black.svg"
                        alt=""
                    /></em>
                  </div>
                  <div class="menu-btn">
                    Cancel
                    <em class="icon"
                      ><img
                        src="../../../../../assets/images/cancel-black.svg"
                        alt=""
                    /></em>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="time-participants">
            <div class="meeting-time">
              {{ getSystemMeetingTime(meeting) }}
              <!-- {{ meeting?.start_time }} - {{ meeting?.end_time }} IST -->
            </div>
            <div
              class="participants-list"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
              (mouseenter)="getParticipantList(meeting)"
              (mouseleave)="meeting.popoverHover = true; hidePopup(meeting)"
            >
              <div
                class="participant initial"
                [ngStyle]="{ 'background-color': meeting.bgColor }"
              >
                {{ getInitial(meeting?.user?.first_name) }}
              </div>
              <div *ngIf="meeting.participant_count > 0" class="participant">
                +{{ meeting.participant_count }}
              </div>
            </div>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="meeting.showParticipants"
              cdkConnectedOverlayPanelClass="mr-context-overlay"
              [cdkConnectedOverlayHasBackdrop]="true"
              [cdkConnectedOverlayBackdropClass]="
                'cdk-overlay-transparent-backdrop'
              "
              (backdropClick)="meeting.showParticipants = false"
            >
              <div
                (mouseenter)="meeting.popoverHover = true"
                (mouseleave)="meeting.popoverHover = false; hidePopup(meeting)"
                *ngIf="!isloading"
                class="participants-overlay-box"
              >
                <div class="participants-title">
                  Participants
                  <ng-container *ngIf="meeting.participant_count > 0"
                    >({{
                      teammateList.length +
                        expertList.length +
                        externalList.length
                    }})</ng-container
                  >
                </div>
                <div class="participants-row">
                  <div class="participants-inner-title">Host</div>
                  <div class="participants-list">
                    <div class="participant-col">
                      <em class="initital lightyellow">{{
                        getInitial(meeting?.user?.first_name)
                      }}</em>
                      <div>
                        <div *ngIf="meeting?.user?.first_name" class="name">
                          {{
                            meeting?.user?.first_name +
                              ' ' +
                              meeting?.user?.last_name
                          }}
                        </div>
                        <div *ngIf="meeting?.user?.email" class="email">
                          {{ meeting?.user?.email }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="meeting.participant_count > 0">
                  <div *ngIf="teammateList.length > 0" class="participants-row">
                    <div class="participants-inner-title">
                      Teammates
                      <span class="count">{{ teammateList.length }}</span>
                    </div>
                    <div class="participants-list">
                      <!-- <div class="participant-col">
                  <em class="initital">{{
                    getInitial(host.name ? host.name : host.email)
                  }}</em>
                  <div class="name">
                    {{ host.name ? host.name : host.email }}
                    <span class="host">(host)</span>
                  </div>
                </div> -->
                      <ng-container *ngIf="teammateList.length > 0">
                        <div
                          *ngFor="let teamMate of teammateList"
                          class="participant-col"
                        >
                          <em class="initital lightyellow">{{
                            getInitial(
                              teamMate.name ? teamMate.name : teamMate.email
                            )
                          }}</em>
                          <div>
                            <div *ngIf="teamMate.name" class="name">
                              {{ teamMate.name }}
                            </div>
                            <div *ngIf="teamMate.email" class="email">
                              {{ teamMate.email }}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div *ngIf="expertList.length > 0" class="participants-row">
                    <div class="participants-inner-title">
                      Experts <span class="count">{{ expertList.length }}</span>
                    </div>
                    <div class="participants-list">
                      <div
                        *ngFor="let expert of expertList"
                        class="participant-col"
                      >
                        <em class="initital lightyellow">{{
                          getInitial(expert.name ? expert.name : expert.email)
                        }}</em>
                        <div>
                          <div *ngIf="expert.name" class="name">
                            {{ expert.name }}
                          </div>
                          <div class="email" *ngIf="expert.email">
                            {{ expert.email }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="externalList.length > 0" class="participants-row">
                    <div class="participants-inner-title">
                      External
                      <span class="count">{{ externalList.length }}</span>
                    </div>
                    <div class="participants-list">
                      <div
                        *ngFor="let external of externalList"
                        class="participant-col"
                      >
                        <em class="initital lightyellow">{{
                          getInitial(
                            external.name ? external.name : external.email
                          )
                        }}</em>
                        <div>
                          <div *ngIf="external.name" class="name">
                            {{ external.name }}
                          </div>
                          <div class="email" *ngIf="external.email">
                            {{ external.email }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="isloading" class="participants-overlay-box">
                <img
                  class="d-block m-auto"
                  src="../../../../../assets/images/pagination-loader.gif"
                  alt=""
                />
              </div>
            </ng-template>
          </div>
          <div class="meeting-info-row">
            <em class="icon"
              ><img src="../../../../../assets/images/bc-icon.svg" alt=""
            /></em>
            <a
              rel="noopener noreferrer"
              href="javascript:void(0)"
              (click)="redirect(meeting, 'project')"
              class="link-text"
              title="{{
                meeting?.project?.name
                  ? meeting?.project?.name
                  : meeting?.pod?.name
              }}"
            >
              {{
                meeting?.project?.name
                  ? meeting?.project?.name
                  : meeting?.pod?.name
              }}
            </a>
          </div>
          <div class="meeting-info-row has-postfix-icon">
            <em class="icon"
              ><img
                src="../../../../../assets/images/link-icon-black.svg"
                alt=""
            /></em>
            <a
              rel="noopener noreferrer"
              href="{{ meeting.url }}"
              *ngIf="meeting.url"
              target="_blank"
              class="link-text"
              >{{ meeting.url }}</a
            >
            <em class="copy-meeting-link">
              <span
                class="copy-link align-self-end copy-email"
                tooltip="Copy link"
                placement="left"
                triggers="mouseenter:mouseleave"
                *ngIf="meeting.url && !addClassCopy"
                ngxClipboard
                (cbOnSuccess)="addClassForCopy()"
                [cbContent]="meeting.url"
              >
                <img
                  alt="copy"
                  src="../../../../../assets/images/copy-purple.svg"
                  width="13"
                  height="15"
                />
              </span>
            </em>
          </div>
        </div>
      </div>

      <!--loader-->
      <div *ngIf="pageLoader" class="meetings-day-grouping">
        <div class="meeting-date skeleton-loader"></div>
        <div class="meeting-outer skeleton-loader"></div>
        <div class="meeting-outer skeleton-loader"></div>
      </div>
      <!--loader-->
    </div>
  </ng-container>
</div>

<add-edit-modal
  [meetingKind]="'online'"
  *ngIf="addEditMeetingModal"
  (meetingupdate)="updateMeeting($event)"
  (closeModal)="addEditMeetingModal = false"
  [isMymeeting]="true"
></add-edit-modal>
