import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMyDatePickerModule } from 'angular-mydatepicker-ivy';
import { SharedModule } from '../../shared/shared-module/shared.module';
import { MeetingSharedService } from './service/meeting-shared.service';
import { AddEditModalComponent } from './add-edit-meeting-modal/add-edit-modal.component';
import { ParticipantDropdownComponent } from './add-edit-meeting-modal/participant-dropdown/participant-dropdown.component';
import { MeetingCustomDropdownListComponent } from './add-edit-meeting-modal/meeting-custom-dropdown-list/meeting-custom-dropdown-list.component';
import { CustomAngularEditorModule } from '../../shared/custom-angular-editor/custom-angular-editor.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMyDatePickerModule,
    SharedModule,
    CustomAngularEditorModule,
    InfiniteScrollModule
  ],
  declarations: [
    AddEditModalComponent,
    ParticipantDropdownComponent,
    MeetingCustomDropdownListComponent
  ],
  exports: [
    AddEditModalComponent,
    ParticipantDropdownComponent,
    MeetingCustomDropdownListComponent
  ],
  providers: [MeetingSharedService]
})
export class MeetingSharedModule {}
