import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { FooterService } from '../../../shared/shared-module/services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public currentProjectId;
  public builderPlanners = [];
  public isShowTrackerList;
  public isShowUiwList;
  public myEventSubscription;
  public myProjectDetailSubscription;
  public builderPlannerSub;
  public builderPlannerSubEdit;
  mattermost_url = '';
  uniCode;
  currentYear = new Date().getFullYear();
  public footerExpand = false;
  subscription = new Subject();
  buildCardID: number;
  bHome_url = '';
  isStudio4BuildCard = false;

  constructor(
    private commonService: CommonService,
    public dataService: DataService,
    private footerService: FooterService
  ) {}

  ngOnInit() {
    this.myEventSubscription =
      this.commonService.notifyCurrentProjectIdSubject.subscribe((data) => {
        if (data) {
          if (
            this.isStatusValid(data.project_status) &&
            this.currentProjectId !== data.project_id
          ) {
            this.currentProjectId = data.project_id;
            if (this.builderPlannerSub) {
              this.builderPlannerSub.unsubscribe();
            }
            this.builderPlannerSub = this.footerService
              .getBuilderPlanners(this.currentProjectId)
              .subscribe((response) => {
                if (response.builderplanners) {
                  this.builderPlanners = response.builderplanners;
                }
              });
          } else if (!this.isStatusValid(data.project_status)) {
            this.builderPlanners = [];
          }
        }
      });
    this.myProjectDetailSubscription =
      this.commonService.notifyProjectDetailSubject.subscribe((data) => {
        if (data) {
          this.uniCode = data.uniq_code;
          this.mattermost_url = data.mattermost_workspace_url;
          this.buildCardID = data.build_card_id;

          //For B-Home
          if (data?.partner_name === 'uae_partner') {
            this.bHome_url = this.dataService.partnerData.builder_home4_domain;
          } else if (data?.studio_version == 2) {
            this.isStudio4BuildCard = true;
            this.bHome_url = this.dataService.partnerData.builder_home4_domain;
          } else {
            this.isStudio4BuildCard = false;
            this.bHome_url = this.dataService.partnerData.builder_live_domain;
          }
        }
      });

    this.builderPlannerSubEdit =
      this.commonService.notifyFooterBuilderPlannerSubject.subscribe((data) => {
        if (data) {
          const index = this.builderPlanners.findIndex((item) => {
            return item.id === data.data.builderplanner.id;
          });
          this.builderPlanners[index] = data.data.builderplanner;
        }
      });
  }

  isStatusValid(status) {
    return status !== 'paid' && status !== 'unpaid' && status !== 'pending';
  }

  public openLeftPanel() {
    return this.commonService.leftPanelOpen;
  }

  resetAllTab() {
    this.isShowTrackerList = false;
    this.isShowUiwList = false;
  }
  toggleTrackerList(event: Event, appName) {
    this.resetAllTab();
    event.stopPropagation();
    if (appName === 'tracker') {
      this.isShowTrackerList = this.isShowTrackerList ? false : true;
    } else if (appName === 'uiw') {
      this.isShowUiwList = this.isShowUiwList ? false : true;
    }
    document.querySelector('body').addEventListener('click', () => {
      this.resetAllTab();
    });
  }
  openTrackerLink(url, urlUW?) {
    if (!url) {
      const domain = this.bHome_url;
      const currentUrl = domain + '/project-progress/' + this.uniCode + '/home';
      window.open(currentUrl, '_blank');
    } else {
      urlUW
        ? window.open(url + '?redirect_to_uiw=true', '_blank')
        : window.open(url, '_blank');
    }
  }

  public goToMattermost() {
    window.open(this.mattermost_url);
  }

  openBNowLink() {
    window.open(environment.B_Now_URL + this.buildCardID);
  }

  ngOnDestroy() {
    this.subscription.next(null);
    this.subscription.complete();
    this.myEventSubscription.unsubscribe();
    if (this.myProjectDetailSubscription) {
      this.myProjectDetailSubscription.unsubscribe();
    }
    if (this.builderPlannerSub) {
      this.builderPlannerSub.unsubscribe();
    }
  }

  mobileFooter() {
    this.footerExpand = !this.footerExpand;
  }

  naviagteToTracker() {
    window.open(this.dataService.partnerData?.tracker_frontend_url, '_blank');
  }
}
