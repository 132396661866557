export enum NotificationCenterResponseActionType {
  Notifications_Count_Updated = 'updatedNotificationsCount',
  Subscription_Confirmation = 'subscriptionConfirmation',
  All_Notifications = 'allNotifications',
  Unread_Notifications = 'unreadNotifications',
  Unseen_Notifications_Count = 'unseenNotificationsCount',
  Unread_Notification_Count = 'unreadNotificationCount',
  Dnd_Updated = 'dndUpdated',
  LatestBroadcastNotification = 'newNotifications',
  Mark_All_Read = 'markedAllAsRead',
  Error = 'error'
}
