import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FaConfig,
  FaIconLibrary,
  FontAwesomeModule
} from '@fortawesome/angular-fontawesome';
import { faClone, faEdit, far } from '@fortawesome/free-regular-svg-icons';
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faBan,
  faBarsProgress,
  faBold,
  faBoxArchive,
  faCalendarDays,
  faCaretDown,
  faChainBroken,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboard,
  faClock,
  faCode,
  faDownload,
  faEllipsisV,
  faEllipsisVertical,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileText,
  faFilm,
  faFilter,
  faFloppyDisk,
  faFolder,
  faFont,
  faGear,
  faHandPaper,
  faImage,
  faIndent,
  faInfoCircle,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faMinus,
  faMinusCircle,
  faOutdent,
  faPaperclip,
  faPaste,
  faPencil,
  faPlus,
  faPlusCircle,
  faRedo,
  faRefresh,
  faRemove,
  faRepeat,
  faReply,
  faSearch,
  faShareFromSquare,
  faSmile,
  faSquare,
  faStar,
  faStrikethrough,
  faSubscript,
  faSuperscript,
  faTasks,
  faThumbsUp,
  faTicket,
  faTimes,
  faTimesCircle,
  faTrashCan,
  faUnderline,
  faUndo,
  faUser,
  faVideoCamera,
  faWarning,
  faXmark,
  fas
} from '@fortawesome/free-solid-svg-icons';
@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class FontAwesomeIconModule {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {
    faConfig.fixedWidth = true;
    library.addIconPacks(fas, far);
    library.addIcons(
      faSquare,
      faCheckSquare,
      faEllipsisV,
      faAngleRight,
      faAngleLeft,
      faArrowLeft,
      faTimes,
      faTimesCircle,
      faExclamationCircle,
      faCircle,
      faFloppyDisk,
      faPencil,
      faEye,
      faEyeSlash,
      faWarning,
      faDownload,
      faFileText,
      faFilter,
      faChevronDown,
      faChevronUp,
      faChevronLeft,
      faChevronRight,
      faFolder,
      faTrashCan,
      faPlusCircle,
      faPlus,
      faMinus,
      faMinusCircle,
      faPaperclip,
      faArrowDown,
      faFont,
      faBold,
      faItalic,
      faUnderline,
      faStrikethrough,
      faSubscript,
      faSuperscript,
      faAlignLeft,
      faAlignCenter,
      faAlignRight,
      faAlignJustify,
      faOutdent,
      faIndent,
      faListUl,
      faListOl,
      faLink,
      faChainBroken,
      faImage,
      faVideoCamera,
      faRemove,
      faCode,
      faUndo,
      faRedo,
      faRepeat,
      faClipboard,
      faPaste,
      faCheck,
      faCheckCircle,
      faStar,
      faTasks,
      faClock,
      faBarsProgress,
      faBan,
      faHandPaper,
      faBoxArchive,
      faClone,
      faSearch,
      faXmark,
      faEdit,
      faGear,
      faTicket,
      faUser,
      faEllipsisVertical,
      faExclamationTriangle,
      faRefresh,
      faReply,
      faInfoCircle,
      faShareFromSquare,
      faCalendarDays,
      faFilm,
      faSmile,
      faCaretDown,
      faThumbsUp,
      faArchive
    );
  }
}
