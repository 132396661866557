<section class="middle-sec common-redesign forget-password-page">
  <div class="pm-dashboard-modal">
    <div *ngIf="!showSucess">
      <h2>Forgot your password?</h2>
      <p class="pb-4">
        Please enter your registered email address to retrieve password.
      </p>
      <form class="common-form" name="forgotForm" #forgotForm="ngForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="email_add">Email address</label>
              <div class="common-input-box sa-email-box">
                <input
                  id="email_add"
                  type="email"
                  name="email"
                  class="common-input"
                  placeholder="You@example.com"
                  required
                  autocomplete="off"
                  maxlength="100"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  #email="ngModel"
                  [(ngModel)]="data.email"
                />
              </div>
              <div
                class="invalid-feedback"
                *ngIf="email.touched && email.invalid && email.errors.required"
              >
                Please enter valid email
              </div>
              <span
                class="invalid-feedback"
                *ngIf="
                  !(email.touched && email.invalid && email.errors.required)
                "
                >{{ ErrorMsg }}</span
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group m12">
              <input
                type="submit"
                class="btn btn-primary comm-login-module-btn"
                name="submit"
                value="Send"
                (click)="forgotClick()"
                [disabled]="forgotForm.invalid"
              />
            </div>
          </div>
        </div>
      </form>
      <p>
        Back to
        <a [routerLink]="['/login']">Login</a>
      </p>
    </div>
    <div *ngIf="showSucess">
      <h2>Instructions sent!</h2>
      <p class="pb-4">
        Instructions for resetting your password have been sent to<br />
        <strong>{{ data.email }}</strong>
      </p>
      <p>
        Back to
        <a [routerLink]="['/login']">Login</a>
      </p>
    </div>
  </div>
</section>
