import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CommonService } from './services/common.service';

@Injectable()
export class ForceIdHandlerInterceptor implements HttpInterceptor {
  constructor(private commonService: CommonService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = req.url;
    if (
      url.startsWith(environment.API_BASE_URL) &&
      url.includes('/projects/')
    ) {
      if (req.method === 'GET' && !url.includes('forceid')) {
        url = url + this.commonService.forceRedirect(url.includes('?'));
      }

      // handling for unpaid buildcard forceid append
      const parts = url.split('/');
      const projectsIndex = parts.indexOf('projects');
      let projectId;
      if (projectsIndex + 1 < parts.length) {
        projectId = parts[projectsIndex + 1];
      }
      const projectDetail =
        this.commonService.notifyProjectDetailSubject?.getValue() ||
        this.commonService.selectedProjectData;
      if (
        !isNaN(parseInt(projectId)) &&
        projectDetail?.id == parseInt(projectId) &&
        projectDetail.status == 'pending' &&
        !projectDetail.first_payment_date
      ) {
        url +=
          (url.includes('?') ? '&' : '?') + `unpaid_id=${parseInt(projectId)}`;
      }
    }
    // Clone the request to modify it
    const modifiedReq = req.clone({
      url: url
    });

    return next.handle(modifiedReq);
  }
}
