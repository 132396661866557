import { Injectable } from '@angular/core';

@Injectable()
export class ToastMessageService {
  public showToastMsg = false;
  public toastMessage: string;
  public errorClass = false;

  public showToastMessage(message, time?, errorClass = false) {
    time = time ? time : 2000;
    this.toastMessage = message;
    this.showToastMsg = true;
    this.errorClass = errorClass;
    setTimeout(() => {
      this.showToastMsg = false;
    }, time);
  }
}
