import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'timezoneConversion'
})
export class GenericTimezoneConversionPipe implements PipeTransform {
  timezoneList = {
    GMT: 'GMT',
    BST: 'Europe/London',
    CST: 'America/Chicago',
    GST: 'Asia/Dubai',
    IST: 'Asia/Kolkata'
  };
  transform(value, fromTimezone: string, toTimezone: string, isClient = false) {
    if (!value) return '';
    const [fromTime, toTime] = value.split(' to ');
    const resultedTimezone = isClient
      ? toTimezone
      : this.timezoneList[toTimezone];
    const momentFrom = moment
      .tz(fromTime, 'hh:mma', fromTimezone)
      .tz(resultedTimezone)
      .format('hh:mmA');
    const momentTo = moment
      .tz(toTime, 'hh:mma', fromTimezone)
      .tz(resultedTimezone)
      .format('hh:mmA');
    const toTimeText = this.convertToTimeText(toTimezone, isClient);
    const convertedTimeRange = `${toTimeText}- ${momentFrom} to ${momentTo}`;
    return convertedTimeRange;
  }

  convertToTimeText(toTimezone, isClient) {
    if (isClient) {
      const momentTz = moment.tz(toTimezone);
      const activeZone1 = momentTz.format('Z');
      const activeZone2 = momentTz.format('z');
      const activeTzAbbr =
        activeZone1.substring(0, 3) == activeZone2.substring(0, 3)
          ? `${toTimezone} (Client)`
          : `${activeZone2} (Client)`;
      return activeTzAbbr;
    } else {
      return toTimezone;
    }
  }
}
