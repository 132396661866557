import { TribeState } from './tribe.state';
import {
  GetAllTribe,
  AddTribe,
  UpdateTribe,
  DeleteTribe
} from './tribe.action';
import { TribeModel } from './tribe.model';

export const Tribe = [
  TribeState,
  TribeModel,
  GetAllTribe,
  AddTribe,
  UpdateTribe,
  DeleteTribe
];
