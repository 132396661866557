import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { HttpRequests } from '../../../shared/shared-module/services/http-requests.service';
import { Constants } from '../../../shared/shared-module/utility/constants';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    public dataService: DataService,
    public httpRequest: HttpRequests
  ) {}

  public getListOfAccount(pagination, query?) {
    let API =
      this.dataService.getServerUrlForAccounts() +
      'pm_dashboard/users/signup_user_partner_data';
    API += '?page=' + pagination.page + '&per_page=' + pagination.perPage;
    if (query) {
      API = API + '&search_txt=' + query;
    }
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    const partnerData = JSON.parse(localStorage.getItem('partnerData'));
    const partnerCode =
      partnerData && partnerData.code ? partnerData.code : 'e-ai';
    httpOptions.headers = httpOptions.headers.set('partner-code', partnerCode);
    return this.httpRequest.get(API, httpOptions).pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getSpecIDStudio(accountData?) {
    const API =
      this.dataService.getServerUrlForAccounts() +
      'pm_dashboard/create_spec_call';
    const data = accountData;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );

    return this.httpRequest.post(API, data, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  priceMultiplier(userId, data) {
    const API = `${this.dataService.getServerUrlForAccounts()}pm_dashboard/users/${userId}`;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );

    return this.httpRequest.put(API, { user: data }, httpOptions).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getUnpaidBuildCardList(clientId) {
    const url = `${this.dataService.getServerUrl()}${Constants.apiEndPoints.getUnpaidBuildCardList.replace(
      '[client_id]',
      clientId
    )}`;
    return this.httpRequest
      .get(url, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
}
