import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeIconModule } from '../shared-module/font-awesome-icon.module';
import { AeSelectComponent } from './ae-select/ae-select.component';
import { CustomAngularEditorToolbarComponent } from './custom-angular-editor-toolbar/custom-angular-editor-toolbar.component';
import { CustomAngularEditorComponent } from './custom-angular-editor/custom-angular-editor.component';
import { CustomAngularEditorService } from './services/custom-angular-editor.service';

@NgModule({
  declarations: [
    CustomAngularEditorComponent,
    CustomAngularEditorToolbarComponent,
    AeSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeIconModule
  ],
  exports: [CustomAngularEditorComponent],
  providers: [CustomAngularEditorService]
})
export class CustomAngularEditorModule {}
export { AngularEditorConfig } from './config';
