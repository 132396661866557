//Here we define four actions for CRUD operations respectively
export enum ManagerActionTypes {
  ADD_ONE = '[Managers] Add One',
  UPDATE_ONE = '[Managers] Update One',
  DELETE_ONE = '[Managers] Delete One',
  GET_ALL = '[Managers] Get All',
  GET_ONE = '[Managers] Get One',
  RE_INVITE_USER = '[Managers] ReInvite User',
  UPDATE_MANAGER_STATUS = '[Managers] Update Manager Status',
  SET_MANAGER_SQUAD_TAB = 'Manager Squad Tab',
  GET_CPE = '[Managers] Get CPE',
  GET_CPM = '[Managers] Get CPM',
  GET_CTE = '[Managers] Get CTE',
  GET_SuccessGenius = '[Managers] Get SuccessGenius',
  GET_Others = '[Managers] Get Others',
  SET_VIEW = '[Manager] Set View',
  GET_SQUAD = '[Manager] Get SQUAD'
}

//Read All
export class GetAllManager {
  static readonly type = ManagerActionTypes.GET_ALL;
  constructor(
    public page: number,
    public perPage: number,
    public searchText: string,
    public value: string,
    public filterStr: string,
    public min: boolean
  ) {}
  // constructor(){}
}

//Set manager squad tab
export class SetManagerSquadTab {
  static readonly type = 'Manager Squad Tab';
  constructor(public payload: string) {}
}

//Create
export class AddManager {
  static readonly type = '[Managers] Add';
  constructor(public payload) {}
}

//Update
export class UpdateManager {
  static readonly type = '[Managers] Update';
  constructor(
    public payload,
    public id: number
  ) {}
}

//Read
export class GetManager {
  static readonly type = ManagerActionTypes.GET_ONE;
  constructor(public id: number) {}
}

//ReInvite User
export class ReInviteUser {
  static readonly type = ManagerActionTypes.RE_INVITE_USER;
  constructor(public managerId: number) {}
}

// Update Manager Status to Archive or UnArchive
export class UpdateManagerStatus {
  static readonly type = ManagerActionTypes.UPDATE_MANAGER_STATUS;
  constructor(
    public id: number,
    public type: boolean
  ) {}
}

//Delete
export class DeleteManager {
  static readonly type = '[Managers] Delete';
  constructor(public id: number) {}
}

export class SetSearchText {
  static readonly type = '[Managers] SearchText';
  constructor(public text: string) {}
}

export class SetFilteredData {
  static readonly type = '[Managers] filteredData';
  constructor(public data) {}
}

export class SetSelectedManager {
  static readonly type = '[Managers] SelectedManager';
  constructor(public data) {}
}

export class GetCPEManager {
  static readonly type = ManagerActionTypes.GET_CPE;
  constructor(
    public page: number,
    public perPage: number,
    public searchText: string,
    public value: string,
    public filterStr: string,
    public min: boolean
  ) {}
}

export class GetCPMManager {
  static readonly type = ManagerActionTypes.GET_CPM;
  constructor(
    public page: number,
    public perPage: number,
    public searchText: string,
    public value: string,
    public filterStr: string,
    public min: boolean
  ) {}
}

export class GetCTEManager {
  static readonly type = ManagerActionTypes.GET_CTE;
  constructor(
    public page: number,
    public perPage: number,
    public searchText: string,
    public value: string,
    public filterStr: string,
    public min: boolean
  ) {}
}

export class GetSuccessGeniusManager {
  static readonly type = ManagerActionTypes.GET_SuccessGenius;
  constructor(
    public page: number,
    public perPage: number,
    public searchText: string,
    public value: string,
    public filterStr: string,
    public min: boolean
  ) {}
}
export class GetOthersManager {
  static readonly type = ManagerActionTypes.GET_Others;
  constructor(
    public page: number,
    public perPage: number,
    public searchText: string,
    public value: string,
    public filterStr: string,
    public min: boolean
  ) {}
}

export class SetManagerViewType {
  static readonly type = ManagerActionTypes.SET_VIEW;
  constructor(public text: string) {}
}

export class getManagerSquad {
  static readonly type = ManagerActionTypes.GET_SQUAD;
}

export type ManagerActions =
  | AddManager
  | UpdateManager
  | DeleteManager
  | GetAllManager
  | GetManager
  | ReInviteUser
  | UpdateManagerStatus
  | SetManagerSquadTab
  | SetSearchText
  | SetSelectedManager
  | GetCPEManager
  | GetCPMManager
  | GetCTEManager
  | GetOthersManager;
