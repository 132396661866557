import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';

import moment from 'moment';
import 'moment-timezone';
import { catchError, map } from 'rxjs/operators';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { HttpRequests } from '../../../shared/shared-module/services/http-requests.service';
import { Constants } from '../../../shared/shared-module/utility/constants';

@Injectable()
export class MeetingSharedService {
  closeMyMeeting = new BehaviorSubject<boolean>(false);
  constructor(
    public httpRequest: HttpRequests,
    public dataService: DataService
  ) {}

  public getMeetings(type, page, perPage) {
    let API =
      this.dataService.getServerUrl() +
      'meetings?page=' +
      page +
      '&per_page=' +
      perPage +
      '&timezone=' +
      moment.tz.guess() +
      '&status=confirmed' +
      this.dataService.forceRedirect(true);
    if (type) {
      API = API + '&tab=' + type;
    }
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getMeetingById(project_id, meeting_id) {
    const API = `${this.dataService.getServerUrl()}projects/${project_id}/meetings/${meeting_id}`;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  editorLink(event) {
    if (event.target.tagName.toLowerCase() === 'a') {
      event.target.classList.add('linkClicked');
      event.target.setAttribute('contenteditable', 'false');
    }
  }

  getClient(id) {
    const API =
      this.dataService.getServerUrl() +
      'projects/' +
      id +
      '/' +
      Constants.apiEndPoints.client;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getParticipantList(projectId, meetingId) {
    const API = `${this.dataService.getServerUrl()}projects/${projectId}/meetings/${meetingId}/participants?forceid=${projectId}`;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getUserTimeZoneList() {
    const API =
      this.dataService.getServerUrl() + Constants.apiEndPoints.timezone;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getRecurringEditStatus(meet_pid) {
    const API = `${this.dataService.getServerUrl()}meetings/recurring_meeting_status/?meet_pid=${meet_pid}`;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public postMeeting(meetingObj, project_id) {
    const data = new FormData();
    data.append('meeting[name]', meetingObj.meetingName);
    data.append('meeting[timezone]', meetingObj.timezone);
    data.append('meeting[duration]', meetingObj.duration);
    data.append('meeting[meeting_provider]', meetingObj.meeting_provider);
    data.append('meeting[date]', meetingObj.meetingDate);
    data.append('meeting[start_time]', meetingObj.meetingStartTime);
    data.append('meeting[end_time]', meetingObj.meetingEndTime);
    data.append('meeting[user_id]', this.dataService.user.id);
    data.append(
      'meeting[agenda]',
      meetingObj.meetingAgenda ? meetingObj.meetingAgenda : ''
    );
    data.append(
      'meeting[notes]',
      meetingObj.meetingDescription ? meetingObj.meetingDescription : ''
    );
    data.append('meeting[meeting_kind]', meetingObj.meeting_kind);
    data.append('meeting[visibility_type]', meetingObj.visibility_type);

    if (meetingObj.meeting_kind == 'offline') {
      // data.append(
      //   'meeting[offline_recording_url]',
      //   meetingObj.offline_recording_url
      // );
      data.append(
        'meeting[meeting_recordings_attributes][][recording_url]',
        meetingObj.offline_recording_url
      );
      data.append('meeting[minutes_of_meeting]', meetingObj.meetingMinutes);
    }

    if (meetingObj.meeting_kind == 'recurring') {
      data.append(
        'meeting[occurrence_end_date]',
        meetingObj.occurrence_end_date
      );

      Object.keys(meetingObj.occurrence_meta).forEach((key) => {
        if (
          meetingObj.occurrence_meta[key] &&
          Array.isArray(meetingObj.occurrence_meta[key])
        ) {
          meetingObj.occurrence_meta[key].forEach((value) => {
            data.append('meeting[occurrence_meta][' + key + '][]', value);
          });
        } else {
          data.append(
            'meeting[occurrence_meta][' + key + ']',
            meetingObj.occurrence_meta[key]
          );
        }
      });
    }

    if (meetingObj.meetingInvitee && meetingObj.meetingInvitee.length > 0) {
      meetingObj.meetingInvitee.forEach((element) => {
        data.append('meeting[members][]', element.email);
      });
    }
    data.append('is_revamp', 'true');
    if (
      meetingObj.meetingParticipants &&
      meetingObj.meetingParticipants.length > 0
    ) {
      meetingObj.meetingParticipants.forEach((participantObj) => {
        for (const j in participantObj) {
          data.append(
            'meeting[participants_attributes][][' + j + ']',
            participantObj[j]
          );
        }
      });
    }
    if (meetingObj.meetingFile && meetingObj.meetingFile.length > 0) {
      meetingObj.meetingFile.forEach((element) => {
        data.append('meeting[attachments_attributes][][file]', element);
      });
    }
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.getMeetings.replace('[project_id]', project_id);
    return this.httpRequest
      .post(API, data, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  cancleMeeting(meeting_id, project_id, isCancelAll = false) {
    const cancellAllCheck = isCancelAll ? '?recurrence_type=all' : '';
    const API =
      this.dataService.getServerUrl() +
      'projects/' +
      project_id +
      '/meetings/' +
      meeting_id +
      '/cancel' +
      cancellAllCheck;
    return this.httpRequest
      .delete(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  updateMeeting(
    project_id,
    meeting_id,
    meetingObj,
    updateSingleInstance = false
    // attendes = null,
    // customersharing = null
  ) {
    let data = new FormData();

    if (meeting_id && (meetingObj?.meetingName || updateSingleInstance)) {
      if (meetingObj.meetingName) {
        data.append('meeting[name]', meetingObj.meetingName);
      }
      data.append('meeting[timezone]', meetingObj.timezone);
      data.append('meeting[duration]', meetingObj.duration);
      data.append('meeting[meeting_provider]', meetingObj.meeting_provider);
      data.append('meeting[date]', meetingObj.meetingDate);
      data.append('meeting[start_time]', meetingObj.meetingStartTime);
      data.append('meeting[end_time]', meetingObj.meetingEndTime);
      data.append('meeting[user_id]', this.dataService.user.id);
      data.append(
        'meeting[agenda]',
        meetingObj.meetingAgenda ? meetingObj.meetingAgenda : ''
      );
      data.append(
        'meeting[notes]',
        meetingObj.meetingDescription ? meetingObj.meetingDescription : ''
      );
      // data.append('meeting[meeting_kind]', meetingObj.meeting_kind);
      data.append('meeting[visibility_type]', meetingObj.visibility_type);

      if (meetingObj.meeting_kind == 'offline') {
        // data.append(
        //   'meeting[meeting_recordings_attributes][][recording_url]',
        //   meetingObj.offline_recording_url
        // );
        data.append('meeting[minutes_of_meeting]', meetingObj.meetingMinutes);
      }

      if (meetingObj.meeting_kind == 'recurring') {
        data.append(
          'meeting[occurrence_end_date]',
          meetingObj.occurrence_end_date
        );

        Object.keys(meetingObj.occurrence_meta).forEach((key) => {
          if (
            meetingObj.occurrence_meta[key] &&
            Array.isArray(meetingObj.occurrence_meta[key])
          ) {
            meetingObj.occurrence_meta[key].forEach((value) => {
              data.append('meeting[occurrence_meta][' + key + '][]', value);
            });
          } else {
            data.append(
              'meeting[occurrence_meta][' + key + ']',
              meetingObj.occurrence_meta[key]
            );
          }
        });
      }

      if (meetingObj.meetingInvitee && meetingObj.meetingInvitee.length > 0) {
        meetingObj.meetingInvitee.forEach((element) => {
          data.append('meeting[members][]', element.email);
        });
      }
      data.append('is_revamp', 'true');
      if (
        meetingObj.meetingParticipants &&
        meetingObj.meetingParticipants.length > 0
      ) {
        meetingObj.meetingParticipants.forEach((participantObj) => {
          for (const j in participantObj) {
            data.append(
              'meeting[participants_attributes][][' + j + ']',
              participantObj[j]
            );
          }
        });
      }
      if (meetingObj.meeting_owner) {
        data.append('meeting[owner_changed]', 'true');
        data.append('meeting[user_id]', meetingObj.meeting_owner);
      }
      if (meetingObj.meetingFile && meetingObj.meetingFile.length > 0) {
        meetingObj.meetingFile.forEach((element) => {
          if (element.id && element.isRemove) {
            data.append('meeting[attachments_attributes][][id]', element.id);
            data.append('meeting[attachments_attributes][][_destroy]', 'true');
          } else if (!element.id) {
            data.append('meeting[attachments_attributes][][file]', element);
          }
        });
      }
    } else {
      data = meetingObj;
    }
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.updateMeeting.replace('[project_id]', project_id) +
      meeting_id;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  updateAllRecurringMeeting(meetingObj, project_id, meeting_id) {
    const data = new FormData();
    data.append('meeting[meeting_provider]', meetingObj.meeting_provider);
    data.append('meeting[recurrence_type]', 'all');

    if (!meetingObj.isDisabledForm) {
      data.append('meeting[name]', meetingObj.meetingName);
      data.append('meeting[timezone]', meetingObj.timezone);
      data.append('meeting[duration]', meetingObj.duration);
      data.append('meeting[date]', meetingObj.meetingDate);
      data.append('meeting[start_time]', meetingObj.meetingStartTime);
      data.append('meeting[agenda]', meetingObj.meetingAgenda);
      data.append('meeting[end_time]', meetingObj.meetingEndTime);

      data.append(
        'meeting[occurrence_end_date]',
        meetingObj.occurrence_end_date
      );

      Object.keys(meetingObj.occurrence_meta).forEach((key) => {
        if (
          meetingObj.occurrence_meta[key] &&
          Array.isArray(meetingObj.occurrence_meta[key])
        ) {
          meetingObj.occurrence_meta[key].forEach((value) => {
            data.append('meeting[occurrence_meta][' + key + '][]', value);
          });
        } else {
          data.append(
            'meeting[occurrence_meta][' + key + ']',
            meetingObj.occurrence_meta[key]
          );
        }
      });

      if (meetingObj.meetingInvitee && meetingObj.meetingInvitee.length > 0) {
        meetingObj.meetingInvitee.forEach((element) => {
          data.append('meeting[members][]', element.email);
        });
      }
    } else if (meetingObj.meeting_provider === 'zoom') {
      if (meetingObj.meetingInvitee && meetingObj.meetingInvitee.length > 0) {
        meetingObj.meetingInvitee.forEach((element) => {
          data.append('meeting[members][]', element.email);
        });
      }
    }

    data.append('meeting[notes]', meetingObj.meetingDescription);
    data.append('meeting[minutes_of_meeting]', meetingObj.meetingMinutes);

    if (meetingObj.meetingFile && meetingObj.meetingFile.length > 0) {
      meetingObj.meetingFile.forEach((element) => {
        if (element.id && element.isRemove) {
          data.append('meeting[attachments_attributes][][id]', element.id);
          data.append('meeting[attachments_attributes][][_destroy]', 'true');
        } else if (!element.id) {
          data.append('meeting[attachments_attributes][][file]', element);
        }
      });
    }

    data.append('is_revamp', 'true');
    if (
      meetingObj.meetingParticipants &&
      meetingObj.meetingParticipants.length > 0
    ) {
      meetingObj.meetingParticipants.forEach((participantObj) => {
        for (const j in participantObj) {
          data.append(
            'meeting[participants_attributes][][' + j + ']',
            participantObj[j]
          );
        }
      });
    }

    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.updateAllRecurringMeeting
        .replace('[project_id]', project_id)
        .replace('[meeting_id]', meeting_id);
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getProjectDetailById(id, isForceRedirect = false) {
    let API = `${this.dataService.getServerUrl()}projects/${id}`;
    if (isForceRedirect) {
      API += '?forceid=' + id;
    }
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getProjectsName(params) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.getSidebarProjects +
      '?page=' +
      params.page +
      '&per_page=' +
      params.perPage +
      '&my_meeting=true' +
      '&timezone=' +
      Intl.DateTimeFormat().resolvedOptions().timeZone +
      (params.search ? `&search=${params.search}` : '') +
      this.dataService.forceRedirect(true);

    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getFeatureDifference(spec_id) {
    const API =
      this.dataService.getServerUrl() +
      'spec_calls/' +
      spec_id +
      '/' +
      Constants.apiEndPoints.feature_difference;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
}
