export class Constants {
  static apiEndPoints = {
    productListUrl:
      'https://api-productlisting.engineer.ai/products?query=[query]',
    signup: 'registrations/sign_up',
    login: 'sessions/sign_in',
    code_repo: 'code_repos',
    add_new_templates: 'templates',
    forgot: 'registrations/forgot_password',
    reset: 'registrations/reset_password',
    get_post: 'projects',
    getContractData: 'contract',
    client: 'clients',
    get_promotion: 'promotion/fetch_promotion_details',
    manager: 'admins',
    inviteManager: 'admins/invite',
    update_user_details: 'users/',
    cred: 'users/change_password',
    features: 'features/bundle_details',
    todos: 'todos/',
    BRACKETS: '[]',
    add_new_feature: 'features',
    timezone: 'timezones',
    getAssigneeList: 'projects/[project_id]/todos/new',
    getMeetings: 'projects/[project_id]/meetings',
    createBuild: 'projects/[project_id]/builds',
    createTodo: 'projects/[project_id]/todos',
    updateTodo: 'projects/[project_id]/todos/',
    updateMeeting: 'projects/[project_id]/meetings/',
    updateAllRecurringMeeting:
      'projects/[project_id]/meetings/[meeting_id]/reccurrences_update',
    getTodos: 'projects/[project_id]/todos?',
    getTodo: 'projects/[project_id]/todos/[todo_id]',
    getComments: 'todos/[todo_id]/comments',
    getTimeline: 'todos/[task_id]/timeline',
    projectAssign: 'projects/assign',
    getEpicActivity: 'projects/[project_id]/trackers/epic_feed',
    update_projects: 'projects/[project_id]',
    fetch_partners_details: 'partners/show',
    getideaboards: 'projects/[project_id]/ideaboards?filter=',
    getVideoTags:
      'projects/[project_id]/meetings/[meeting_id]/feature_taggings',
    getAllPods: 'pods',
    myTask: 'my_tasks',

    frontapp_embedding: 'fronts/front_embedding',
    frontapp_polling: 'projects/[project_id]/front_last_inbound',
    resync:
      'projects/[project_id]/allocations/[allocation_id]/sync_allocation_data_to_tracker',
    getSidebarProjects: 'projects/sidebar_projects',
    getFeaturesByIds: 'pm_dashboard/features/feature_data',
    add_delay: 'projects/[project_id]/releases/[release_id]/internal_delays',
    deallocatedFeatures: 'deallocated_features',
    getTrackerStoryCompletedFeatures:
      'external/projects/[project_id]/features/story_completions',
    getProjectFeatureTimeline:
      'projects/[project_id]/builder_card_features/[build_card_feature_id]/notes/notes_timeline',
    getSpecCallTranscriptsStatus:
      'spec_calls/[spec_id]/transcripts/transcript_status',
    getSpecCallTranscriptsData:
      'spec_calls/[spec_id]/transcripts/transcript_data',
    generateTranscriptForSpec:
      'spec_calls/[spec_id]/transcripts/retry_call_transcription',
    resendDockerIDEs: 'external/projects/[project_id]/resend_docker_ides',
    getTrackerStoryAccepted: 'external/projects/[project_id]/stories_accepted',
    getTranscriptStatusForMeeting:
      'meetings/[meeting_id]/transcripts/transcript_status',
    getTranscriptDataForMeeting:
      'meetings/[meeting_id]/transcripts/transcript_data',
    generateTranscriptDataForMeeting:
      'meetings/[meeting_id]/transcripts/retry_call_transcription',
    getSquadMeeting: 'pods/[project_id]/meetings',
    postSquadMeeting: 'pods/[project_id]/meetings',
    getProjectBriefTimeline: 'projects/[project_id]/project_brief_timelines',
    updateSquadMeeting: 'pods/[squad_id]/meetings/',
    updateSquadAllRecurringMeeting:
      'pods/[squad_id]/meetings/[meeting_id]/reccurrences_update',
    getSimilarBuildCard: 'spec_calls/[specCallId]/similar_projects',
    getSimilarBuildCardForProject: 'projects/[projectId]/similar_projects',
    getPinnedBuildCard: 'projects/[projectId]/pinned_projects',
    deletePinnedBuildCard: 'projects/[projectId]/pinned_projects/',
    getProjectTransactionLogFeatures: 'projects/[project_id]/feature_logs',
    getProjectTransactionLogFeaturesLogs:
      'projects/[project_id]/feature_logs/data_logs',
    deleteTimeSlot:
      'users/[user_id]/available_dates/[available_dates_id]/time_slots/[time_slots_id]',
    deleteDateSlot: 'users/[user_id]/available_dates/[available_dates_id]',
    updateDateSlot: 'users/[user_id]/update_available_dates',
    brex_email:
      'projects/[project_id]/accounts/[account_id]/send_mail_via_marketplace',
    deleteAssociates: 'projects/[project_id]/client_projects/bulk_destroy',
    getProjectDetail: 'projects/[project_id]/project_details',
    updateMeetingSpeakerName:
      'meetings/[meeting_id]/transcripts/update_speaker_mapping',
    updateSpecSpeakerName:
      'spec_calls/[spec_id]/transcripts/update_speaker_mapping',
    generateTransactionLogReport:
      'projects/[project_id]/feature_logs/create_transaction_log_report',
    getZoomToken: 'meetings/zoom_meeting_token',
    getRefreshZoomToken: 'meetings/zoom_meeting_token',
    getDetailedBuildCard: 'projects/[project_id]/detailed_build_card_pdfs',
    createDetailedBuildCard: 'projects/[project_id]/create_project_pdfs',
    getProjectAllocatedExperts:
      'allocations?project_id=[project_id]&stab=accepted',
    getCatalogueNominatedBuildcard: 'projects/nominated_bcs_for_upgrade',
    createblockUpgradeRequest: 'projects/initiate_block_upgrade_to_tracker',
    create_cr_Request: 'projects/[project_id]/feature_change_requests',
    getJourney: 'projects/journey_build_card_features',
    getPhaseFeatureDetails: 'projects/[project_id]/phase_details',
    getJourneyFeatures:
      'projects/journey_build_card_features/[project_id]/project',
    getJourneyFeaturePercentage:
      'projects/[project_id]/journey_build_card_features/dls_feature',
    getDetailedKickOff: 'projects/[project_id]/build_card_kick_off_pdfs',
    createDetailedKickOff: 'projects/[project_id]/create_kickoff_project_pdfs',
    stopBcareAutoRenewal:
      'projects/[project_id]/builder_care_stop_requests/generate_stop_request',
    getAssignedSubStatuses: 'sub_statuses',
    getSpecCallAttachments: 'spec_calls/[spec_id]/spec_call_attachments',
    updatePaymentsFrequencyTimeline:
      'projects/[project_id]/log_frequency_change_to_timeline',
    getSubstatus: 'projects/[project_id]/sub_statuses/list',
    deleteBuildCardTag: 'projects/[projectId]',
    generatePdf: 'projects/generate_pdf',
    getTodosByIds: 'projects/[projectId]/todos/fetch_todos',
    getUnpaidBuildCardList: 'clients/[client_id]/client_projects',
    saveProjectsColumnPreferences: 'admins/save_column_preferences',
    getToDoLabels: 'master_todo_labels/list',
    createTodoLabel: 'master_todo_labels',
    updateTodoLabel: 'master_todo_labels/[todoId]',
    getBCTypes: 'build_card_types/list',
    getBcTagList: 'build_card_types/[typeId]/build_card_type_tags',
    createBcTagLabel: 'build_card_types/create_build_card_type_tags',
    updateBcTagLabel: 'build_card_types/[typeId]/build_card_type_tags/[tagId]',
    update_spec_action: 'jfx_note_details/',
    feature_difference: 'feature_difference'
  };

  static CMTApiEndpoints = {
    getFeatureSkillsBlocks:
      'features/available_blocks?builder_studio_feature_id=[builder_studio_feature_id]'
  };

  static studioApiEndpoints = {
    getPaymentLink: 'pm_dashboard/build_card/build_card_id/get_payment_link',
    getNatashaChatLogs:
      'pm_dashboard/build_card/[build_card_id]/customer_natasha_interaction',
    getPaymentStatus:
      'pm_dashboard/build_card/[build_card_id]/installment_count',
    updatePaymentStatus:
      'pm_dashboard/build_card/[build_card_id]/update_payment_status',
    switchToMonthlyCycle:
      'pm_dashboard/build_card/[build_card_id]/change_billing_cycle',
    getInstallments: 'pm_dashboard/build_cards/[build_card_id]/installments',
    getBuildcardInfo: 'pm_dashboard/build_card/[build_card_id]/build_card_info',
    updateCollectionProcess:
      'pm_dashboard/build_cards/[build_card_id]/collection_process',
    refundInstallments: 'pm_dashboard/installments/bulk_refund',
    addLateFee:
      'pm_dashboard/api/v1/installments/[installment_id]/payment_delay_charge',
    waiveOffLateFee: 'pm_dashboard/installments/[installment_id]/bulk_waive_off'
  };

  static COCKPIT_WINDOW_MESSAGE = {
    call_schedule: 'Call Scheduled!',
    call_was_schedule: 'This call was scheduled at',
    meeting_start: 'START MEETING'
  };

  static userRole = {
    pm_lead: 'pm_lead',
    pm: 'pm',
    gpm: 'gpm'
  };

  static PARAM_CONSTANTS = {
    DECIMAL: '.'
  };
  static VIDEO_MIN_SNIP_TIME = {
    TIME: 2
  };
  static ROR_REACT_ICONS = {
    RUBY: 'https://pmdashboardassets.blob.core.windows.net/assets-pm-production/Ruby.svg',
    REACT:
      'https://pmdashboardassets.blob.core.windows.net/assets-pm-production/React.svg'
  };

  static frontappEndPoints = {
    getConversationById: 'conversations/[conversation_id]',
    getConversationByTag: 'conversations/search/[query]',
    getMessagesByConversationId: 'conversations/[conversation_id]/messages',
    getCommentsByConversationId: 'conversations/[conversation_id]/comments',
    getEventsByConversationId: 'conversations/[conversation_id]/events',
    getMessageById: 'messages/[message_id]',

    createComment: 'conversations/[conversation_id]/comments',
    createConversation: 'channels/[channel_id]/messages',
    createConversationReply: 'conversations/[conversation_id]/messages',
    createDraftReply: 'conversations/[conversation_id]/drafts',
    createDraft: 'channels/[channel_id]/drafts',

    editDraft: 'drafts/[message_id]/',
    deleteDraft: 'drafts/[draft_id]',
    getInboxTeammates: 'inboxes/[inbox_id]/teammates',
    updateConversationStatus: 'conversations/[conversation_id]',
    getTeammateSignature: 'teammates/[teammate_id]/signatures',
    getInbox: 'inboxes/[inbox_id]',
    getChannel: 'channels/[channel_id]'
  };

  static hhmmOfDay = [
    '12:00',
    '12:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30'
  ];

  static hhOfDay = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17'
  ];

  static categorySubCategoryMap = [
    {
      category: 'Sales <>Customer communication gap',
      subCategory: []
    },
    {
      category: 'Sales<>Delivery communication gap ',
      subCategory: []
    },
    {
      category: 'Capacity Issues',
      subCategory: [
        'Delay onboarding designer',
        'Delay onboarding developer',
        'Delay onboarding tester',
        'Non responsiveness',
        'Unplanned leaves',
        'Inexperienced expert'
      ]
    },
    {
      category: 'Product Issues',
      subCategory: [
        'IDE issue',
        '360 issue',
        'UIW issue',
        'Tracker issue',
        'Whiteboard issue',
        'Payment failure issue',
        'Cloud Set-up Delays'
      ]
    },
    {
      category: 'Sensei Bandwidth',
      subCategory: []
    },
    {
      category: 'Ninja Bandwidth',
      subCategory: []
    },
    {
      category: 'SRE being Non-Responsive',
      subCategory: []
    },
    {
      category: 'Care/Cloud Contract Delays',
      subCategory: []
    },
    {
      category: 'Productologist Bandwidth',
      subCategory: []
    },
    {
      category: 'Understanding gaps in Scope',
      subCategory: []
    },
    {
      category: 'Design screens are missed',
      subCategory: []
    },
    {
      category: 'Feature mismatched',
      subCategory: []
    },
    {
      category: 'Features are missing',
      subCategory: []
    },
    {
      category: 'Finance Delays ',
      subCategory: [
        'Payment is not updated',
        'Buildcard is not correctly marked as offline paid',
        'VAT issues',
        'Invoice Receipt Issue',
        'Tax related Issue'
      ]
    },
    {
      category: 'Technical issues',
      subCategory: [
        'Automation Tooling Validation fixes',
        'SonarQube issues',
        'Unit Test cases',
        'Dependency on third party API',
        'Mobile Build failure issues',
        'Code Quality issues',
        'Pipeline Failure',
        'Deployment issues',
        'Infra limitations'
      ]
    },
    {
      category: 'Delay in pausing due to BB development',
      subCategory: []
    }
  ];
  static skillsList = [
    'Blockchain',
    '.NET',
    'Node',
    'Java',
    'Rails/ROR',
    'GoLang',
    'C#',
    'C',
    'PHP/Wordpress',
    'Machine learning',
    'Node JS',
    'Python',
    'Rails/ROR',
    'React Native',
    'Android',
    'iOS/Swift',
    'Angular',
    'React',
    'HTML/CSS',
    'React JS'
  ];

  static toastMessages = {
    error: 'Something went wrong. Please try again !',
    error2: 'Something went wrong.',
    maxFilesUploadError: 'Maximum 5 files can be uploaded.'
  };

  static designationMap = {
    productologistDesignations: [
      'CPE',
      'SCPE',
      'Graduate Project Manager',
      'Associate Productologist',
      'Tribe Lead - Productologist'
    ],
    ninjaDesignations: [
      'CPM',
      'SCPM',
      'Associate Ninja',
      'Tribe Lead - Ninja',
      'Head of Ninja'
    ],
    senseiDesignations: [
      'CTE',
      'SCTE',
      'Tribe Lead - Sensei',
      'Head of Sensei'
    ],
    successGeniusDesignations: ['Customer Success Manager'],
    financeDesignations: ['Sr. Finance', 'Finance Executive']
  };
  static UAEPartner = 'uae_partner';

  static meetingFromTimeArr() {
    const timeArray = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour; // Convert hour to 12-hour format
        const period = hour >= 12 ? 'pm' : 'am'; // Determine if it's am or pm
        const formattedMinute = (minute < 10 ? '0' : '') + minute; // Add leading zero if minute is less than 10
        // Push the formatted time to the array
        timeArray.push(`${formattedHour}:${formattedMinute}${period}`);
      }
    }
    return timeArray;
  }

  static meetingRepeatOptions() {
    return [
      { name: 'Event does not repeat', key: 'single' },
      { name: 'Every weekday (Mon to Fri)', key: 'weekday' },
      { name: 'Daily (Mon to Sun)', key: 'daily' },
      { name: 'Weekly', key: 'weekly' },
      { name: 'Monthly', key: 'monthly' }
    ];
  }

  static internalMembersSuffix = [
    '@builder.ai',
    '@engineer.ai',
    '@yopmail.com',
    '@x.builder.ai'
  ];

  static priorityList = [
    // { key: '', value: 'Select Priority', icon: '' },
    { key: 'P1', icon: 'P1.svg', value: 'P1 - Blocker' },
    { key: 'P2', icon: 'P2.svg', value: 'P2 - Critical' },
    { key: 'P3', icon: 'P3.svg', value: 'P3 - Major' },
    { key: 'P4', icon: 'P4.svg', value: 'P4 - Minor' },
    { key: 'P5', icon: 'P5.svg', value: 'P5 - Trivial' }
  ];
}
