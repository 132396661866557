// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  API_BASE_URL: 'https://api-staging-pmdashboard.engineer.ai/api/v1/pm/',
  QLIK_BASE_URL: 'https://api.data.builder.ai/',
  CUSTOMISATION_BUDGET_TOKEN: '400efce8-9c92-4891-942f-ff98cf030cc0',
  API_BASE_URL_FOR_ACCOUNTS:
    'https://engineer-ai-backend-beta.studio.stage.eastus.az.svc.builder.ai/',
  STUDIO_4_BASE_URL:
    'https://engineer-ai-backend-beta.studio.stage.eastus.az.svc.builder.ai/',
  WEB_SOCKET_URL: 'wss://api-staging-pmdashboard.engineer.ai/cable',
  STUDIO_WEB_SOCKET_URL: 'wss://api-staging-builder.engineer.ai/cable',
  apptoken: 'iCxBWGyfgL89NPCwkc-i',
  STUDIO_API_BASE_URL:
    'https://engineer-ai-backend-beta.studio.stage.eastus.az.svc.builder.ai/',
  BASE_URL: 'https://staging-pmdashboard.engineer.ai/#/',
  API_BASE_URL_PAYMENT: 'http://staging-payments.engineer.ai/',
  PAYMENT_TOKEN:
    'GT2CLMJrWD5Iy/K1pi8n6XZn8m1HUXy5fBrQbSIomzv81tYODhQQQDN117aOXJBtMVv9bxN0bLRSvJCoX8/+Ww==',
  ZOOMCLIENTID: 'g01JCPqZSIKB2Ne7ru0Sww',
  GOOGLE_CLIENT_ID:
    '930963822265-4r70gvcgcbm9fp3oid19dd8a9t0ga8e6.apps.googleusercontent.com',
  GOOGLE_CLIENT_SECRET_KEY: '8Fzwv0B1RJZxJ7BKKSWwosEk',
  BMEET_TOKEN: 'qv71qVlQPq_vfRoThBk2pw',
  BMEET_URL: 'https://meet-backend-staging.builder.ai/api/v1/',
  GITLABURL: 'https://staging.gitlab.builder.ai/clientprojects',
  FRONTAPP_AUTH_TOKEN:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOlsicHJpdmF0ZToqIiwicHJvdmlzaW9uaW5nIiwic2NpbSIsInRpbTo1NDYyNyIsInRpbToyNjk3NjMiLCJ0aW06MjY5ODI3IiwidGltOjI2ODg2NyIsInRpbToyNjg4MDMiLCJ0aW06MjcwNzg3IiwidGltOjI2OTYzNSIsInRpbToyNjk1NzEiLCJ0aW06MjcwMzM5IiwidGltOjI2OTY5OSIsInRpbToyNjY2OTEiLCJ0aW06NTcwMTkiLCJ0aW06MjY5NTA3Iiwic2hhcmVkOioiXSwiaWF0IjoxNTc1ODkwMjUxLCJpc3MiOiJmcm9udCIsInN1YiI6ImVuZ2luZWVyX2FpIiwianRpIjoiNjE0MWRlNzg1ODY2ZTc4NCJ9.SWn0Dbi-ZGiJcWLYPGMHSocYuLXXu4QgdmHRxhokGck',
  FRONTAPPURL: 'https://api2.frontapp.com',
  SCHEDULER_URL:
    'https://staging-scheduler.builder.ai/?token=tpdpdxTvmdfGFyDpWTaK',
  SFID_URL:
    'https://engineerai--newprocess.lightning.force.com/lightning/r/Build_card_object_spec__c/SF_BuildCard_ID/view',
  BUILDER_STUDIO_URL: 'https://studio.builder.ai/',
  CLOUD_AUTH_TOKEN: 'Token  86c31f482c948c3cefa6a86bc34717a7c5c9dc47',
  CLOUD_API_URL: 'https://staging-api.cat.builder.ai/v1/',
  BUILDER_TRACKER_URL: 'https://staging.api.tracker.engineer.ai/api/v1/',
  BUILDER_TRACKER_SECRET_KEY: 'PXIdJKzxCXowjXPIRgLm',
  ENABLE_SENTRY: true,
  ENABLE_SERVICE_WORKER: true,
  MICROFRONTENDS: {
    manager: {
      remoteUrl: 'https://builder360-staging-managers.azureedge.net/'
    }
  },
  name: 'staging',
  PARTNER_CODE: 'e-ai',
  SECRET_KEY: 'tpdpdxTvmdfGFyDpWTaK',
  API_AVAIL_BASE_URL: 'https://api-staging-schedular.builder.ai/api/v1/',
  CMT_API_URL: 'https://cmt-staging.builder.ai/api/v2/',
  CMT_Username: 'b360_staging',
  CMT_Password: '18bc26c1-dda2-4cb1-9de0-ec0684361322',
  WHITEBOARD_URL: 'https://staging-whiteboard.builder.ai',
  B_HOME: 'https://b-home.bhome.stage.eastus.az.svc.builder.ai/api/v2/bhome/',
  B_HOME_SECRET_KEY: 'WPJuUpVDtnwJbmYQjReT',
  B_HOME_WEBSOCKET_URL:
    'wss://b-home.bhome.stage.eastus.az.svc.builder.ai/cable',
  BCHAT: 'https://staging-chat-pmdashboard.engineer.ai/',
  BCHAT_WEBSOCKET_URL: 'wss://staging-chat.builder.ai/cable',
  UAE_STUDIO_BASE_URL: 'https://api-staging-builder.engineer.ai/',
  B_Now_URL: 'https://staging-now.engineer.ai/',
  NOTIFICATION_TOKEN:
    '4a52e8fc513fc9424f4e0149085e203916392f8aa4c20bc406723d3c737bb340f867233e79b1622d9e3558ed9dee0ab91e588455165bacc5a7cfec0e9da132e3',
  NOTIFICATION_NEXUS_URL:
    'https://notify-nexus.b360.stage.eastus.az.svc.builder.ai/cable'
};
