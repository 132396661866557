import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'commentName'
})
export class CommentNamePipe implements PipeTransform {
  transform(username: string): string {
    if (username !== '' && username != null) {
      const nameArr = username.split(' ');
      let formattedString = '';
      if (nameArr.length > 0) {
        nameArr.forEach((name) => {
          formattedString = formattedString + name[0];
        });
      }
      return formattedString;
    }
    return null;
  }
}
