<!--Error screens 404-->
<div class="error-screen">
  <div class="error_404">
    <img src="assets/images/error_404.png" alt="404" />
    <h4>Error Code: 404</h4>
    <h1>Page not found...</h1>
    <p>We are unable to find the page you are looking for</p>
    <button type="button" (click)="homePage()">Back to Home Page</button>
  </div>
</div>
