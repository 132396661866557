<app-header *ngIf="!commonService.newHeadertrue"></app-header>
<app-revamp-header *ngIf="commonService.newHeadertrue"></app-revamp-header>
<app-main-leftpanel
  *ngIf="dataService.user"
  [ngClass]="{ 'full-leftnav': miniStudio }"
></app-main-leftpanel>

<div class="initialLoader" *ngIf="isLoader">
  <div class="homeLoader">
    <img src="assets/images/builder_black.png" alt="" />
  </div>
  <div class="loadingOverlay"></div>
</div>

<div class="initialLoader toasterwrap" *ngIf="toastMessageService.showToastMsg">
  <div class="loadingOverlay"></div>
  <div
    class="project-success-msg"
    [ngClass]="{ 'project-error-msg': toastMessageService.errorClass === true }"
  >
    <img src="assets/images/new-tick-revamp.svg" alt="new-tick" />
    <h3>
      {{ toastMessageService.toastMessage }}
    </h3>
    <a class="ms-auto" (click)="closeSaveTost()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
    </a>
  </div>
</div>

<router-outlet></router-outlet>

<app-footer *ngIf="!miniStudio"></app-footer>

<app-call-score></app-call-score>

<div *ngIf="isRefreshEnable" class="hard-refresh">
  <div class="alert alert-primary" role="alert">
    <fa-icon icon="exclamation-circle"></fa-icon>
    A new version of Builder 360 is available! Please
    <strong (click)="onHardRefresh()"> Refresh</strong>
  </div>
</div>

<ng-container *ngIf="showToasterMsg$ | async as showToasterMsg">
  <ng-container *ngIf="showToasterMsg.toastMessage">
    <app-toaster [showToasterMsg]="true"></app-toaster>
  </ng-container>
</ng-container>

<div *ngIf="instantMeetingEnable" class="instant-meeting-notification">
  <span (click)="closeInstantModelPopup()" class="close-noti"
    ><img src="../../../assets/images/close-cross.svg" alt=""
  /></span>
  <div class="noti-title">New instant meeting has started</div>
  <p>{{ instantMeeting.meeting_name }}</p>
  <div class="noti-btn-wrapper">
    <button (click)="closeInstantModelPopup()" class="noti-btn is-white">
      Ignore
    </button>
    <button (click)="navigateTo(instantMeeting.url)" class="noti-btn">
      Join
    </button>
  </div>
</div>
