<!--Error screens 500-->
<div class="error-screen">
  <div class="error_500">
    <img src="assets/images/error_500.png" alt="error" />
    <h4>Error Code: 500</h4>
    <h1>System is Under Maintenence</h1>
    <p>We are preparing to serve you better.</p>
    <button type="button" (click)="homePage()">Back to Home Page</button>
  </div>
</div>
