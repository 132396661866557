import { Time } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker-ivy';
import moment from 'moment';
import 'moment-timezone';
import { Subscription, filter, finalize, map, tap } from 'rxjs';
import { LinkGoogleCalendarComponent } from '../../../shared/shared-module/components/link-google-calendar/link-google-calendar.component';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { SharedService } from '../../../shared/shared-module/services/shared.service';
import { Constants } from '../../../shared/shared-module/utility/constants';
import { debounce } from '../../../shared/shared-module/utility/utility.functions';
import { LoaderService } from '../../services/loader.service';
import { ToastMessageService } from '../../services/toast.message.service';
import { SpecCallService } from '../../spec-calls/services/spec-call.service';
import { MeetingSharedService } from '../service/meeting-shared.service';

@Component({
  selector: 'add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.scss']
})
export class AddEditModalComponent implements OnInit, OnChanges {
  //new
  timeDropdownIsVisible = false;
  timeDurationDropdownIsVisible = false;
  timezoneDropdownIsVisible = false;
  proposedTimezoneIsVisible = false;
  repeatFreqDropdownIsVisible = false;
  isAttachmentMenuClickedItem = -1;
  teammatesDropdown = false;
  externalParticipantsDropdown = false;
  expertsDropdown = false;
  @Input() isMymeeting = false;
  @Output() closeModal = new EventEmitter();
  @Output() meetingupdate = new EventEmitter();
  selectedTeammates = [
    { email: 'deepak.sharma@builder.ai' },
    { email: 'ankur.kushwah@builder.ai' },
    { email: 'deepanshi@builder.ai' }
  ];
  teammatesDynamicSuggestions = {
    isLoading: false,
    data: [],
    error: '',
    nextPageNumber: 1,
    pageSize: 10
  };
  teammatesStaticSuggestions = {
    isLoading: false,
    data: [],
    error: '',
    nextPageNumber: null,
    pageSize: null,
    allowedEmailSuffix: Constants.internalMembersSuffix
  };
  externalSuggestions = {
    isLoading: false,
    data: [],
    error: '',
    nextPageNumber: null,
    pageSize: null,
    disAllowedEmailSuffix: Constants.internalMembersSuffix
  };
  expertSuggestions = {
    isLoading: false,
    data: [],
    error: '',
    nextPageNumber: null,
    pageSize: null
  };
  fromTimeArr = Constants.meetingFromTimeArr();
  meetingRepeatOptions = Constants.meetingRepeatOptions();
  toTimeArr = [];
  weeklyRepeat = 1;
  clientTimezone;
  bMeetDataLoaded = false;
  isBMeetPlatform = true;
  //
  title: 'Schedule' | 'Edit' = 'Schedule';
  meetingDateVal;
  // startTimeAmPm = 'AM';
  mytime: Date = new Date();
  isStartTimeElapsed = true;
  endDateFomatted: string;
  recurOccurences = [];
  timezoneConfig = {
    id: 'time_zone',
    componentName: 'AddEditMeetingModalComponent',
    saveOrCancelChanges: 'cancel', // contains on 'save' or 'cancel',
    selectedData: moment.tz.guess(),
    disabled: true, //make it true vikas
    isRequired: true,
    isFormGrpClassEnable: true,
    isDropdownOnTop: false
  };
  isEndTimeElapsed;
  showFileErr = false;
  removedFiles = [];
  bmeetSigned = false;
  @ViewChild('zoomTemplate') zoomTemplate: TemplateRef<any>; // eslint-disable-line

  constructor(
    private _fb: FormBuilder,
    public meetingService: MeetingSharedService,
    public toastMessageService: ToastMessageService,
    public cdr: ChangeDetectorRef,
    public sharedService: SharedService,
    public commonService: CommonService,
    public apirequestService: ApiRequests,
    public dataService: DataService,
    public specCallService: SpecCallService,
    public loaderService: LoaderService
  ) {
    this.currentUserName = this.dataService.getUserData();

    this.myDatePickerOptions = {
      dateFormat: 'mmm dd, yyyy',
      firstDayOfWeek: 'su',
      closeSelectorOnDateSelect: true,
      markCurrentDay: true,
      dateRange: false,
      disableUntil: {
        year: 0,
        month: 0,
        day: 0
      }
    };

    this.recurringEndDatePickoptions = {
      dateFormat: 'mmm dd, yyyy',
      firstDayOfWeek: 'su',
      closeSelectorOnDateSelect: true,
      markCurrentDay: false,
      dateRange: false,
      disableUntil: {
        year: 0,
        month: 0,
        day: 0
      }
    };
  }

  selectedManagers = [];
  hhmmOfDay = Constants.hhmmOfDay;
  hhOfDay = Constants.hhOfDay;

  @Input() currentParicipantSpec = [];
  @Output() onSubmit = new EventEmitter(); // eslint-disable-line
  @Input() editMeeting;
  @Output() meetingPopupClose = new EventEmitter();
  @Input() tabStatus = null;
  @Input() editRecurringType = '';
  @Input() overviewMeeting;
  @Input() saveOrCancel: 'save' | 'cancel';
  @Input() meetingKind = null;
  @ViewChild(LinkGoogleCalendarComponent, { static: false })
  linkGoogleCalendarComp: LinkGoogleCalendarComponent;
  public model: IMyDateModel = null;
  meetingForm: FormGroup;
  formattedDate;
  filesToBeUpload = [];
  timeDiff;
  inviteeList = [];
  meetingdate;
  timeError = '';
  startTimeValid = true;
  startTimemodel: Date;
  meetingTime: Time;
  myDatePickerOptions: IAngularMyDpOptions;
  recurringEndDatePickoptions: IAngularMyDpOptions;
  meetingRecurrenceEndDateModel;
  public currentUserName;
  public recurringError = '';
  public daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['subscript', 'superscript', 'fontName'],
      [
        'unlink',
        'textColor',
        'insertImage',
        'backgroundColor',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };
  agendaConfig: AngularEditorConfig;
  momConfig: AngularEditorConfig;
  emailChipConfig = {
    id: 'email',
    label: 'Add your teammates',
    component: 'ProjectMeetingFormComponent',
    disable: false,
    isRequiredField: true,
    currentParicipant: [],
    saveOrCancel: '',
    clientEmail: '',
    disableClientsAPI: false,
    disableExpertsAPI: false
  };
  startTimeTouched = false;
  selectedPodData;
  projectDetail;
  isOpenMeetingOwner = false;

  isShowPaginationLoader = false;
  params = {
    search: '',
    page: 1,
    perPage: 18
  };
  projectList = [];
  searchText = '';
  selectedProject;
  toggleProjectDropdown = false;
  projectSubscription: Subscription;

  ngOnInit() {
    this.agendaConfig = { ...this.editorConfig, showToolbar: false };
    this.momConfig = { ...this.editorConfig, showToolbar: false };
    this.resetAll();
    this.getBMeetDetail();
    // this.startTimeAmPm = 'AM';
    this.initializeForm();
    if (this.editMeeting) {
      this.title = 'Edit';
      this.setValues();
    } else {
      this.title = 'Schedule';
    }

    this.previousDatesDisable();

    this.commonService.notifyProjectDetailSubject
      .pipe(filter((x) => !!x))
      .subscribe((data) => {
        if (data) {
          this.projectDetail = data;
          this.clientTimezone = data.client_timezone;
          if (this.isMymeeting) {
            this.selectedProject = null;
          } else {
            this.selectedProject = data;
            if (data.status !== 'pending' || data.first_payment_date) {
              this.timezoneConfig.disabled = true;
              this.getProjectTeammates();
              this.getExpertsParticipantList();
              this.getExternalParticipantList();
            }
            if (this.editMeeting?.studio_call_type === 'kickoff') {
              const userDesignationMap =
                this.dataService.getUserData()?.designation_map;
              // formatted userlist for passing as options to meeting custom dropdown
              this.selectedPodData = {
                users: data?.users?.map((x) => ({
                  ...x,
                  key: x.id,
                  value: `${x.first_name} ${x.last_name} (${userDesignationMap && userDesignationMap[x.designation] ? userDesignationMap[x.designation] : x.designation})`,
                  disabled: !x.google_cal_access_token,
                  title: !x.google_cal_access_token
                    ? 'Google Calendar is not linked'
                    : ''
                }))
              };
            }
          }
        }
      });
    this.editMeeting
      ? this.fillToTimeDropdown(false)
      : this.fillToTimeDropdown();
  }

  public isMeetingTimeElapsed(meetingTimeZone, meetingDate, meetingStartTime) {
    if (meetingTimeZone && meetingDate && meetingStartTime) {
      const currentDateTime = moment().tz(meetingTimeZone);

      const formatedMeetingDate = moment(meetingDate).format('YYYY/MM/DD');
      const utcStartDateTime = moment
        .tz(
          moment(
            formatedMeetingDate + ' ' + meetingStartTime,
            'YYYY/MMM/DD hh:mm A'
          ).format('YYYY-MM-DD HH:mm:ss'),
          moment.tz.guess()
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      const startDateTimeObj = moment.utc(utcStartDateTime).tz(meetingTimeZone);

      return !!(startDateTimeObj.diff(currentDateTime) < 0);
    } else {
      return true;
    }
  }

  previousDatesDisable() {
    if (this.meetingKind == 'offline') {
      const currDate = moment()
        .add(1, 'days')
        .tz(this.timezone ? this.timezone : moment.tz.guess());
      this.myDatePickerOptions = {
        ...this.myDatePickerOptions,
        disableSince: {
          year: parseInt(currDate.format('YYYY')),
          month: parseInt(currDate.format('MM')),
          day: parseInt(currDate.format('DD'))
        }
      };
      if (this.myDatePickerOptions.disableUntil) {
        delete this.myDatePickerOptions.disableUntil;
      }
    } else {
      const yesterDayInTimeZone = moment()
        .subtract(1, 'day')
        .tz(this.timezone ? this.timezone : moment.tz.guess());
      this.myDatePickerOptions = {
        ...this.myDatePickerOptions,
        disableUntil: {
          year: parseInt(yesterDayInTimeZone.format('YYYY')),
          month: parseInt(yesterDayInTimeZone.format('MM')),
          day: parseInt(yesterDayInTimeZone.format('DD'))
        }
      };
      if (this.myDatePickerOptions.disableSince) {
        delete this.myDatePickerOptions.disableSince;
      }
    }
  }

  previousRecurrenceEndDatesDisable() {
    if (this.meetingDate.value) {
      const startDate = moment(
        this.meetingDate.value.singleDate.jsDate
      ).subtract(1, 'day');
      const endDate = startDate.clone().add(6, 'months').add(1, 'day');
      this.recurringEndDatePickoptions = {
        ...this.recurringEndDatePickoptions,
        disableUntil: {
          year: parseInt(startDate.format('YYYY'), 10),
          month: parseInt(startDate.format('MM'), 10),
          day: parseInt(startDate.format('DD'), 10)
        },
        disableSince: {
          year: parseInt(endDate.format('YYYY'), 10),
          month: parseInt(endDate.format('MM'), 10),
          day: parseInt(endDate.format('DD'), 10)
        }
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.timezoneConfig.saveOrCancelChanges = this.saveOrCancel;
    this.timezoneConfig.selectedData = moment.tz.guess();
    console.log('timzoneConfig =', this.timezoneConfig.selectedData);
    this.timezoneConfig = Object.assign({}, this.timezoneConfig);
    this.filesToBeUpload = [];
    this.showFileErr = false;
    this.removedFiles = [];
    this.selectedManagers = [];
    this.emailChipConfig.saveOrCancel = this.saveOrCancel;
    this.emailChipConfig = Object.assign({}, this.emailChipConfig);
    if (this.editMeeting) {
      this.emailChipConfig.isRequiredField = !!(
        this.editMeeting.meeting_provider !== 'zoom' &&
        this.editMeeting.studio_call_type !== 'kickoff'
      );
      this.emailChipConfig.disable =
        this.editMeeting.meeting_provider === 'zoom' &&
        this.tabStatus !== 'previous'
          ? false
          : this.isEditFormDisabled;
    }

    if (
      changes.meetingKind?.previousValue != changes.meetingKind?.currentValue
    ) {
      this.previousDatesDisable();
    }
  }

  resetAll() {
    // this.startTimeAmPm = 'AM';
    this.initializeForm();
  }

  formSubmit() {
    let durationMinutes = 0;
    const value = {
      ...this.meetingForm.getRawValue()
    };
    if (
      this.meetingStartTime.value &&
      this.meetingDate.value &&
      this.meetingDurationHours.value !== '' &&
      this.meetingDurationMinutes.value !== ''
    ) {
      const startTime = this.meetingStartTime.value;

      const date = moment(this.meetingDate.value.singleDate.jsDate).format(
        'YYYY-MM-DD'
      );

      const startDate = moment(
        date + ' ' + startTime,
        'YYYY-MM-DD hh:mm A'
      ).format('YYYY-MM-DD HH:mm:ss');
      const startMomUtc = moment
        .tz(
          startDate,
          this.timezoneConfig.selectedData
            ? this.timezoneConfig.selectedData
            : moment.tz.guess()
        )
        .utc();
      const startMomUtcTime = startMomUtc.format('HH:mm:ss');

      const MomUtcDate = startMomUtc.format('YYYY-MM-DD');

      value.meetingDate = MomUtcDate;
      value.meetingStartTime = startMomUtcTime;

      durationMinutes = this.getDuration();

      value.meetingEndTime = startMomUtc
        .add(durationMinutes, 'minutes')
        .format('HH:mm:ss');
    }
    let meetingId;

    value.meeting_kind = this.meetingKind == 'offline' ? 'offline' : 'normal';
    value.duration = durationMinutes;
    value.timezone = this.timezoneConfig.selectedData
      ? this.timezoneConfig.selectedData
      : moment.tz.guess();
    value.meeting_provider =
      this.editMeeting && this.editMeeting.meeting_provider === 'zoom'
        ? 'zoom'
        : 'meet';

    value.isDisabledForm = this.isEditFormDisabled;
    if (this.editMeeting) {
      if (this.filesToBeUpload.length > 0) {
        value.meetingFile = this.filesToBeUpload;
      }
      if (this.removedFiles.length > 0) {
        this.meetingForm.value.removedFile = true;
        value.meetingFile =
          value.meetingFile === undefined
            ? this.removedFiles
            : [...this.filesToBeUpload, ...this.removedFiles];
      }
      meetingId = this.editMeeting.id;
    } else {
      value.meetingFile = this.filesToBeUpload;
    }

    // recurringMeeting Manipulation
    if (
      value['meetingRecurrenceType'] !== 'single' &&
      value.meetingRecurrenceEndDate
    ) {
      const endDate = moment(
        value.meetingRecurrenceEndDate.singleDate.jsDate
      ).format('YYYY-MM-DD');

      const endDateString = moment(
        endDate + ' ' + this.meetingStartTime.value,
        'YYYY-MM-DD hh:mm A'
      ).format('YYYY-MM-DD HH:mm:ss');
      const endDateUTC = moment
        .tz(
          endDateString,
          this.timezoneConfig.selectedData
            ? this.timezoneConfig.selectedData
            : moment.tz.guess()
        )
        .utc();

      value.occurrence_end_date = endDateUTC.format('YYYY-MM-DD');
      value.meeting_kind = 'recurring';

      switch (value['meetingRecurrenceType']) {
        case 'monthly':
          value.occurrence_meta = {
            monthly: [value['meetingRecurrenceMonthDay']]
          };
          break;
        case 'weekly':
          value.occurrence_meta = {
            weekly: [
              ...value['meetingRecurrenceWeekDays'].map((x) =>
                this.daysOfWeek.indexOf(x)
              )
            ],
            after: this.meetingForm.get('meetingRecurrenceRepeatWeek').value
          };
          break;
        case 'weekday':
          value.occurrence_meta = { weekday: null };
          break;
        case 'daily':
          value.occurrence_meta = { daily: null };
          break;
      }
    }

    if (this.meetingKind == 'offline') {
      value.meeting_provider = 'offline';
      value.visibility_type = 'external';
    } else if (value.external.length > 0) {
      value.visibility_type = 'external';
    }

    // kickoff meeting change owner flow  start
    if (
      this.editMeeting &&
      this.editMeeting.studio_call_type === 'kickoff' &&
      this.currentUserName &&
      value.meeting_owner &&
      value.meeting_owner != this.currentUserName.id &&
      this.selectedPodData
    ) {
      const meetingOwner = this.selectedPodData.users?.find(
        (x) => x.id == value.meeting_owner
      );
      if (meetingOwner) {
        value.meetingInvitee =
          this.selectedManagers.findIndex(
            (z) => z.email == meetingOwner.email
          ) == -1
            ? [
                ...this.getParticipantList(),
                {
                  name: meetingOwner.first_name + ' ' + meetingOwner.last_name,
                  email: meetingOwner?.email,
                  user_type: 'teammate'
                }
              ]
            : this.getParticipantList();
        value.meetingName = `Kick-off meeting ${meetingOwner.first_name} ${meetingOwner.last_name} with ${this.projectDetail?.client?.name}`;
      }
    } else {
      value.meetingInvitee = this.getParticipantList();
      value.meeting_owner = null;
    }
    // kickoff meeting change owner flow  end
    value.meetingParticipants = this.getParticipantList();
    if (meetingId) {
      this.formUpdate({ value: value, id: meetingId });
    } else {
      this.formSave({ value: value, id: meetingId });
    }
  }

  focus(controlName) {
    if (controlName == 'agenda') {
      this.agendaConfig = { ...this.agendaConfig, showToolbar: true };
    } else if (controlName == 'mom') {
      this.momConfig = { ...this.momConfig, showToolbar: true };
    }
  }

  blurEditor(controlName) {
    if (controlName == 'agenda') {
      this.agendaConfig = { ...this.agendaConfig, showToolbar: false };
    } else if (controlName == 'mom') {
      this.momConfig = { ...this.momConfig, showToolbar: false };
    }
  }

  initializeForm() {
    this.startTimeValid = true;
    this.startTimemodel = void 0;
    this.meetingRecurrenceEndDateModel = void 0;
    this.meetingTime = void 0;

    this.meetingForm = this._fb.group({
      meetingName: ['', [Validators.required]],
      meetingDate: ['', [Validators.required]],
      meetingStartTime: [this.nextRoundTime(), [Validators.required]],
      meetingEndTime: [
        moment(this.nextRoundTime(), 'h:mma').add(30, 'minutes').format('h:mma')
      ],
      meetingUrl: ['', [Validators.pattern('https?://.+')]],
      meetingAgenda: ['', []],
      meetingMinutes: [''],
      meetingDescription: ['', []],
      meetingInvitee: [''],
      meetingDurationHours: ['00', [Validators.required]],
      meetingDurationMinutes: ['30', [Validators.required]],
      zoom_pid: [''],
      meeting_provider: ['meet'],
      offline_recording_url: [
        '',
        this.meetingKind == 'offline'
          ? [Validators.required, Validators.pattern('https?://.+')]
          : []
      ],
      visibility_type: [
        this.meetingKind == 'offline' ? 'external' : 'internal'
      ],
      isRecurringMeeting: [false],
      meetingRecurrenceType: [this.meetingRepeatOptions[0].key],
      meetingRecurrenceRepeatWeek: [1],
      meetingRecurrenceWeekDays: [[]],
      meetingRecurrenceMonthDay: [''],
      meetingRecurrenceEndDate: [{ value: '' }],
      teammates: [[]],
      external: [[]],
      experts: [[]],
      meeting_owner: [this.currentUserName?.id]
    });
  }

  editorLink(event) {
    this.meetingService.editorLink(event);
  }

  get meetingName() {
    if (
      this.isEditFormDisabled ||
      (this.editMeeting &&
        this.editMeeting.meeting_kind == 'recurring' &&
        this.editRecurringType == 'single') ||
      this.editMeeting?.studio_call_type === 'kickoff'
    ) {
      this.meetingForm.get('meetingName').disable();
    } else {
      this.meetingForm.get('meetingName').enable();
    }

    return this.meetingForm.get('meetingName');
  }

  get meetingDurationHours() {
    if (this.isEditFormDisabled) {
      this.meetingForm.get('meetingDurationHours').disable();
    } else {
      this.meetingForm.get('meetingDurationHours').enable();
    }

    return this.meetingForm.get('meetingDurationHours');
  }

  get meetingDurationMinutes() {
    if (this.isEditFormDisabled) {
      this.meetingForm.get('meetingDurationMinutes').disable();
    } else {
      this.meetingForm.get('meetingDurationMinutes').enable();
    }

    return this.meetingForm.get('meetingDurationMinutes');
  }

  get timezone() {
    if (this.isEditFormDisabled) {
      this.timezoneConfig.disabled = false;
      this.timezoneConfig = Object.assign({}, this.timezoneConfig);
    } else {
      this.timezoneConfig.disabled = true;
      this.timezoneConfig = Object.assign({}, this.timezoneConfig);
    }
    return this.timezoneConfig.selectedData;
  }

  get meetingDate() {
    if (this.isEditFormDisabled) {
      this.meetingForm.get('meetingDate').disable();
    } else {
      this.meetingForm.get('meetingDate').enable();
    }

    return this.meetingForm.get('meetingDate');
  }

  get meetingStartTime() {
    if (this.isEditFormDisabled) {
      this.meetingForm.get('meetingStartTime').disable();
    } else {
      this.meetingForm.get('meetingStartTime').enable();
    }

    return this.meetingForm.get('meetingStartTime');
  }

  get meetingEndTime() {
    if (this.isEditFormDisabled) {
      this.meetingForm.get('meetingEndTime').disable();
    } else {
      this.meetingForm.get('meetingEndTime').enable();
    }

    return this.meetingForm.get('meetingEndTime');
  }

  get meetingUrl() {
    if (this.editMeeting) {
      this.meetingForm.get('meetingUrl').disable();
    } else {
      this.meetingForm.get('meetingUrl').enable();
    }

    return this.meetingForm.get('meetingUrl');
  }

  get meetingUrlTrim() {
    if (this.editMeeting) {
      this.meetingForm.get('meetingUrl').disable();
    } else {
      this.meetingForm.get('meetingUrl').enable();
    }

    return this.meetingForm.get('meetingUrl').value.trim();
  }

  get isRecurringMeeting() {
    if (
      this.isEditFormDisabled ||
      (this.editMeeting && this.editMeeting.meeting_kind == 'recurring')
    ) {
      this.meetingForm.get('isRecurringMeeting').disable();
    } else {
      this.meetingForm.get('isRecurringMeeting').enable();
    }

    return this.meetingForm.get('isRecurringMeeting');
  }

  get meetingRecurrenceType() {
    if (
      this.isEditFormDisabled ||
      (this.editMeeting &&
        this.editMeeting.meeting_kind == 'recurring' &&
        this.editRecurringType == 'single')
    ) {
      this.meetingForm.get('meetingRecurrenceType').disable();
    } else {
      this.meetingForm.get('meetingRecurrenceType').enable();
    }

    return this.meetingForm.get('meetingRecurrenceType');
  }

  get meetingRecurrenceWeekDays() {
    if (
      this.isEditFormDisabled ||
      (this.editMeeting &&
        this.editMeeting.meeting_kind == 'recurring' &&
        this.editRecurringType == 'single')
    ) {
      this.meetingForm.get('meetingRecurrenceWeekDays').disable();
    } else {
      this.meetingForm.get('meetingRecurrenceWeekDays').enable();
    }

    return this.meetingForm.get('meetingRecurrenceWeekDays');
  }

  get meetingRecurrenceRepeatWeek() {
    if (
      this.isEditFormDisabled ||
      (this.editMeeting &&
        this.editMeeting.meeting_kind == 'recurring' &&
        this.editRecurringType == 'single')
    ) {
      this.meetingForm.get('meetingRecurrenceRepeatWeek').disable();
    } else {
      this.meetingForm.get('meetingRecurrenceRepeatWeek').enable();
    }

    return this.meetingForm.get('meetingRecurrenceRepeatWeek');
  }

  get meetingRecurrenceMonthDay() {
    if (
      this.isEditFormDisabled ||
      (this.editMeeting &&
        this.editMeeting.meeting_kind == 'recurring' &&
        this.editRecurringType == 'single')
    ) {
      this.meetingForm.get('meetingRecurrenceMonthDay').disable();
    }
    return this.meetingForm.get('meetingRecurrenceMonthDay');
  }

  get meetingRecurrenceEndDate() {
    if (
      this.isEditFormDisabled ||
      (this.editMeeting &&
        this.editMeeting.meeting_kind == 'recurring' &&
        this.editRecurringType == 'single')
    ) {
      this.meetingForm.get('meetingRecurrenceEndDate').disable();
    } else {
      this.meetingForm.get('meetingRecurrenceEndDate').enable();
    }

    return this.meetingForm.get('meetingRecurrenceEndDate');
  }

  get meetingAgenda() {
    if (this.isEditFormDisabled) {
      this.meetingForm.get('meetingAgenda');
    } else {
      this.meetingForm.get('meetingAgenda').enable();
    }

    return this.meetingForm.get('meetingAgenda');
  }

  get meetingAgendaTrim() {
    return this.meetingForm.get('meetingAgenda').value.trim();
  }

  get meetingDescription() {
    return this.meetingForm.get('meetingDescription');
  }

  get meetingInvitee() {
    return this.meetingForm.get('meetingInvitee');
  }

  get zoom_pid() {
    return this.meetingForm.get('zoom_pid');
  }

  get isEditFormDisabled() {
    if (this.isMymeeting && !this.selectedProject) {
      return this.isMymeeting && !this.selectedProject?.name ? true : false;
    } else {
      if (this.editMeeting && this.editMeeting.studio_call_type === 'kickoff') {
        return !this.isAuthorisedUser;
      } else {
        return (
          this.editMeeting &&
          (!this.isAuthorisedUser ||
            (this.editMeeting.meeting_kind != 'offline' &&
              moment.utc().isAfter(this.editMeeting?.end_date_time)))
        );
      }
    }
  }

  onChange(e) {
    if (e.target.files.length + this.filesToBeUpload.length > 5) {
      this.toastMessageService.showToastMessage(
        Constants.toastMessages.maxFilesUploadError,
        3000,
        true
      );
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      this.filesToBeUpload.push(e.target.files[i]);
    }
    if (this.filesToBeUpload.length > 5) {
      this.showFileErr = true;
    } else {
      this.showFileErr = false;
    }
  }

  removeItem(invitee) {
    const index = this.inviteeList.indexOf(invitee);
    if (index !== -1) this.inviteeList.splice(index, 1);
  }

  setValues() {
    this.editMeeting.timezone = moment.tz.guess();
    if (!this.meetingForm) {
      return;
    }

    let endDateTimeObj;
    let startTime;
    let startDateTimeObj;
    let durationHours = '';
    let durationMinutes = '';
    if (this.editMeeting.date) {
      if (
        this.editRecurringType === 'all' &&
        this.editMeeting.latest_recc_meeting
      ) {
        const latest_time = moment
          .tz(
            this.editMeeting.latest_recc_meeting.start_time,
            this.editMeeting.timezone
              ? this.editMeeting.timezone
              : moment.tz.guess()
          )
          .utc()
          .format('hh:mm A');
        this.meetingdate = moment
          .utc(
            moment(
              this.editMeeting.latest_recc_meeting.date + ' ' + latest_time,
              'YYYY-MM-DD hh:mm A'
            ).format('YYYY-MM-DD HH:mm:ss')
          )
          .tz(
            this.editMeeting.timezone
              ? this.editMeeting.timezone
              : moment.tz.guess()
          )
          .format('YYYY/MM/DD');
      } else {
        this.meetingdate = moment(this.editMeeting.date, 'YYYY-MM-DD').format(
          'YYYY/MM/DD'
        );
      }

      if (this.editMeeting.start_time && this.editMeeting.end_time) {
        const utcStartDateTime = moment
          .tz(
            moment(
              this.meetingdate + ' ' + this.editMeeting.start_time,
              'YYYY-MM-DD hh:mm A'
            ).format('YYYY-MM-DD HH:mm:ss'),
            moment.tz.guess()
          )
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        const utcEndDateTime = moment
          .tz(
            moment(
              this.meetingdate + ' ' + this.editMeeting.end_time,
              'YYYY-MM-DD hh:mm A'
            ).format('YYYY-MM-DD HH:mm:ss'),
            moment.tz.guess()
          )
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        startDateTimeObj = moment
          .utc(utcStartDateTime)
          .tz(
            this.editMeeting.timezone
              ? this.editMeeting.timezone
              : moment.tz.guess()
          );
        endDateTimeObj = moment
          .utc(utcEndDateTime)
          .tz(
            this.editMeeting.timezone
              ? this.editMeeting.timezone
              : moment.tz.guess()
          );
        startTime = startDateTimeObj.format('hh:mm'); // eslint-disable-line
      }

      this.model = {
        isRange: false,
        singleDate: {
          jsDate: moment(startDateTimeObj.format('YYYY-MM-DD')).toDate(),
          formatted: startDateTimeObj.format('MMM DD, YYYY')
        },
        dateRange: null
      };

      const currentDateTime = moment().tz(
        this.editMeeting.timezone
          ? this.editMeeting.timezone
          : moment.tz.guess()
      );
      const today = new Date();
      const perviousDay = new Date();
      if (
        this.editMeeting.start_time.includes('PM') &&
        this.editMeeting.end_time.includes('AM')
      ) {
        perviousDay.setDate(today.getDate() - 1);
      }
      const currentEndDateTime = moment(
        moment(perviousDay, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss')
      ).tz(
        this.editMeeting.timezone
          ? this.editMeeting.timezone
          : moment.tz.guess()
      );

      this.isStartTimeElapsed = !!(startDateTimeObj.diff(currentDateTime) <= 0);
      this.isEndTimeElapsed = !!(endDateTimeObj.diff(currentEndDateTime) <= 0);
    }

    if (
      (this.editMeeting.duration != null &&
        this.editMeeting.duration !== undefined) ||
      (this.editMeeting.zoom_duration != null &&
        this.editMeeting.zoom_duration !== undefined)
    ) {
      const duration =
        this.editMeeting.meeting_provider === 'zoom'
          ? this.overviewMeeting.zoom_duration
          : this.overviewMeeting.duration;
      durationMinutes = (parseInt(duration, 10) % 60)
        .toString()
        .padStart(2, '0');
      durationHours = Math.floor(parseInt(duration, 10) / 60)
        .toString()
        .padStart(2, '0');
    }
    const userFromLocalTime = moment
      .tz(this.editMeeting.start_time, 'h:mma', 'GMT')
      .clone()
      .tz(moment.tz.guess());
    this.meetingForm
      .get('meetingStartTime')
      .setValue(userFromLocalTime.format('h:mma'));

    const userToLocalTime = moment
      .tz(this.editMeeting.end_time, 'h:mma', 'GMT')
      .clone()
      .tz(moment.tz.guess());
    this.meetingForm
      .get('meetingEndTime')
      .setValue(userToLocalTime.format('h:mma'));

    this.fillToTimeDropdown(false);

    const meeting = {
      meetingName: this.editMeeting.name,
      meetingDurationHours: durationHours,
      meetingDurationMinutes: durationMinutes,
      meetingUrl: this.editMeeting?.url,
      meetingAgenda: this.editMeeting.agenda ? this.editMeeting.agenda : '',
      meetingMinutes: this.editMeeting.minutes_of_meeting
        ? this.editMeeting.minutes_of_meeting
        : '',
      meetingInvitee: '',
      meetingDescription: this.editMeeting.notes ? this.editMeeting.notes : '',
      meetingDate: this.model,
      teammates: this.editMeeting.teammates,
      external: this.editMeeting.external,
      experts: this.editMeeting.experts,
      zoom_pid: this.editMeeting.zoom_pid,
      timezone: this.editMeeting.timezone
        ? this.editMeeting.timezone
        : moment.tz.guess(),
      visibility_type: this.editMeeting.visibility_type,
      offline_recording_url:
        this.editMeeting.meeting_recordings[0]?.recording_url
    };
    if (this.editMeeting.attachments.length) {
      this.filesToBeUpload = [...this.editMeeting.attachments];
    }
    console.log('editMeeting = ', this.editMeeting);
    this.timezoneConfig.selectedData = this.editMeeting.timezone
      ? this.editMeeting.timezone
      : moment.tz.guess();
    /// recurrence meeting setvalues
    if (this.editMeeting.meeting_kind === 'recurring') {
      const time = moment
        .tz(
          this.editMeeting.start_date_time,
          this.editMeeting.timezone
            ? this.editMeeting.timezone
            : moment.tz.guess()
        )
        .utc()
        .format('hh:mm A');
      const endDateTimeObj = moment
        .utc(
          moment(
            this.editMeeting.occurrence_end_date + ' ' + time,
            'YYYY-MM-DD hh:mm A'
          ).format('YYYY-MM-DD HH:mm:ss')
        )
        .tz(
          this.editMeeting.timezone
            ? this.editMeeting.timezone
            : moment.tz.guess()
        );
      this.meetingRecurrenceEndDateModel = {
        isRange: false,
        singleDate: {
          jsDate: moment(endDateTimeObj.format('YYYY-MM-DD')).toDate(),
          formatted: endDateTimeObj.format('MMM DD, YYYY')
        },
        dateRange: null
      };
      this.meetingForm.get('meetingRecurrenceEndDate').enable();

      meeting['isRecurringMeeting'] = true;
      this.meetingRepeatOptions = this.meetingRepeatOptions.filter(
        (option) => option.key !== 'single'
      );
      if (this.editMeeting.occurrence_meta) {
        for (const key in this.editMeeting.occurrence_meta) {
          if (key == 'monthly') {
            meeting['meetingRecurrenceMonthDay'] =
              this.editMeeting.occurrence_meta[key][0];
          } else if (key == 'weekly') {
            meeting['meetingRecurrenceWeekDays'] =
              this.editMeeting.occurrence_meta[key]?.map(
                (x: number) => this.daysOfWeek[x]
              );
          }
          if (key == 'after') {
            meeting['meetingRecurrenceRepeatWeek'] =
              this.editMeeting.occurrence_meta[key];
          } else {
            meeting['meetingRecurrenceType'] = key;
          }
        }
      }

      meeting['meetingRecurrenceEndDate'] = this.meetingRecurrenceEndDateModel;
      this.recurringEndDatePickoptions = {
        ...this.recurringEndDatePickoptions,
        dateFormat: 'mmm dd, yyyy'
      };
    }

    this.meetingForm.patchValue(meeting);

    this.previousRecurrenceEndDatesDisable();
    this.endDateChanged(this.meetingRecurrenceEndDateModel);
  }

  removeFile(idx) {
    this.filesToBeUpload[idx]['isRemove'] = true;
    if (this.filesToBeUpload[idx]?.id) {
      this.removedFiles.push(this.filesToBeUpload[idx]);
    }
    this.filesToBeUpload.splice(idx, 1);
    if (this.filesToBeUpload.length < 6) {
      this.showFileErr = false;
    } else {
      this.showFileErr = true;
    }
  }

  public resetForm() {
    this.meetingForm.reset();
    this.editMeeting = null;
    this.inviteeList = [];
    this.filesToBeUpload = [];
    this.initializeForm();
    this.model = null;
    this.timeDiff = '';
    const meeting = {
      meetingName: '',
      meetingStartTime: '',
      meetingDate: this.model,
      meetingUrl: '',
      meetingAgenda: '',
      meetingMinutes: '',
      meetingInvitee: '',
      meetingDescription: '',
      zoom_pid: '',
      offline_recording_url: '',
      timezone: moment.tz.guess(),
      visibility_type: 'internal'
    };
    this.currentParicipantSpec = [];
    this.emailChipConfig.currentParicipant = [];
    this.meetingForm.patchValue(meeting);
  }

  public isValid() {
    return (
      this.meetingForm.invalid ||
      this.timeError !== '' ||
      this.selectedManagers.length === 0
    );
  }

  editMeetingOnZoom() {
    window.open(
      'https://zoom.us/meeting/' + this.editMeeting.zoom_pid,
      '_blank'
    );
    this.meetingPopupClose.emit();
  }

  getEmailList(event) {
    this.selectedManagers = event;
  }

  updateOccurences() {
    this.recurOccurences = [];
    if (
      this.meetingForm &&
      !this.isEditFormDisabled &&
      this.meetingForm.get('meetingDate').value &&
      this.meetingForm.get('meetingRecurrenceEndDate').value &&
      this.meetingStartTime.value &&
      this.meetingForm.get('meetingRecurrenceType').value !== 'single'
    ) {
      const startDate = moment(
        moment(
          this.meetingForm.get('meetingDate').value.singleDate.jsDate
        ).format('YYYY-MM-DD') +
          ' ' +
          this.meetingStartTime.value,
        'YYYY-MM-DD hh:mmA'
      ).format('YYYY-MM-DD');
      const startMom = moment.tz(
        startDate,
        this.timezoneConfig.selectedData
          ? this.timezoneConfig.selectedData
          : moment.tz.guess()
      );

      const endDate = moment(
        moment(
          this.meetingForm.get('meetingRecurrenceEndDate').value.singleDate
            .jsDate
        ).format('YYYY-MM-DD') +
          ' ' +
          this.meetingStartTime.value,
        'YYYY-MM-DD hh:mmA'
      ).format('YYYY-MM-DD');
      const endDateMom = moment.tz(
        endDate,
        this.timezoneConfig.selectedData
          ? this.timezoneConfig.selectedData
          : moment.tz.guess()
      );

      switch (this.meetingForm.get('meetingRecurrenceType').value) {
        case 'weekly':
          if (
            this.meetingForm.get('meetingRecurrenceWeekDays').value &&
            this.meetingForm.get('meetingRecurrenceWeekDays').value.length > 0
          ) {
            let nextDateW = startMom.clone();
            const meetingWeekDays = this.meetingForm.get(
              'meetingRecurrenceWeekDays'
            ).value;
            const repeatAfterWeek = this.meetingForm.get(
              'meetingRecurrenceRepeatWeek'
            ).value;
            while (nextDateW.isSameOrBefore(endDateMom)) {
              if (
                meetingWeekDays.indexOf(nextDateW.format('ddd').toLowerCase()) >
                -1
              ) {
                this.recurOccurences.push(
                  nextDateW.format('YYYY-MM-DD hh:mm A')
                );
              }
              if (
                nextDateW.format('YYYY-MM-DD') ==
                nextDateW.clone().endOf('week').format('YYYY-MM-DD')
              ) {
                nextDateW = nextDateW
                  .clone()
                  .add(repeatAfterWeek, 'weeks')
                  .startOf('week');
              } else {
                nextDateW.add(1, 'day');
              }
            }
          } else {
            this.recurOccurences = [];
          }
          break;
        case 'monthly':
          if (this.meetingForm.get('meetingRecurrenceMonthDay').value) {
            const setSafeDate = (date) => {
              const day = parseInt(
                this.meetingForm.get('meetingRecurrenceMonthDay').value,
                10
              );
              if (day > parseInt(date.endOf('month').format('DD'), 10)) {
                return moment(date.endOf('month').format('YYYY-MM-DD'));
              } else {
                return moment(date.set('date', day).format('YYYY-MM-DD'));
              }
            };
            let recurrenceNextMonthDate = setSafeDate(startMom.clone());

            if (recurrenceNextMonthDate.isSameOrBefore(endDateMom)) {
              while (recurrenceNextMonthDate.isSameOrBefore(endDateMom)) {
                if (startMom.isSameOrBefore(recurrenceNextMonthDate)) {
                  this.recurOccurences.push(
                    recurrenceNextMonthDate.format('YYYY-MM-DD hh:mm A')
                  );
                }
                recurrenceNextMonthDate = setSafeDate(
                  recurrenceNextMonthDate.clone().add(1, 'month')
                );
              }
            } else {
              this.recurOccurences = [];
            }
          } else {
            this.recurOccurences = [];
          }
          break;
        case 'daily':
          const nextDate = startMom.clone(); // eslint-disable-line
          while (nextDate.isSameOrBefore(endDateMom)) {
            this.recurOccurences.push(nextDate.format('YYYY-MM-DD hh:mm A'));
            nextDate.add(1, 'day');
          }
          break;
        case 'weekday':
          const nextDt = startMom.clone(); // eslint-disable-line
          while (nextDt.isSameOrBefore(endDateMom)) {
            if (nextDt.day() !== 0 && nextDt.day() !== 6) {
              this.recurOccurences.push(nextDt.format('YYYY-MM-DD hh:mm A'));
            }
            nextDt.add(1, 'day');
          }
          break;
        default:
          this.recurOccurences = [];
      }
    } else {
      this.recurOccurences = [];
    }

    if (this.recurOccurences.length == 0) {
      this.recurringError =
        'At least one occurence is required to schedule a recurring meeting.';
    } else {
      this.recurringError = '';
    }
  }

  // selectAmPm(amPm, time) {
  //   if (time === 'startTime') {
  //     this.startTimeAmPm = amPm;
  //   }
  // }

  meetingDurationHoursChange() {
    if (
      !this.isEditFormDisabled &&
      this.meetingDurationHours.value &&
      this.meetingDurationHours.value == '00'
    ) {
      this.meetingDurationMinutes.setValue('');
    }
  }

  timezoneChange() {
    if (!this.isEditFormDisabled) {
      this.previousDatesDisable();
      if (this.meetingForm.getRawValue().meetingDate) {
        this.meetingDate.setValue('');
        this.meetingForm.get('meetingRecurrenceEndDate').setValue('');
        this.recurOccurences = [];
        this.recurringError = '';
      }
    }
  }

  startDateChanged(event) {
    if (!this.isEditFormDisabled && event) {
      this.meetingForm.patchValue({
        meetingDate: { isRange: false, singleDate: event.singleDate }
      });
      if (
        !this.isEditFormDisabled &&
        this.meetingDate.value &&
        this.meetingStartTime.value
      ) {
        this.meetingForm.get('meetingRecurrenceEndDate').enable();
        this.meetingForm.get('meetingRecurrenceEndDate').setValue('');
      }
      this.previousRecurrenceEndDatesDisable();
    }
  }

  restrictTime(evt) {
    const theEvent = evt || window.event;

    // Handle key press
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    const regex = /[0-9:]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }

  endDateChanged(event) {
    if (
      this.meetingForm.get('meetingRecurrenceEndDate').enabled &&
      this.meetingForm.get('meetingRecurrenceType').value !== 'single'
      // this.meetingForm.get('isRecurringMeeting') &&
      // this.meetingForm.get('isRecurringMeeting').value
    ) {
      if (!this.isEditFormDisabled && this.meetingDate.value) {
        this.meetingForm.patchValue({
          meetingRecurrenceEndDate: {
            isRange: false,
            singleDate: event.singleDate
          }
        });
        const endDate = moment(
          this.meetingForm.get('meetingRecurrenceEndDate').value.singleDate
            .jsDate
        );
        this.endDateFomatted = endDate.format('MMM DD, YYYY');

        this.updateOccurences();
      }
    }
  }

  weeklyReccurenceChange($event, weekday: string) {
    const weeks = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    if (
      !this.isEditFormDisabled &&
      this.meetingForm.get('meetingRecurrenceWeekDays').enabled
    ) {
      this.meetingForm.get('meetingRecurrenceWeekDays').markAsTouched();
      const selectedWeeks = this.meetingForm.getRawValue()
        .meetingRecurrenceWeekDays as Array<string>;
      const updatedWeeks = $event.target.checked
        ? [...selectedWeeks, weekday]
        : selectedWeeks.filter((x) => x != weekday);

      this.meetingForm
        .get('meetingRecurrenceWeekDays')
        .setValue(
          weeks.filter((weekName) => updatedWeeks.indexOf(weekName) > -1)
        );

      this.updateOccurences();
    }
  }

  public getSelectedTimezone(timezoneValue) {
    this.timezoneConfig.selectedData = timezoneValue;
    this.timezoneChange();
  }

  get isGoogleLoggedIn() {
    return !!(this.dataService.user.google_cal_access_token != null);
  }

  refreshGoogleLoggedIn() {
    this.cdr.detectChanges();
  }

  googleCalenderLogin() {
    if (this.linkGoogleCalendarComp) {
      this.linkGoogleCalendarComp.googleCalenderLogin();
    }
  }

  getBMeetDetail() {
    this.loaderService.show();
    this.bMeetDataLoaded = false;
    this.apirequestService.getUserDetailFromBMeet().subscribe(
      (data) => {
        this.loaderService.hide();
        this.bMeetDataLoaded = true;
        if (data?.user?.id) {
          this.bmeetSigned = true;
        } else {
          this.bmeetSigned = false;
        }
        // this.cdr.detectChanges();
      },
      () => {
        this.loaderService.hide();
        this.bMeetDataLoaded = true;
        this.bmeetSigned = false;
      }
    );
  }

  closeMeetingModal() {
    this.closeModal.emit();
  }

  removeTeammate(teammate) {
    this.selectedTeammates = this.selectedTeammates.filter(
      (res) => res.email !== teammate.email
    );
  }

  selectedFromTimeDropdown() {
    this.fillToTimeDropdown();
  }

  fillToTimeDropdown(isInitialize = true) {
    const calculatedToTimeArr = [];
    for (let i = 1; i < 4 * 18; i++) {
      const time = moment(
        this.meetingForm.get('meetingStartTime').value,
        'h:mma'
      ).add(15 * i, 'minutes');

      const firstMoment = moment(
        this.meetingForm.get('meetingStartTime').value,
        'h:mma'
      );
      const secondMoment = time.clone();

      const diffMilliseconds = secondMoment.diff(firstMoment);
      const duration = moment.duration(diffMilliseconds);
      const diffHours =
        Math.floor(duration.asHours()) < 0
          ? 24 + Math.floor(duration.asHours())
          : Math.floor(duration.asHours());
      const diffMinutes =
        Math.floor(duration.asMinutes()) % 60 < 0
          ? 60 + (Math.floor(duration.asMinutes()) % 60)
          : Math.floor(duration.asMinutes()) % 60;

      if (diffHours > 0 && diffMinutes > 0) {
        calculatedToTimeArr.push({
          key: time.format('h:mma'),
          value: `${time.format('h:mma')} (${diffHours} hr ${diffMinutes} mins)`
        });
      } else if (diffMinutes > 0) {
        calculatedToTimeArr.push({
          key: time.format('h:mma'),
          value: `${time.format('h:mma')} (${diffMinutes} mins)`
        });
      } else if (diffHours > 0) {
        calculatedToTimeArr.push({
          key: time.format('h:mma'),
          value: `${time.format('h:mma')} (${diffHours} hr)`
        });
      }
    }
    this.toTimeArr = [...calculatedToTimeArr];
    this.cdr.detectChanges();
    if (isInitialize) {
      const toTime = moment(
        this.meetingForm.get('meetingStartTime').value,
        'h:mma'
      )
        .add(30, 'minutes')
        .format('h:mma');
      this.meetingForm.get('meetingEndTime').setValue(`${toTime}`);
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClickListner() {
    this.isAttachmentMenuClickedItem = -1;
  }

  getSelectedTime() {
    return `${this.meetingForm.get('meetingStartTime').value} to ${this.meetingForm.get('meetingEndTime').value}`;
  }

  getDuration() {
    const endTimeValue = this.toTimeArr.find(
      (x) => x.key === this.meetingForm.get('meetingEndTime').value
    );
    if (endTimeValue) {
      const parts = endTimeValue.value
        .replace('(', '')
        .replace(')', '')
        .split(' ');

      // Initialize variables to hold hours and minutes
      let hours = 0;
      let minutes = 0;

      // Loop through parts to extract hours and minutes
      for (let i = 0; i < parts.length; i++) {
        if (parts[i + 1] === 'hr') {
          hours += parseInt(parts[i]);
        } else if (parts[i + 1] === 'mins') {
          minutes += parseInt(parts[i]);
        }
      }

      // Calculate total minutes
      const totalMinutes = hours * 60 + minutes;

      // Return the total minutes
      return totalMinutes;
    } else {
      this.toastMessageService.showToastMessage(
        Constants.toastMessages.error,
        3000,
        true
      );
    }
  }

  repeatMeetingSelected(option) {
    this.meetingForm.get('meetingRecurrenceType').setValue('');
    this.meetingForm.get('meetingRecurrenceWeekDays').setValue([]);
    this.meetingForm.get('meetingRecurrenceMonthDay').setValue('');
    this.meetingForm.get('meetingRecurrenceEndDate').setValue('');
    if (option.key != 'single') {
      this.meetingForm
        .get('meetingRecurrenceEndDate')
        .setValidators([Validators.required]);
      if (option.key == 'Monthly') {
        this.meetingForm
          .get('meetingRecurrenceMonthDay')
          .setValidators([Validators.required]);
      } else {
        this.meetingForm.get('meetingRecurrenceMonthDay').setValidators([]);
      }
    } else {
      this.meetingForm.get('meetingRecurrenceEndDate').setValidators([]);
      this.meetingForm.get('meetingRecurrenceMonthDay').setValidators([]);
    }
    this.meetingForm.get('meetingRecurrenceType').updateValueAndValidity();
    this.meetingForm.get('meetingRecurrenceWeekDays').updateValueAndValidity();
    this.meetingForm.get('meetingRecurrenceMonthDay').updateValueAndValidity();
    this.meetingForm.get('meetingRecurrenceEndDate').updateValueAndValidity();
    this.meetingForm.updateValueAndValidity();
    this.meetingForm.get('meetingRecurrenceType').markAsUntouched();
    this.meetingForm.get('meetingRecurrenceWeekDays').markAsUntouched();
    this.meetingForm.get('meetingRecurrenceMonthDay').markAsUntouched();
    this.meetingForm.get('meetingRecurrenceEndDate').markAsUntouched();
    this.meetingForm.get('meetingRecurrenceType').setValue(option.key);
    this.updateOccurences();
  }

  updateWeeklyRepeat(type) {
    const value = parseInt(
      this.meetingForm.get('meetingRecurrenceRepeatWeek').value
        ? this.meetingForm.get('meetingRecurrenceRepeatWeek').value
        : 1
    );
    if (
      (type === 'increment' && value + 1 > 10) ||
      (type === 'decrement' && value - 1 < 1)
    ) {
      return;
    }
    if (type === 'increment') {
      this.meetingForm.patchValue({
        meetingRecurrenceRepeatWeek: value + 1
      });
    } else if (type === 'decrement') {
      const updatedValue = value - 1 > 0 ? value - 1 : 1;
      this.meetingForm.patchValue({
        meetingRecurrenceRepeatWeek: updatedValue
      });
    } else {
      this.meetingForm.patchValue({
        meetingRecurrenceRepeatWeek: 1
      });
    }
    this.updateOccurences();
  }

  convertFileSize(bytes) {
    const megabytes = bytes / (1024 * 1024);
    const kilobytes = bytes / 1024;

    if (megabytes >= 1) {
      return megabytes.toFixed(2) + ' MB';
    } else {
      return kilobytes.toFixed(2) + ' KB';
    }
  }

  nextRoundTime() {
    const currentTime = moment().format('h:mma');
    const parsedTime = moment(currentTime, 'h:mma');
    const remainder = parsedTime.minutes() % 15;
    const minutesToAdd = remainder === 0 ? 15 : 15 - remainder;
    const nextRoundedTime = parsedTime.add(minutesToAdd, 'minutes');
    const formattedTime = nextRoundedTime.format('h:mma');
    return formattedTime;
  }

  getProjectTeammates() {
    this.teammatesStaticSuggestions.isLoading = true;
    this.meetingService
      .getProjectDetailById(this.selectedProject.id)
      .pipe(
        map((projectDetail) => {
          return projectDetail['project'].users.map((user) => {
            return {
              name: user.first_name + ' ' + user.last_name,
              email: user.email
            };
          });
        }),
        finalize(
          () =>
            (this.teammatesStaticSuggestions = {
              ...this.teammatesStaticSuggestions
            })
        )
      )
      .subscribe(
        (teamamate) => {
          this.teammatesStaticSuggestions.isLoading = false;
          this.teammatesStaticSuggestions.data = teamamate.filter((x) =>
            this.teammatesStaticSuggestions.allowedEmailSuffix.some((z) =>
              x.email.endsWith(z)
            )
          );
        },
        (error) => {
          this.teammatesStaticSuggestions.isLoading = false;
          this.teammatesStaticSuggestions.error = error;
        }
      );
  }

  searchTeamMates($event: {
    pageNumber: number;
    pageSize: number;
    searchValue: string;
    isScroll: boolean;
  }) {
    if (!$event.isScroll) {
      this.teammatesDynamicSuggestions.data = [];
    }
    this.teammatesDynamicSuggestions.isLoading = true;
    this.apirequestService
      .getManagers($event.pageNumber, $event.pageSize, $event.searchValue)
      .pipe(
        finalize(
          () =>
            (this.teammatesDynamicSuggestions = {
              ...this.teammatesDynamicSuggestions
            })
        )
      )
      .subscribe(
        (data) => {
          this.teammatesDynamicSuggestions.isLoading = false;
          this.teammatesDynamicSuggestions.nextPageNumber = data.next_page;
          if ($event.isScroll) {
            this.teammatesDynamicSuggestions.data =
              this.teammatesDynamicSuggestions.data.concat(
                data.users
                  .filter(
                    (z) =>
                      this.teammatesStaticSuggestions.allowedEmailSuffix.some(
                        (q) => z.email.endsWith(q)
                      ) &&
                      this.teammatesDynamicSuggestions.data.findIndex(
                        (w) => w.email === z.email
                      ) === -1
                  )
                  .map((x) => ({
                    ...x,
                    name: x.first_name + ' ' + x.last_name
                  }))
              );
          } else {
            this.teammatesDynamicSuggestions.data = data.users
              .filter((z) =>
                this.teammatesStaticSuggestions.allowedEmailSuffix.some((q) =>
                  z.email.endsWith(q)
                )
              )
              .map((x) => ({
                ...x,
                name: x.first_name + ' ' + x.last_name
              }));
          }
        },
        (error) => {
          this.teammatesDynamicSuggestions.isLoading = false;
          this.teammatesDynamicSuggestions.error = error;
        }
      );
  }

  getExternalParticipantList() {
    this.externalSuggestions.isLoading = true;

    this.apirequestService
      .getClientByClientId(this.selectedProject.id)
      .pipe(
        tap(
          (clientDetail) => {
            this.externalSuggestions.isLoading = false;
            this.externalSuggestions.data =
              this.getMergedClientDetail(clientDetail);
          },
          (error) => {
            this.externalSuggestions.isLoading = false;
            this.externalSuggestions.error = error;
          }
        ),
        finalize(
          () => (this.externalSuggestions = { ...this.externalSuggestions })
        )
      )
      .subscribe();
  }

  getMergedClientDetail(clientDetail) {
    const associates = clientDetail.associates.map((user) => {
      return {
        name: user.name,
        email: user.email
      };
    });

    const client = {
      name: clientDetail.client.name,
      email: clientDetail.client.email
    };

    return [...associates, client].filter(
      (v, i, a) =>
        this.externalSuggestions.disAllowedEmailSuffix.every(
          (x) => !v.email.endsWith(x)
        ) && a.findIndex((e) => v.email === e.email) === i
    );
  }

  getExpertsParticipantList() {
    this.expertSuggestions.isLoading = true;
    console.log('export get');
    this.apirequestService
      .getProjectAllocatedExperts(this.selectedProject.id)
      .subscribe(
        (allocatedExperts) => {
          this.expertSuggestions.isLoading = false;
          if (Array.isArray(allocatedExperts?.allocations)) {
            this.expertSuggestions.data = allocatedExperts?.allocations
              .filter(
                (v, i, a) =>
                  !!v.developer_email &&
                  Array.isArray(v.skills) &&
                  v.skills.length > 0 &&
                  a.findIndex(
                    (w) => v.developer_email === w.developer_email
                  ) === i
              )
              .map((e) => ({
                name: e.developer_name,
                email: e.developer_email,
                skill: e.skills[0]
              }))
              .sort((a, b) => {
                if (a.skill < b.skill) return -1;
                if (a.skill > b.skill) return 1;
                return 0;
              });
            this.expertSuggestions = Object.assign({}, this.expertSuggestions);
          }
        },
        (error) => {
          this.expertSuggestions.error = error;
          this.expertSuggestions.isLoading = false;
        }
      );
  }

  openDeleteOption(id) {
    if (this.isAttachmentMenuClickedItem === id) {
      this.isAttachmentMenuClickedItem = -1;
    } else {
      this.isAttachmentMenuClickedItem = id;
    }
  }

  formSave(data) {
    this.loaderService.show();
    this.meetingService
      .postMeeting(data.value, this.selectedProject.id)
      .subscribe(
        (response) => {
          this.loaderService.hide();
          this.toastMessageService.showToastMessage(
            'Meeting Added successfully.'
          );
          this.meetingupdate.emit({
            date: data.date,
            meeting: response.meetings
          });
          this.closeMeetingModal();
        },
        (error) => {
          this.loaderService.hide();
          if (
            error?.error &&
            (typeof error.error === 'string' || error.error instanceof String)
          ) {
            this.toastMessageService.showToastMessage(error.error, 4000, true);
          } else if (
            error?.error?.error &&
            Array.isArray(error.error.error) &&
            error.error.error.length > 0
          ) {
            this.toastMessageService.showToastMessage(
              error.error.error[0],
              4000,
              true
            );
          }
        }
      );
  }

  formUpdate(data) {
    let updateType = 'single';
    if (this.editRecurringType == 'all') {
      updateType = 'all';
    } else {
      if (
        this.editMeeting.meeting_kind != 'recurring' &&
        data.value.meeting_kind == 'recurring'
      ) {
        updateType = 'all';
      } else {
        updateType = 'single';
      }
    }

    this.loaderService.show();
    const updateTypeObservable =
      updateType === 'single'
        ? this.meetingService.updateMeeting(
            this.commonService.selectedProjecId,
            data.id,
            data.value,
            true
          )
        : this.meetingService.updateAllRecurringMeeting(
            data.value,
            this.commonService.selectedProjecId,
            data.id
          );
    updateTypeObservable.subscribe(
      (response) => {
        this.loaderService.hide();
        this.toastMessageService.showToastMessage(
          'Details updated successfully.'
        );
        this.meetingupdate.emit({
          date: this.editMeeting.date,
          meeting: response.meetings
        });
        this.closeMeetingModal();
      },
      (error) => {
        this.loaderService.hide();
        if (
          error?.error &&
          (typeof error.error === 'string' || error.error instanceof String)
        ) {
          this.toastMessageService.showToastMessage(error.error, 4000, true);
        } else if (
          error?.error?.error &&
          Array.isArray(error.error.error) &&
          error.error.error.length > 0
        ) {
          this.toastMessageService.showToastMessage(
            error.error.error[0],
            4000,
            true
          );
        }
      }
    );
  }

  getParticipantList() {
    const formValue = this.meetingForm.getRawValue();
    return [
      ...formValue.teammates
        .filter(
          (x, i, self) => self.findIndex((z) => z.email === x.email) === i
        )
        .map((z) => ({
          name: z.name,
          email: z.email,
          user_type: 'teammate'
        })),
      ...formValue.external
        .filter(
          (x, i, self) => self.findIndex((z) => z.email === x.email) === i
        )
        .map((x) => ({
          name: x.name,
          email: x.email,
          user_type: 'external'
        })),
      ...formValue.experts
        .filter(
          (x, i, self) => self.findIndex((z) => z.email === x.email) === i
        )
        .map((c) => ({
          name: c.name,
          email: c.email,
          user_type: 'expert'
        }))
    ];
  }

  get participants() {
    const meetingFormValue = this.meetingForm.getRawValue();
    return [
      ...meetingFormValue.teammates,
      ...meetingFormValue.external,
      ...meetingFormValue.experts
    ];
  }

  get isStartTimeValid() {
    if (
      (!this.editMeeting ||
        (this.editMeeting &&
          this.editMeeting?.studio_call_type !== 'kickoff')) &&
      this.meetingDate.value &&
      this.meetingDate.value.singleDate.jsDate
    ) {
      const date = moment().tz(
        this.timezoneConfig.selectedData
          ? this.timezoneConfig.selectedData
          : moment.tz.guess()
      );
      const diff = moment(
        moment(this.meetingDate.value.singleDate.jsDate).format('YYYY-MM-DD') +
          ' ' +
          this.meetingForm.get('meetingStartTime').value,
        'YYYY-MM-DD h:mma',
        this.timezoneConfig.selectedData
          ? this.timezoneConfig.selectedData
          : moment.tz.guess()
      ).diff(date);

      return this.meetingKind != 'offline' ? diff > 0 : diff < 0;
    } else {
      return true;
    }
  }

  validateInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = parseInt(inputElement.value);

    if (isNaN(value) || value < 1 || value > 10) {
      inputElement.value = '1';
      this.meetingForm.get('meetingRecurrenceRepeatWeek').setValue(1);
    } else {
      inputElement.value = value.toString();
      this.meetingForm.get('meetingRecurrenceRepeatWeek').setValue(value);
    }
  }

  getActiveMeetingProvider() {
    if (this.editMeeting) {
      return this.editMeeting.meeting_provider === 'zoom' ? false : true;
    } else {
      return this.isBMeetPlatform;
    }
  }

  toggleMeetingProvider() {
    if (!this.editMeeting) {
      this.isBMeetPlatform = !this.isBMeetPlatform;
    }
  }

  isMOMSectionVisible() {
    if (
      (this.editMeeting && this.tabStatus === 'previous') ||
      this.meetingKind == 'offline'
    ) {
      return true;
    } else {
      return false;
    }
  }

  okClicked() {
    this.closeMeetingModal();
    if (!this.isBMeetPlatform) {
      window.open('https://zoom.us/meeting/schedule', '_blank', 'noopener');
    }
  }

  get isAuthorisedUser() {
    return (
      this.editMeeting &&
      this.currentUserName?.id ===
        (this.editMeeting.user_data
          ? this.editMeeting.user_data?.id
          : this.editMeeting.user?.id)
    );
  }

  get isKickOffCall() {
    if (this.editMeeting && this.editMeeting.studio_call_type === 'kickoff') {
      return true;
    }
    return false;
  }

  searchProject = debounce((event) => {
    if (event.key === 'Backspace' && this.selectedProject) {
      this.selectedProject = null;
      this.timezoneConfig.disabled = false;
      this.meetingForm.reset();
    }
    this.getProjects();
  });

  getProjects(isScroll?) {
    if (this.searchText.length < 2) {
      this.toggleProjectDropdown = false;
      return;
    } else {
      if (!isScroll) {
        this.params.page = 1;
        this.projectList = [];
      }
      this.toggleProjectDropdown = true;
      this.params.search = this.searchText;
      this.isShowPaginationLoader = true;
      if (this.projectSubscription) {
        this.projectSubscription.unsubscribe();
      }
      this.projectSubscription = this.meetingService
        .getProjectsName(this.params)
        .subscribe(
          (res) => {
            if (isScroll === 'scroll') {
              this.projectList = this.projectList.concat(res.projects);
            } else {
              this.projectList = res.projects;
            }
            this.toggleProjectDropdown = true;
            this.params.page = res.next_page;
            this.isShowPaginationLoader = false;
          },
          () => {
            this.isShowPaginationLoader = false;
          }
        );
    }
  }

  onScroll() {
    if (this.params.page !== null && !this.isShowPaginationLoader) {
      this.getProjects('scroll');
    }
  }

  changeProject(project) {
    this.selectedProject = project;
    this.searchText = this.selectedProject.name;
    this.toggleProjectDropdown = false;
    this.timezoneConfig.disabled = true;
    this.timezoneConfig = Object.assign({}, this.timezoneConfig);
    this.removeSelectedProject();
    this.getExpertsParticipantList();
    this.getProjectTeammates();
    this.getExternalParticipantList();
  }

  removeSelectedProject() {
    // this.toggleProjectDropdown = false;
    // this.selectedProject = '';
    // this.searchText = '';
    this.meetingForm.get('teammates').patchValue([]);
    this.meetingForm.get('external').patchValue([]);
    this.meetingForm.get('experts').patchValue([]);
    this.teammatesDynamicSuggestions.data = [];
    this.teammatesStaticSuggestions.data = [];
    this.expertSuggestions.data = [];
    this.externalSuggestions.data = [];
    this.expertSuggestions = Object.assign({}, this.expertSuggestions);
    this.externalSuggestions = Object.assign({}, this.externalSuggestions);
    this.teammatesStaticSuggestions = Object.assign(
      {},
      this.teammatesStaticSuggestions
    );
    this.teammatesDynamicSuggestions = Object.assign(
      {},
      this.teammatesDynamicSuggestions
    );
  }
}
