<div
  tabindex="0"
  #dropdownWrapper
  [(elemDetect)]="isDropdownVisible"
  class="{{ customClass }} mr-context-menu-wrp mr-select custom-formcontrol"
  aria-controls="listbox"
  aria-haspopup="listbox"
  aria-expanded="false"
  role="combobox"
  (keydown)="handleKeyPress($event)"
  [ngClass]="{ disabled: disabled }"
>
  <div
    tabindex="-1"
    class="mr-context-menu-trigger has-arrow"
    [ngClass]="{ open: isDropdownVisible, disabled: disabled }"
    (click)="toggleDropdown()"
  >
    {{
      optionValueStr && selectedOption[optionValueStr]
        ? selectedOption[optionValueStr]
        : selectedOption
    }}
  </div>

  <div *ngIf="isDropdownVisible" role="listbox" class="position-absolute w-100">
    <input
      #searchBox
      class="searchBox form-control"
      placeholder="Search..."
      type="text"
      [(ngModel)]="searchOption"
      (keydown)="searchBoxKeyPress($event)"
    />
    <div
      class="dropdownlist-wrapper mr-context-menu max-height abs-pos secondary"
    >
      <div class="list">
        <div
          [attr.tabindex]="isDropdownVisible ? i : '-1'"
          id="{{ idPrefix }}_{{
            optionKeyStr && option[optionKeyStr]?.toString()
              ? option[optionKeyStr]?.toString().replace(':', '')
              : option?.toString().replace(':', '')
          }}"
          class="context-menu-item"
          [class.active]="
            optionKeyStr
              ? selectedOption[optionKeyStr] === option[optionKeyStr]
              : selectedOption === option
          "
          [class.disabled]="option.disabled"
          [attr.title]="option.title"
          (click)="onSelectOption(option); $event.stopPropagation()"
          *ngFor="let option of filteredList; let i = index"
        >
          {{
            optionValueStr && option[optionValueStr]
              ? option[optionValueStr]
              : option
          }}
        </div>
      </div>
      <div class="no-record text-center">No record found !!</div>
    </div>
  </div>
</div>
