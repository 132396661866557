import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CallScoreService } from '../../../shared/shared-module/services/call-score.service';
import { ToastMessageService } from '../../services/toast.message.service';

@Component({
  selector: 'app-call-score',
  templateUrl: './call-score.component.html',
  styleUrls: ['./call-score.component.scss']
})
export class CallScoreComponent implements OnInit {
  @ViewChild('callScore', { static: true }) callScore: ModalDirective;
  constructor(
    public callScoreService: CallScoreService,
    public toastMessageService: ToastMessageService
  ) {}
  public isLoader = false;
  public currentScoreIndex: number;
  public currentScoreData = 1;
  public callScoreData = [];
  public getCurrentInfo;
  public selectedRating = '';
  public selectedFeedback = '';
  public selectedSaveBtn = false;
  public scoreToSend = [
    {
      call_num: '',
      call_value: '',
      feedback: '',
      save_btn: false
    }
  ];
  public callScoresItem = [
    { call_num: '-1', call_value: 'No Show' },
    {
      call_num: '0',
      call_value: ['Not Qualified', 'Not Interested', 'Unresponsive']
    },
    {
      call_num: '1',
      call_value: ['Not talking to the right person', 'Language Barrier']
    },
    { call_num: '2', call_value: 'No Budget Today (future)' },
    { call_num: '3', call_value: 'Idea needs refinement' },
    { call_num: '4', call_value: 'No timeline in mind' },
    { call_num: '5', call_value: 'Follow up needed' },
    { call_num: '6', call_value: 'Need to Schedule Spec Call' },
    { call_num: '7', call_value: ['Spec Call Scheduled', 'Agreement Sent'] },
    { call_num: '8', call_value: 'Ready to Proceed' }
  ];
  ngOnInit(): void {
    this.callScoreService.notifyCallScoreData.subscribe((data) => {
      if (data) {
        this.callScoreData = data;
        this.getCurrentInfo = data[0];
      }
    });
  }
  public checkArray(value) {
    if (typeof value === 'object') {
      return true;
    } else {
      return false;
    }
  }
  public nextData() {
    this.currentScoreData = this.currentScoreData + 1;
    this.selectedRatingRecord(this.currentScoreData - 1);
    this.getCurrentInfo = this.callScoreData[this.currentScoreData - 1];
  }
  public prevData() {
    this.currentScoreData = this.currentScoreData - 1;
    this.selectedRatingRecord(this.currentScoreData - 1);
    this.getCurrentInfo = this.callScoreData[this.currentScoreData - 1];
  }
  public selectCurrentScore(scoreIndex, selectedRating?) {
    if (selectedRating) {
      this.selectedRating = selectedRating;
      this.onChangeRating();
    }
    this.currentScoreIndex = scoreIndex;
  }
  public selectedRatingRecord(currentIndex) {
    let selectedRating;
    if (this.scoreToSend[currentIndex] === undefined) {
      selectedRating = {
        call_num: '',
        call_value: '',
        feedback: '',
        save_btn: false
      };
      this.scoreToSend.push(selectedRating);
      this.selectedRating = '';
      this.selectedFeedback = '';
      this.currentScoreIndex = null;
      this.selectedSaveBtn = false;
    } else {
      this.selectedRating =
        this.scoreToSend[currentIndex].call_num +
        ',' +
        this.scoreToSend[currentIndex].call_value;
      this.selectedFeedback = this.scoreToSend[currentIndex].feedback;
      this.selectedSaveBtn = this.scoreToSend[currentIndex].save_btn;
      this.currentScoreIndex = this.callScoresItem.findIndex(
        (rating) => rating.call_num === this.scoreToSend[currentIndex].call_num
      );
    }
  }
  popupHide() {
    this.callScoreService.showCallScorePopup = false;
    this.currentScoreData = 1;
  }
  popupShow() {
    this.selectedRatingRecord(this.currentScoreData - 1);
  }
  onRatingFeedbackChange() {
    const getValue = this.selectedRating.split(',');
    const selectedRating = {
      call_num: getValue[0],
      call_value: getValue[1],
      feedback: this.selectedFeedback,
      save_btn: true
    };
    this.scoreToSend[this.currentScoreData - 1].call_num =
      selectedRating?.call_num;
    this.scoreToSend[this.currentScoreData - 1].call_value =
      selectedRating?.call_value;
    this.scoreToSend[this.currentScoreData - 1].save_btn =
      selectedRating?.call_num !== '' ? selectedRating?.save_btn : false;
    this.scoreToSend[this.currentScoreData - 1].feedback =
      selectedRating?.feedback;
  }
  onChangeRating() {
    this.selectedSaveBtn = true;
    this.onRatingFeedbackChange();
  }
  onChangeFeedback() {
    this.onRatingFeedbackChange();
  }
  saveData() {
    this.isLoader = true;
    const id = this.getCurrentInfo?.id;
    const data = new FormData();
    const getValue = this.selectedRating.split(',');
    const feedback = this.selectedFeedback;
    data.append('spec_call[rating_value]', getValue[0]);
    data.append('spec_call[rating]', getValue[1]);
    data.append('spec_call[feedback]', feedback);
    this.callScoreService.updateSpecCall(id, data).subscribe(
      () => {
        if (this.callScoreData.length === 1) {
          this.callScoreData = [];
          this.scoreToSend = [];
          this.callScore.hide();
        } else {
          this.callScoreData.splice(this.currentScoreData - 1, 1);
          this.scoreToSend.splice(this.currentScoreData - 1, 1);
          this.currentScoreData = 1;
          this.selectedRatingRecord(this.currentScoreData - 1);
          this.getCurrentInfo = this.callScoreData[0];
        }
        this.isLoader = false;
        this.toastMessageService.showToastMessage(
          'Spec call has been successfully updated.'
        );
      },
      () => {
        this.isLoader = false;
        this.toastMessageService.showToastMessage('Something went wrong.');
      }
    );
  }
  hideCallPop() {
    this.callScore.hide();
  }
  ngOnDestory() {
    this.callScoreService.notifyCallScoreData.unsubscribe();
  }
}
