import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.component.html'
})
export class UnauthorizedAccessComponent {
  constructor(public router: Router) {}

  homePage() {
    this.router.navigate(['/projects']);
  }
}
