<div class="toaster">
  <div class="toast-icon" [class]="options.toastClass">
    <i aria-hidden="true" class="circle-size"></i>
  </div>
  <div class="toast-details">
    <div>
      <div
        *ngIf="title"
        [class]="options.titleClass"
        [attr.aria-label]="title"
        class="custom-title"
      >
        {{ title }}
      </div>
      <div
        *ngIf="message && options.enableHtml"
        role="alert"
        aria-live="polite"
        class="custom-message"
        [class]="options.messageClass"
        [innerHTML]="message"
      ></div>
      <div
        *ngIf="message && !options.enableHtml"
        role="alert"
        aria-live="polite"
        class="custom-message"
        [class]="options.messageClass"
        [attr.aria-label]="message"
      >
        {{ message }}
      </div>
    </div>
  </div>
  <div class="close-icon">
    <a
      *ngIf="options.closeButton"
      (click)="remove()"
      class="toast-close-button"
    >
      &times;
    </a>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
