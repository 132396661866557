import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import momentz from 'moment-timezone';
import moment from 'moment';
import { from, Subject } from 'rxjs';
import { pluck } from 'rxjs/operators';
@Injectable()
export class SharedService {
  showModal: any = new Subject(); // eslint-disable-line
  modalRef: BsModalRef;

  public addedTemplate = {
    id: '',
    category_ids: [],
    title: '',
    reference_urls: [],
    platform_ids: [],
    selected: false
  };
  public addedFeature = {
    id: '',
    title: '',
    description: '',
    template_ids: [],
    difficulty: 6,
    algorithm: 'none',
    stream_media: false,
    interface_type: 'backend',
    reference_urls: [],
    selected: false
  };
  promotionList = [];
  cardPaid = false;
  expandedView = false;
  bundleFeatures = [];
  constructor(private modalService: BsModalService) {}

  getLocalTimezoneCode() {
    return momentz().tz(moment.tz.guess()).format('z');
  }

  public addHttps(img?) {
    let modifyImg = '';
    if (img) {
      if (img.lastIndexOf('http') === 0) {
        if (img.includes('https')) {
          return img;
        }
        return img.replace('http', 'https');
      } else if (img.lastIndexOf('http') > 0) {
        modifyImg = img.substring(img.lastIndexOf('http'));
        if (modifyImg.includes('https')) {
          return modifyImg;
        }
        return modifyImg.replace('http', 'https');
      }
      return img;
    }
  }

  numberWithCommas(x) {
    const formatter = new Intl.NumberFormat('en-US');
    const formatedPrice = formatter.format(x);
    return formatedPrice;
  }

  decimalNumber(num) {
    return Math.round(num * 100) / 100;
  }

  editorLink(event) {
    if (event.target.tagName.toLowerCase() === 'a') {
      event.target.classList.add('linkClicked');
      event.target.setAttribute('contenteditable', 'false');
    }
    //uncomment when using "unlink" in editor
    // else{
    //   if(document.querySelector('.linkClicked')){
    //       document.querySelectorAll('.linkClicked').forEach((item)=> {
    //           item.removeAttribute("contenteditable")
    //       });
    //   }
    // }
  }

  ceilNumber(n) {
    return Math.ceil(n);
  }

  public getMappedArrayModel<T>(modelToBeMapped: T[], jsonObject?): T[] {
    if (Array.isArray(jsonObject) && jsonObject.length > 0) {
      from(jsonObject)
        .pipe(pluck('attributes'))
        .subscribe((item: T) => {
          modelToBeMapped.push(item);
        });
    }
    return modelToBeMapped;
  }
}
