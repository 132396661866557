import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { Angulartics2Module } from 'angulartics2';
// import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { InternationalPhoneNumberModule } from '../../shared/custom-international-phone-number/phone-number.module';
import { rootRouterConfig } from '../../app.route';
import { AuthGuard } from '../../auth-guard.service';
import { UrlParameterModel } from '../../shared/shared-module/models/url-parameters-model/url-parameter.model';
import { FooterService } from '../../shared/shared-module/services/footer.service';
import { ModelMapperService } from '../../shared/shared-module/services/model-mapper.service';
import { ForgotPasswordComponent } from '../login-module/forgot-password/forgot-password.component';
import { LoginComponent } from '../login-module/login/login.component';
import { ResetPasswordComponent } from '../login-module/reset-password/reset-password.component';
import { SignupComponent } from '../login-module/signup/signup.component';
import { GlobalErrorHandler } from '../services/global-error-handler';
import { LoaderService } from '../services/loader.service';
import { ProjectStatusService } from '../services/project-status.service';
import { ToastMessageService } from '../services/toast.message.service';
import { AppComponent } from './app.component';
import { CallScoreComponent } from './call-score/call-score.component';
import { CodeRepoComponent } from './code-repo/code-repo.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MyTaskComponent } from './header/my-task/my-task.component';
import { MainLeftpanelComponent } from './main-leftpanel/main-leftpanel.component';

import * as Sentry from '@sentry/angular-ivy';
import { CustomAngularEditorModule } from '../../shared/custom-angular-editor/custom-angular-editor.module';
import { CustomToastComponent } from '../../shared/shared-module/components/custom-toast/custom-toast.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PartnerBasicInformationComponent } from './partner-basic-information/partner-basic-information.component';
import { RevampHeaderComponent } from './revamp-header/revamp-header.component';
import { UnauthorizedAccessComponent } from './unauthorized-access/unauthorized-access.component';
import { UnderMaintainanceComponent } from './under-maintainance/under-maintainance.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { ActionCableService } from 'angular2-actioncable';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { ApiRequests } from '../../shared/shared-module/services/apiRequests.service';
import { DataService } from '../../shared/shared-module/services/data.service';
import { SharedModule } from '../../shared/shared-module/shared.module';
import { storeState } from './store';
import { FontAwesomeIconModule } from '../../shared/shared-module/font-awesome-icon.module';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { ForceIdHandlerInterceptor } from '../../shared/shared-module/forceId-handler.interceptor';
import { AngularMyDatePickerModule } from 'angular-mydatepicker-ivy';
import { VisiblityObserverDirective } from './notification-center/directives/visiblity-observer.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { DynamicLoaderService } from '../../shared/shared-module/services/dynamic-lazy-loader.service';
import { MeetingSharedModule } from '../../core/meeting-shared-module/meeting-shared-module.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SignupComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MainLeftpanelComponent,
    CodeRepoComponent,
    PartnerBasicInformationComponent,
    PageNotFoundComponent,
    UnauthorizedAccessComponent,
    UnderMaintainanceComponent,
    RevampHeaderComponent,
    CallScoreComponent,
    MyTaskComponent,
    NotificationCenterComponent,
    VisiblityObserverDirective
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(rootRouterConfig, {
      onSameUrlNavigation: 'reload',
      useHash: true
      // preloadingStrategy: PreloadAllModules,
    }),
    FormsModule,
    SharedModule.forRoot(),
    HttpClientModule,
    Angulartics2Module.forRoot(),
    AngularMyDatePickerModule,
    InternationalPhoneNumberModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    AngularEditorModule,
    CustomAngularEditorModule,
    ServiceWorkerModule.register('safety-worker.js', {
      enabled: environment.ENABLE_SERVICE_WORKER,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      closeButton: true,
      positionClass: 'toast-top-right',
      newestOnTop: false // added custom toast!
    }), // ToastrModule added
    NgxsModule.forRoot(storeState),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    FontAwesomeIconModule,
    OverlayModule,
    MeetingSharedModule
  ],
  providers: [
    ActionCableService,
    AuthGuard,
    LoaderService,
    ProjectStatusService,
    ToastMessageService,
    FooterService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {}, // eslint-disable-line
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForceIdHandlerInterceptor,
      multi: true
    },
    DynamicLoaderService
  ],
  // entryComponents: [CustomToastComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    public modelMapperService: ModelMapperService,
    public dataService: DataService,
    private apiRequests: ApiRequests
  ) {
    dataService.setIncomingUrlParameters(this.getUrlParamsFromUrl());
    this.apiRequests.fetchPartnerDetails().subscribe();
  }
  public getUrlParamsFromUrl(): UrlParameterModel {
    const path = location.href;
    let query;
    if (path.indexOf('?') !== -1) {
      if (path.indexOf('auth_type=zoomlogin') > -1) {
        this.authZoomLogin(path);
      }

      if (path.indexOf('auth_type=googlecalenderlogin') > -1) {
        this.googleCalendarLogin(path);
      }
      query = path.substr(path.indexOf('?') + 1);
      const result = {};
      query.split('&').forEach((part) => {
        const item = part.split('=');
        if (item[0] !== '') {
          const encodedText = encodeURIComponent(item[1]);
          result[item[0]] = decodeURIComponent(encodedText);
        }
      });
      return this.modelMapperService.mapToUrlParameterModel(
        new UrlParameterModel(),
        result
      );
    }
    return null;
  }

  authZoomLogin(path) {
    const auth = path.split('&')[1];
    if (auth.indexOf('code=') > -1) {
      const authCode = auth.split('=')[1];
      if (authCode != null && authCode != '') {
        localStorage.setItem('zoomAuthCode', authCode);
      }
    }
  }

  googleCalendarLogin(path) {
    localStorage.setItem('google_cal_auth', 'true');
    if (path.indexOf('error=') == -1) {
      const params = path.split('&');
      for (const i in params) {
        if (params[i].indexOf('code') > -1) {
          localStorage.setItem('google_cal_auth_code', params[i].split('=')[1]);
          if (window.opener) {
            window.opener.postMessage(
              'google_cal_auth_code',
              window.parent.origin
            );
            window.close();
          }
        }
      }
    } else {
      localStorage.setItem('google_cal_auth_error', 'true');
    }
  }
}
