import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(
    items?: any[],
    searchText?: string,
    key?: string,
    key2?: string
  ): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter((item) => {
      if (key === 'manager') {
        const first_name = item.first_name.toLowerCase();
        const last_name = item.last_name.toLowerCase();
        const name = first_name + ' ' + last_name;
        return name.includes(searchText);
      } else if (key === 'projects') {
        const { client_name, assigned_name, location, client_email } = item;
        return client_name
          ? client_name.toLowerCase().includes(searchText)
          : '' || assigned_name
            ? assigned_name.toLowerCase().includes(searchText)
            : '' || (location && location.includes(searchText)) || client_email
              ? client_email.toLowerCase().includes(searchText)
              : '';
      } else {
        return key2
          ? item[key2].toLowerCase().includes(searchText) ||
              (key && item[key].toLowerCase().includes(searchText))
          : item[key].toLowerCase().includes(searchText);
      }
    });
  }
}
