<div
  *ngIf="timezones.length > 0"
  id="time_zone"
  [class]="{ 'form-group': timezoneConfig.isFormGrpClassEnable }"
>
  <label>
    Timezone
    <em *ngIf="timezoneConfig.isRequired">*</em>
  </label>

  <mat-select
    [tooltip]="
      timezoneConfig.componentName === 'myAccountComponent'
        ? 'Changes made in timezone will not update the working hours. Please update your working hours.'
        : ''
    "
    (openedChange)="openedChange($event)"
    placeholder="Select"
    [(ngModel)]="selectedItem"
    [compareWith]="comparer"
    [disabled]="!timezoneConfig.disabled"
  >
    <!-- <mat-select-trigger>
      {{ selectedItem }}
    </mat-select-trigger> -->
    <div class="select-container">
      <mat-optgroup id="timezone-search">
        <input
          #search
          autocomplete="off"
          placeholder="Search"
          aria-label="Search"
          autofocus
          matInput
          [(ngModel)]="searchText"
          (keydown)="_filter()"
        />
      </mat-optgroup>
      <mat-optgroup *ngIf="filteredTimezone.length === 0">
        <div>No results found!</div>
      </mat-optgroup>
      <mat-option
        (onSelectionChange)="selectionChange($event)"
        *ngFor="let option of filteredTimezone"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </div>
  </mat-select>
  <div class="invalid-feedback">{{ timezoneErrorMsg }}</div>
</div>
