import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { LoaderService } from '../../../../core/services/loader.service';
import { IsAuthTokeUpdated, SetIsLoaderEnable } from './loader.action';
import { LoaderModel } from './loader.model';
@State<LoaderModel>({
  name: 'loaderstate',
  defaults: {
    isLoader: false,
    isPaginationLoader: false,
    title: 'loader',
    isAuthTokenUpdated: false
  }
})
@Injectable()
export class LoaderState {
  constructor(private loaderService: LoaderService) {}

  @Action(SetIsLoaderEnable)
  setLoaderState(
    { getState, setState }: StateContext<LoaderModel>,
    { payload }: SetIsLoaderEnable
  ) {
    payload ? this.loaderService.show() : this.loaderService.hide();
    setState({
      ...getState(),
      isLoader: payload
    });
  }

  @Action(IsAuthTokeUpdated)
  authTokenUpdated(
    { getState, setState }: StateContext<LoaderModel>,
    { payload }: IsAuthTokeUpdated
  ) {
    setState({
      ...getState(),
      isAuthTokenUpdated: payload
    });
  }
}
