<div
  class="participant-wrapper mt-2"
  [class.disabled]="disabledElement"
  [elemDetect]="isShowDropdown"
  (elemDetectChange)="onCloseDropdown($event)"
>
  <div class="input-dropdown-wrp">
    <input
      #staticFilteredCount
      style="display: none"
      type="hidden"
      value="{{
        staticUserSuggestions
          ? (
              staticUserSuggestions.data
              | filter: searchValue : 'name' : 'email'
            ).length
          : 0
      }}"
    />
    <input
      #staticFilteredEmail
      style="display: none"
      type="hidden"
      value="{{
        staticFilteredCount?.value === 1
          ? (staticUserSuggestions.data
              | filter: searchValue : 'name' : 'email')[0]?.email
          : null
      }}"
    />
    <input
      [class.disabled]="disabledElement"
      tabindex="0"
      (click)="openDropdown()"
      #searchField
      type="text"
      class="mr-input sm form-control"
      [(ngModel)]="searchValue"
      (keyup)="
        onKeyup($event, staticFilteredCount?.value, staticFilteredEmail?.value)
      "
      placeholder="{{ placeholder }}"
    />
    <em
      tabindex="-1"
      (click)="toggleDropdown()"
      class="dropdown-arrow"
      [class.inverted]="isShowDropdown"
      role="button"
    ></em>
  </div>
  <div
    tabindex="-1"
    (click)="$event.stopPropagation()"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
    [scrollWindow]="false"
    class="mr-context-menu abs-pos max-height w-100"
    *ngIf="isShowDropdown || isShowSuggestionsDropdown"
  >
    <div #suggesstionWrapper class="user-groups">
      <ng-container *ngIf="!isShowSuggestionsDropdown && !disabledElement">
        <ng-container *ngIf="fieldType !== 'Expert'">
          <div
            *ngIf="
              staticUserSuggestions?.data?.length > 0 &&
              !disabledElement &&
              (
                staticUserSuggestions.data
                | filter: searchValue : 'name' : 'email'
              ).length > 0
            "
            class="checkbox-title-row"
          >
            <span class="row-label">Select all</span>
            <div class="mr-custom-checkbox">
              <input
                tabindex="-1"
                [ngModel]="isSelectAllChecked"
                (change)="onSelectAll($event)"
                type="checkbox"
                class="hidden-input"
              />
              <label></label>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!disabledElement">
        <ng-container *ngIf="staticUserSuggestions">
          <ng-container *ngIf="staticUserSuggestions.isLoading">
            <ng-container *ngTemplateOutlet="loader"></ng-container>
          </ng-container>
          <ng-container *ngIf="fieldType === 'Expert'; else noRecord">
            <span
              *ngIf="
                !staticUserSuggestions.isLoading &&
                staticUserSuggestions.data.length === 0
              "
              class="h-100 w-100 text-center d-flex no-record px-3 mt-5 mb-5"
              >No expert allocated on this build card</span
            >
            <span
              *ngIf="
                !staticUserSuggestions.isLoading &&
                staticUserSuggestions.data.length > 0 &&
                (
                  staticUserSuggestions.data
                  | filter: searchValue : 'name' : 'email'
                ).length === 0
              "
              class="h-100 w-100 text-center d-flex no-record px-3 mt-5 mb-5"
              >No Records were found</span
            >
          </ng-container>
          <ng-template #noRecord>
            <ng-container
              *ngIf="
                isShowSuggestionsDropdown
                  ? !dynamicUserSuggestions?.isLoading &&
                    dynamicUserSuggestions?.data?.length === 0
                  : !staticUserSuggestions.isLoading &&
                    (
                      staticUserSuggestions.data
                      | filter: searchValue : 'name' : 'email'
                    ).length === 0
              "
            >
              <span
                class="h-100 w-100 text-center d-flex no-record px-3 mt-5 mb-5"
                >No Records were found</span
              >
            </ng-container>
          </ng-template>
          <ng-container
            *ngIf="
              !isShowSuggestionsDropdown &&
              !staticUserSuggestions.isLoading &&
              staticUserSuggestions.data?.length > 0
            "
          >
            <ng-container
              *ngFor="
                let userInfo of staticUserSuggestions.data
                  | filter: searchValue : 'name' : 'email';
                let i = index
              "
            >
              <div
                *ngIf="
                  fieldType === 'Expert' &&
                  (i === 0 ||
                    (i > 0 &&
                      staticUserSuggestions.data[i - 1].skill !==
                        staticUserSuggestions.data[i].skill))
                "
                class="checkbox-title-row align-items-end"
              >
                <span class="row-label">{{ userInfo.skill | titlecase }}</span>
                <div class="mr-custom-checkbox">
                  <input
                    [ngModel]="isAllExpertsChecked(userInfo.skill)"
                    (change)="selectAllExpertsBySkill($event, userInfo.skill)"
                    type="checkbox"
                    class="hidden-input"
                  />
                  <label></label>
                </div>
              </div>
              <ng-container
                *ngTemplateOutlet="
                  userItem;
                  context: {
                    $implicit: userInfo,
                    rowIndex: i,
                    arrayType: 'static'
                  }
                "
              ></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="dynamicUserSuggestions && isShowSuggestionsDropdown"
        >
          <ng-container *ngIf="dynamicUserSuggestions.data?.length > 0">
            <ng-container
              *ngFor="
                let userInfo of dynamicUserSuggestions.data;
                let i = index
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  userItem;
                  context: {
                    $implicit: userInfo,
                    rowIndex: i,
                    arrayType: 'dynamic'
                  }
                "
              ></ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="dynamicUserSuggestions.isLoading">
            <ng-container *ngTemplateOutlet="loader"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div
  tabindex="-1"
  *ngIf="!isShowDropdown && !isShowSuggestionsDropdown"
  class="selected-participants"
>
  <div
    class="selected-user"
    *ngFor="let userInfo of selectedUsers; let i = index"
  >
    <div
      class="user-initial"
      [style.backgroundColor]="userInitialColor[i % userInitialColor.length]"
    >
      {{ userInfo.name | nameInitial }}
    </div>
    <div title="{{ userInfo.email }}" class="user-email">
      {{ userInfo.email }}
    </div>
    <em
      *ngIf="!disabledElement"
      tabindex="-1"
      class="remove-user"
      role="button"
      (click)="removeSelectedUser(userInfo)"
    ></em>
  </div>
</div>

<ng-container> </ng-container>

<ng-template
  let-userInfo
  let-rowIndex="rowIndex"
  let-arrayType="arrayType"
  #userItem
>
  <div
    title="{{ userInfo.name }}&nbsp;&lt;{{ userInfo.email }}&gt;"
    class="context-menu-item sm-y-pad"
  >
    <div
      class="user-card has-checkbox"
      [style.cursor]="!disabledElement ? 'pointer' : 'auto'"
    >
      <div
        class="user-initial"
        [style.backgroundColor]="
          userInitialColor[rowIndex % userInitialColor.length]
        "
      >
        {{ userInfo.name | nameInitial }}
      </div>
      <div class="user-info">
        <div class="user-name">{{ userInfo.name }}</div>
        <div class="user-email">{{ userInfo.email }}</div>
      </div>
      <div *ngIf="!disabledElement" class="mr-custom-checkbox">
        <input
          tabindex="-1"
          [(ngModel)]="userInfo.selected"
          (change)="onChangeCheck($event, userInfo)"
          type="checkbox"
          class="hidden-input"
          role="button"
        />
        <label></label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <div class="context-menu-item sm-y-pad">
    <div class="user-card has-checkbox">
      <div class="user-initial skeleton-loader"></div>
      <div class="user-info">
        <div class="user-name skeleton-loader"></div>
        <div class="user-email skeleton-loader"></div>
      </div>
    </div>
  </div>
  <div class="context-menu-item sm-y-pad">
    <div class="user-card has-checkbox">
      <div class="user-initial yellow-bg skeleton-loader"></div>
      <div class="user-info">
        <div class="user-name skeleton-loader"></div>
        <div class="user-email skeleton-loader"></div>
      </div>
    </div>
  </div>
</ng-template>
