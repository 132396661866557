//Here we define four actions for CRUD operations respectively
export enum AccountsActionTypes {
  ADD_ONE = '[Accounts] Add One',
  UPDATE_ONE = '[Accounts] Update One',
  DELETE_ONE = '[Accounts] Delete One',
  GET_ALL = '[Accounts] Get All',
  GET_ONE = '[Accounts] Get One'
}

//Read All
export class GetAllAccounts {
  static readonly type = AccountsActionTypes.GET_ALL;
  constructor(
    public pagination,
    public query: string
  ) {}
}
