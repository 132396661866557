import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  showPageloader = new Subject<boolean>();
  show() {
    this.showPageloader.next(true);
  }
  hide() {
    this.showPageloader.next(false);
  }
}
