import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { ApiRequests } from '../../services/apiRequests.service';
import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';

/**
 * this is for single selection of timezone
 */
@Component({
  selector: 'app-time-zone',
  templateUrl: './time-zone.component.html',
  styleUrls: ['./time-zone.component.scss']
})
export class TimeZoneComponent implements OnInit, OnChanges {
  @Input() timezoneConfig = {
    id: 'time_zone',
    componentName: '',
    saveOrCancelChanges: '', // contains on 'save' or 'cancel',
    selectedData: '',
    disabled: false,
    isRequired: true,
    isFormGrpClassEnable: true,
    isDropdownOnTop: false
  };
  timezones = [];
  selectedItem = [];
  @Output() sendSelectedTimeZone = new EventEmitter();
  dropdownSettings = {};
  timezoneErrorMsg = '';
  dropDownSelect = false;
  filteredTimezone = [];
  searchText = '';

  constructor(
    private apiRequest: ApiRequests,
    private commonService: CommonService,
    private dataService: DataService
  ) {
    if (this.commonService.timezones) {
      this.timezones = this.commonService.timezones;
      this.filteredTimezone = [...this.timezones];
    } else {
      this.getTimeZones();
    }
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      idField: this.timezoneConfig.id,
      textField: 'timeZone',
      allowSearchFilter: true,
      enableCheckAll: false,
      itemsShowLimit: 5,
      defaultOpen: false,
      closeDropDownOnSelection: true
    };
  }

  ngOnChanges() {
    this.timezoneErrorMsg = '';
    this.selectBasedOnCompoent();
  }

  selectBasedOnCompoent() {
    if (
      this.timezoneConfig.componentName === 'myAccountComponent' &&
      this.timezoneConfig.saveOrCancelChanges === 'cancel'
    ) {
      this.selectedItem = [this.getUserObject().timezone];
    } else {
      if (
        this.timezoneConfig.componentName !== 'myAccountComponent' &&
        this.timezoneConfig.selectedData &&
        this.timezoneConfig.selectedData.trim() !== ''
      ) {
        this.selectedItem = [this.timezoneConfig.selectedData];
      } else {
        this.selectedItem = [];
      }
    }
    if (this.timezones && this.timezones.length > 0) {
      this.getSelectedTimeZoneValue();
    }
  }

  dropdownInvalid() {
    if (this.dropDownSelect) {
      this.dropDownSelect = false;
      if (this.selectedItem.length < 1 && this.timezoneConfig.isRequired) {
        this.timezoneErrorMsg = 'Please enter the timezone';
      } else {
        this.timezoneErrorMsg = '';
      }
    }
  }

  onItemSelect(item) {
    const selected = item;
    this.selectedItem = [selected];
    this.dropdownInvalid();
    if (this.timezoneConfig.disabled) {
      this.sendSelectedTimeZone.emit(this.getTimezoneTextOnly(selected));
    } else if (
      this.timezoneConfig.componentName === 'myAccountComponent' &&
      this.timezoneConfig.saveOrCancelChanges === 'cancel'
    ) {
      this.selectedItem = [this.getUserObject().timezone];
      this.sendSelectedTimeZone.emit(this.selectedItem[0]);
    }
  }

  getTimeZones() {
    this.apiRequest.getUserTimeZoneList().subscribe(
      (data) => {
        if (data) {
          const timezones = data.map((time) => {
            const parts = time.split(' ');
            if (parts.length === 2) {
              return parts[0] + ' (' + parts[1] + ')';
            }
            return time;
          });
          if (timezones) {
            this.timezones = timezones;
            this.filteredTimezone = [...this.timezones];
            this.commonService.timezones = timezones;
            if (
              this.timezoneConfig.componentName === 'myAccountComponent' &&
              this.timezoneConfig.saveOrCancelChanges === 'cancel'
            ) {
              this.selectedItem = [this.getUserObject().timezone];
            }
            this.getSelectedTimeZoneValue();
          }
        }
      },
      () => {
        console.error('time-zone api error');
      }
    );
  }

  getUserObject() {
    return this.dataService.user ? this.dataService.user : null;
  }

  getSelectedTimeZoneValue() {
    if (this.selectedItem.length > 0) {
      this.selectedItem[0] =
        this.selectedItem[0] === 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : this.selectedItem[0];
      const value = this.timezones.filter((element) =>
        element.includes(this.selectedItem)
      );
      this.selectedItem = value;
    } else {
      this.selectedItem = [];
    }
  }

  getTimezoneTextOnly(timezone) {
    if (timezone.split('(').length > 1) {
      return timezone.split('(')[0].trim();
    } else if (timezone.split('+').length > 1) {
      return timezone.split('+')[0].trim();
    } else if (timezone.split('-').length > 1) {
      return timezone.split('-')[0].trim();
    } else {
      return timezone.trim().split(' ')[0].trim();
    }
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.searchText = '';
    this.dropDownSelect = e;
    this.filteredTimezone = this.timezones;
  }

  clearSearch(event) {
    event.stopPropagation();
    this.searchText = '';
  }

  selectionChange(event) {
    if (event.isUserInput && event.source.selected == true) {
      this.onItemSelect(event.source.value);
    }
  }

  comparer(o1, o2): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1 == o2 : o2 == o2;
  }

  _filter() {
    if (this.searchText) {
      const filteredList = this.timezones.filter(
        (option) => option.toLowerCase().indexOf(this.searchText) === 0
      );
      this.filteredTimezone = filteredList;
    } else {
      this.filteredTimezone = this.timezones;
    }
  }
}
