import { Injectable } from '@angular/core';
import { ToastMessageService } from '../../../services/toast.message.service';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ManagerService } from '../../services/manager.service';
import {
  ManagerSquadTabStateModel,
  ManagerStateModel
} from './manager-state.model';
import {
  AddManager,
  GetAllManager,
  GetCPEManager,
  GetCPMManager,
  GetCTEManager,
  GetManager,
  GetOthersManager,
  GetSuccessGeniusManager,
  ReInviteUser,
  SetFilteredData,
  SetManagerSquadTab,
  SetManagerViewType,
  SetSearchText,
  UpdateManager,
  UpdateManagerStatus,
  getManagerSquad
} from './manager.action';
import { DataService } from '../../../../shared/shared-module/services/data.service';
import { Constants } from '../../../../shared/shared-module/utility/constants';

@State<ManagerStateModel>({
  name: 'managerstate',
  defaults: {
    entities: {},
    loading: false,
    title: 'manager',
    userList: [],
    searchedUsersList: [],
    searchText: '',
    selectedManager: {},
    cpeEntities: {},
    cpmEntities: {},
    cteEntities: {},
    successGeniusEntities: {},
    othersManagerEntities: {},
    cpeUserList: [],
    cpmUserList: [],
    cteUserList: [],
    successGeniusUserList: [],
    othersManagerUserList: [],
    error: {},
    filteredData: {},
    viewType: '',
    squad: []
  }
})
@Injectable()
export class ManagerState {
  managerSquadTab: 'proManager-tab' | 'archivedManager-tab' | 'pod-tab';
  constructor(
    private managerService: ManagerService,
    private toastService: ToastMessageService,
    private dataService: DataService
  ) {}

  @Action(SetManagerSquadTab)
  setLoaderState(
    { getState, setState }: StateContext<ManagerSquadTabStateModel>,
    { payload }: SetManagerSquadTab
  ) {
    this.managerSquadTab = payload as any;
    setState({
      ...getState(),
      tab: payload
    });
  }

  @Action(GetAllManager)
  getAllDataFromState(
    ctx: StateContext<ManagerStateModel>,
    { page, perPage, searchText, value, filterStr, min }
  ) {
    const state = ctx.getState();
    if (page == 1) {
      ctx.patchState({
        ...state,
        loading: true,
        userList: [],
        entities: { ...state.entities, total_count: 0 }
      });
    } else {
      ctx.patchState({ ...state, loading: true });
    }
    return this.managerService
      .getAllManager(page, perPage, searchText, value, filterStr, min)
      .pipe(
        tap(
          (returnData) => {
            if (page == 1) {
              ctx.patchState(
                this.reAssignManagerList(ctx.getState(), returnData)
              );
            } else {
              ctx.patchState(
                this.reAssignManagerList(ctx.getState(), returnData, true)
              );
            }
          },
          (err) => {
            ctx.patchState({ ...state, loading: false, error: err });
          }
        )
      );
  }

  reAssignManagerList(state, returnData, isConcat?) {
    return {
      ...state,
      entities:
        returnData && returnData.message === 'success'
          ? returnData['data']
          : null,
      loading: false,
      userList:
        returnData &&
        returnData.message === 'success' &&
        returnData['data']?.users?.length > 0
          ? isConcat
            ? state.userList.concat(returnData['data']?.users)
            : returnData['data']?.users
          : []
    };
  }

  @Action(AddManager)
  addDataToState(ctx: StateContext<any>, { payload }: AddManager) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.managerService.addManagers(payload).pipe(
      tap(
        (returnData) => {
          if (
            returnData['message'] === 'success' &&
            state.userList.length > 0
          ) {
            state.userList.push(returnData['user']);
            returnData['data']['apiType'] = 'add';
          }

          ctx.patchState({
            entities:
              returnData && returnData['message'] === 'success'
                ? returnData['data']
                : null,
            loading: false
          });
        },
        (err) => {
          ctx.patchState({ loading: false, error: err });
        }
      )
    );
  }

  @Action(UpdateManager)
  updateDataOfState(ctx: StateContext<any>, { payload, id }: UpdateManager) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.managerService.updateManager(payload, id).pipe(
      tap(
        (returnData) => {
          if (returnData['message'] === 'success') {
            const index = state.userList.map(({ id }) => id).indexOf(id);
            state.userList[index] = returnData['data'].user;
          }

          ctx.setState({
            ...state,
            entities:
              returnData && returnData['message'] === 'success'
                ? returnData['data'].user
                : null,
            loading: false
            // userList: this.managerUserList,
          });
        },
        (err) => {
          ctx.setState({ ...state, loading: false, error: err });
        }
      )
    );
  }

  @Action(ReInviteUser)
  sendReInvite(ctx: StateContext<any>, { managerId }) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.managerService.sendReInvite(managerId).pipe(
      tap(
        (returnData) => {
          if (returnData['message'] == 'success') {
            this.toastService.showToastMessage(
              'Invitation email has been sent.'
            );
          } else {
            this.toastService.showToastMessage(
              'Something went wrong. Try after sometime.',
              2000,
              true
            );
          }
          ctx.setState({
            ...state,
            // entities: returnData,
            loading: false
            // userList: this.managerUserList,
          });
        },
        (err) => {
          ctx.setState({ ...state, loading: false, error: err });
        }
      )
    );
  }

  @Action(UpdateManagerStatus)
  updateManagerStatusToArchiveUnArchive(
    ctx: StateContext<any>,
    { id, type }: UpdateManagerStatus
  ) {
    const state = ctx.getState();
    ctx.patchState({ ...state, loading: true });
    return this.managerService.updateManagerStatus(id, type).pipe(
      tap(
        (returnData) => {
          ctx.patchState({
            loading: false
            // userList: this.managerUserList,
          });
          if (returnData['message'] == 'success') {
            this.toastService.showToastMessage(returnData['data']?.message);
          } else {
            this.toastService.showToastMessage(
              'Something went wrong. Try after sometime.',
              2000,
              true
            );
          }
        },
        (err) => {
          ctx.patchState({ loading: false, error: err });
        }
      )
    );
  }

  @Action(SetSearchText)
  setSearchText(ctx: StateContext<any>, payload) {
    ctx.patchState({ searchText: payload.text });
    return ctx.patchState({
      searchText: payload?.text
    });
  }

  @Action(SetManagerViewType)
  setViewType(ctx: StateContext<any>, payload) {
    ctx.patchState({ viewType: payload.text });
    return ctx.patchState({
      viewType: payload
    });
  }

  @Action(SetFilteredData)
  setFilteredData(ctx: StateContext<any>, payload) {
    ctx.patchState({ searchText: payload['data'] });
    return ctx.patchState({
      filteredData: payload
    });
  }

  @Action(GetManager)
  getDataFromState(ctx: StateContext<any>, { id }: UpdateManager) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.managerService.getManagerById(id).pipe(
      tap(
        (returnData) => {
          ctx.patchState({
            selectedManager:
              returnData && returnData['message'] === 'success'
                ? returnData['data'].user
                : null,
            loading: false
            // userList: this.managerUserList,
          });
        },
        (err) => {
          ctx.patchState({ loading: false, error: err });
        }
      )
    );
  }

  @Action(GetCPEManager)
  getCPEDataFromState(
    ctx: StateContext<ManagerStateModel>,
    { page, perPage, searchText, value, filterStr, min }
  ) {
    const state = ctx.getState();
    console.log(filterStr);
    return this.managerService
      .getAllManager(
        page,
        perPage,
        searchText,
        value,
        Constants.designationMap.productologistDesignations.reduce(
          (acc, designation, index) =>
            (index == 0 ? acc : acc.concat('&')).concat(
              `designation[]=${designation}`
            ),
          ''
        ),
        min,
        this.dataService.partnerData?.id
      )
      .pipe(
        tap(
          (returnData) => {
            if (page == 1) {
              ctx.patchState({
                cpeEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                cpeUserList: returnData['data']?.users
              });
            } else {
              ctx.patchState({
                cpeEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                cpeUserList: state.cpeUserList.concat(returnData['data']?.users)
              });
            }
          },
          (err) => {
            ctx.patchState({ loading: false, error: err });
          }
        )
      );
  }

  @Action(GetCPMManager)
  getCPMDataFromState(
    ctx: StateContext<ManagerStateModel>,
    { page, perPage, searchText, value, filterStr, min }
  ) {
    const state = ctx.getState();
    console.log(filterStr);
    return this.managerService
      .getAllManager(
        page,
        perPage,
        searchText,
        value,
        Constants.designationMap.ninjaDesignations.reduce(
          (acc, designation, index) =>
            (index == 0 ? acc : acc.concat('&')).concat(
              `designation[]=${designation}`
            ),
          ''
        ),
        min,
        this.dataService.partnerData?.id
      )
      .pipe(
        tap(
          (returnData) => {
            if (page == 1) {
              ctx.patchState({
                cpmEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                cpmUserList: returnData['data']?.users
              });
            } else {
              ctx.patchState({
                cpmEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                cpmUserList: state.cpmUserList.concat(returnData['data']?.users)
              });
            }
          },
          (err) => {
            ctx.patchState({ loading: false, error: err });
          }
        )
      );
  }

  @Action(GetCTEManager)
  getCTEDataFromState(
    ctx: StateContext<ManagerStateModel>,
    { page, perPage, searchText, value, filterStr, min }
  ) {
    const state = ctx.getState();
    console.log(filterStr);
    return this.managerService
      .getAllManager(
        page,
        perPage,
        searchText,
        value,
        Constants.designationMap.senseiDesignations.reduce(
          (acc, designation, index) =>
            (index == 0 ? acc : acc.concat('&')).concat(
              `designation[]=${designation}`
            ),
          ''
        ),
        min,
        this.dataService.partnerData?.id
      )
      .pipe(
        tap(
          (returnData) => {
            if (page == 1) {
              ctx.patchState({
                cteEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                cteUserList: returnData['data']?.users
              });
            } else {
              ctx.patchState({
                cteEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                cteUserList: state.cteUserList.concat(returnData['data']?.users)
              });
            }
          },
          (err) => {
            ctx.patchState({ loading: false, error: err });
          }
        )
      );
  }

  @Action(GetSuccessGeniusManager)
  getSuccessGeniusDataFromState(
    ctx: StateContext<ManagerStateModel>,
    { page, perPage, searchText, value, filterStr, min }
  ) {
    const state = ctx.getState();
    console.log(filterStr);
    return this.managerService
      .getAllManager(
        page,
        perPage,
        searchText,
        value,
        Constants.designationMap.successGeniusDesignations.reduce(
          (acc, designation, index) =>
            (index == 0 ? acc : acc.concat('&')).concat(
              `designation[]=${designation}`
            ),
          ''
        ),
        min,
        this.dataService.partnerData?.id
      )
      .pipe(
        tap(
          (returnData) => {
            if (page == 1) {
              ctx.patchState({
                successGeniusEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                successGeniusUserList: returnData['data']?.users
              });
            } else {
              ctx.patchState({
                successGeniusEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                successGeniusUserList: state.successGeniusUserList.concat(
                  returnData['data']?.users
                )
              });
            }
          },
          (err) => {
            ctx.patchState({ loading: false, error: err });
          }
        )
      );
  }

  @Action(GetOthersManager)
  getOthersManagersDataFromState(
    ctx: StateContext<ManagerStateModel>,
    { page, perPage, searchText, value, filterStr, min }
  ) {
    const state = ctx.getState();
    console.log(filterStr);
    return this.managerService
      .getAllManager(
        page,
        perPage,
        searchText,
        value,
        `${Constants.designationMap.senseiDesignations.reduce(
          (acc3, designation3, index3) =>
            (index3 == 0 && acc3 == '' ? acc3 : acc3.concat('&')).concat(
              `not_in_designation[]=${designation3}`
            ),
          Constants.designationMap.ninjaDesignations.reduce(
            (acc2, designation2, index2) =>
              (index2 == 0 && acc2 == '' ? acc2 : acc2.concat('&')).concat(
                `not_in_designation[]=${designation2}`
              ),
            Constants.designationMap.productologistDesignations.reduce(
              (acc1, designation1, index1) =>
                (index1 == 0 && acc1 == '' ? acc1 : acc1.concat('&')).concat(
                  `not_in_designation[]=${designation1}`
                ),
              Constants.designationMap.successGeniusDesignations.reduce(
                (acc0, designation0, index0) =>
                  (index0 == 0 ? acc0 : acc0.concat('&')).concat(
                    `not_in_designation[]=${designation0}`
                  ),
                ''
              )
            )
          )
        )}`,
        min,
        this.dataService.partnerData?.id
      )
      .pipe(
        tap(
          (returnData) => {
            if (page == 1) {
              ctx.patchState({
                othersManagerEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                othersManagerUserList: returnData['data']?.users
              });
            } else {
              ctx.patchState({
                othersManagerEntities:
                  returnData && returnData['message'] === 'success'
                    ? returnData['data']
                    : null,
                othersManagerUserList: state.othersManagerUserList.concat(
                  returnData['data']?.users
                )
              });
            }
          },
          (err) => {
            ctx.patchState({ loading: false, error: err });
          }
        )
      );
  }

  @Action(getManagerSquad)
  getManagerSquad(ctx: StateContext<ManagerStateModel>, { page, perPage }) {
    const state = ctx.getState();
    ctx.setState({ ...state });
    console.log(page, ',', perPage);
    return this.managerService.getManagerSquad().pipe(
      tap(
        (returnData) => {
          ctx.patchState({
            squad: returnData['data'].pods
          });
        },
        (err) => {
          ctx.patchState({ loading: false, error: err });
        }
      )
    );
  }
}
