import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpRequests } from '../../../shared/shared-module/services/http-requests.service';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable()
export class FooterService {
  constructor(
    public dataService: DataService,
    public httpRequest: HttpRequests,
    private commonService: CommonService
  ) {}
  public getBuilderPlanners(projectId) {
    const API =
      this.dataService.getServerUrl() +
      'projects/' +
      projectId +
      '/builder_planners' +
      this.commonService.forceRedirect();
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((resp) => {
          if (resp.data) {
            return resp.data;
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
  public getListOfDesignBoards(projectId) {
    const API =
      this.dataService.getServerUrl() +
      'projects/' +
      projectId +
      '/design_boards';
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((resp) => {
          if (resp.data) {
            return resp.data;
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
}
