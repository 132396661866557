import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AccountService } from '../services/account.service';

import { GetAllAccounts } from './accounts.action';
import { AccountsModel } from './accounts.model';

@State<AccountsModel>({
  name: 'accountsstate',
  defaults: {
    entities: {},
    loading: false,
    searchText: '',
    error: {},
    userList: [],
    nextPage: 1
  }
})
@Injectable()
export class AccountsState {
  constructor(private accountService: AccountService) {}

  @Action(GetAllAccounts)
  getAllDataFromState(ctx: StateContext<AccountsModel>, { pagination, query }) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });

    return this.accountService.getListOfAccount(pagination, query).pipe(
      tap(
        (returnData) => {
          if (returnData?.users && Array.isArray(returnData.users)) {
            if (pagination.page == 1) {
              ctx.setState(
                this.reAssignManagerList(
                  ctx.getState(),
                  returnData,
                  pagination.page
                )
              );
            } else {
              ctx.setState(
                this.reAssignManagerList(
                  ctx.getState(),
                  returnData,
                  pagination.page,
                  true
                )
              );
            }
          } else {
            ctx.setState({ ...state, nextPage: null });
          }
        },
        (err) => {
          ctx.setState({ ...state, loading: false, error: err });
        }
      )
    );
  }

  reAssignManagerList(state, returnData, pageNumber, isConcat?) {
    return {
      ...state,
      entities: returnData,
      loading: false,
      nextPage: pageNumber + 1,
      userList: isConcat
        ? state.userList.concat(returnData?.users)
        : returnData?.users
    };
  }
}
