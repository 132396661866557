export class PartnerdataModel {
  builder_domain: any;
  builder_tracker_url: any;
  code: any;
  company_name: any;
  discount_percentage: any;
  domain: any;
  id: 8;
  logo: any;
  name: any;
  partners_type: any;
  pm_domain: any;
  tracker_frontend_url: any;
  variance_percentage: any;
  vendor_backend_domain: any;
  vendor_frontend_domain: any;
  card_added: boolean;
  currency: { code: string };
  release_status: any;
  builder_live_domain: any;
  builder_home4_domain: any;
}
